<template>
  <!-- 商户列表 -->
  <div class="wrap">
    <TablePage
      ref="tablePage"
      v-model="options"
      @handleEvent="handleEvent"
    ></TablePage>
  </div>
</template>

<script>
import TablePagination from '@/components/tablePage/tablePagination'
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import {
  joinGiftListAPI,
  joinGiftUpdateStatusAPI,
  joinGiftIsStopAPI
} from '@/api/marketing/vipMarketing/joinGift'
import TablePage from '@/components/tablePage/index.vue'
import { getDicts } from '@/api/system/dict/data'
import CDialog from '@/components/dialogTemplate/dialog/index.vue'

export default {
  name: 'TenantList',
  components: {
    TablePage,
    cardTitleCom,
    seniorSearch,
    TablePagination,
    CDialog
  },
  dicts: ['bill_status', 'fill_vip_level_range_type', 'fill_shop_range_type'],
  data () {
    return {
      options: {
        listNo: true,
        mutiSelect: true,
        loading: true,
        check: [],
        pagination: {
          total: 0,
          pageNum: 1,
          pageSize: 15,
          isStop: undefined, // 启用状态
          billStatus: undefined // 方案状态
        },
        rowKey: "billId",
        getListApi: joinGiftListAPI,
        title: '商户列表',
        search: [
          {
            label: '日期',
            type: 'datetimerange',
            model: '',
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: 'filters',
            tip: '方案名称/方案编号',
            isRestore: true,
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'billNames', label: '方案名称' },
              { filter: 'billNos', label: '方案编号' }
            ]
          },
          {
            type: 'local',
            label: '启用状态',
            model: '',
            filter: 'isStops',
            seniorSearch: true,
            option: {
              data: [
                {
                  label: '启用',
                  value: false
                },
                {
                  label: '停用',
                  value: true
                }
              ],
              label: 'label',
              value: 'value',
              multiple: true
            }
          },
          {
            type: 'local',
            label: '方案状态',
            model: '',
            filter: 'billStatuss',
            seniorSearch: true,
            option: {
              remote: getDicts,
              remoteBody: 'bill_status',
              label: 'dictLabel',
              value: 'dictValue',
              multiple: true
            }
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        buttons: [
          {
            click: 'add',
            label: '新增',
            type: 'primary',
            icon: 'el-icon-plus'
          },
          {
            click: 'audit',
            label: '审核',
            type: 'success',
            icon: 'el-icon-document',
            disabled: () => !this.options?.check?.length
          },
          {
            click: 'enable',
            label: '启用',
            type: 'warning',
            btnType: 'dropdown',
            other: [
              {
                click: 'redoEnable',
                label: '禁用'
              }
            ],
            disabled: () => !this.options?.check?.length
          },
          {
            click: 'del',
            label: '删除',
            type: 'danger',
            icon: 'el-icon-delete',
            disabled: () => !this.options?.check?.length
          },
          {
            click: 'getList',
            label: '刷新',
            right: 'true'
          }
        ],
        columns: [
          {
            prop: 'billNo',
            type: 'link',
            click: 'link',
            label: '方案编号',
            width: '200'
          },
          { prop: 'billName', label: '方案名称', width: '200' },
          // { prop: 'vipLevelRangeType', label: '适用会员', width: '200' },
          // { prop: 'shopRangeType', label: '适用门店', width: '150' },
          {
            prop: 'vipLevelRangeTypeName',
            // type: 'dicts',
            // name: 'fill_vip_level_range_type',
            label: '适用会员',
            width: '150'
          },
          {
            prop: 'shopRangeTypeName',
            // type: 'dicts',
            // name: 'fill_shop_range_type',
            label: '适用门店',
            width: '150'
          },
          { prop: 'channel', label: '适用渠道', width: '150',
          formatter: (v, row) => `${row.isAllowOfflineShop? '线下门店' : ''}${row.isAllowOnlineShop? `${row.isAllowOfflineShop? ',线上商城' : '线上商城 '}` : ''}${row.isAllowOfflineHQ? `${row.isAllowOnlineShop? ',总部' : '总部 '}` : ''}${row.isAllowOnlineWxCard? `${row.isAllowOfflineHQ? ',微信会员卡' : '微信会员卡'}` : ''}` },
          { prop: 'begTime', label: '有效时间', width: '100' },
          { prop: 'endTime', label: '失效时间', width: '150' },
          {
            prop: 'billStatusName',
            // type: 'dicts',
            // name: 'bill_status',
            label: '方案状态',
            width: '150'
          },
          { prop: 'isStopName', label: '启用状态' },
          { prop: 'billRemark', label: '备注', width: '120' },
          { prop: 'updateBy', label: '修改人' },
          { prop: 'updateTime', label: '修改时间', width: '120' },
          { prop: 'createBy', label: '创建人' },
          { prop: 'createTime', label: '创建时间', width: '160' }
        ],
        list: []
      }
    }
  },
  methods: {
    async handleEvent (type, row) {
      const selectData = this.options.check
      switch (type) {
        case 'link':
          await this.$router.push({
            name: 'joinGiftDetail',
            query: { billId: row.billId, type: 'Update' }
          })
          break
        case 'add':
          await this.$router.push({ name: 'joinGiftDetail' })
          break
        case 'audit':
          {
            if (!selectData.length) return
            this.handleBillStatus('审核', selectData)
          }
          break
        case 'redoAudit':
          {
            if (!selectData.length) return
            this.handleBillStatus('反审核', selectData)
          }
          break
        case 'enable':
          {
            if (!selectData.length) return
            this.handleBillStatus('启用', selectData)
          }
          break
        case 'redoEnable':
          {
            if (!selectData.length) return
            this.handleBillStatus('禁用', selectData)
          }
          break
        case 'del':
          {
            if (!selectData.length) return
            this.handleBillStatus('删除', selectData)
          }
          break
        default:
      }
    },
    handleBillStatus (command, selectData) {
      let name = ''
      let billStatus = ''
      let isStop = ''
      if (command === '反审核') {
        name = '反审核'
        billStatus = '0'
      } else {
        if (command === '审核') {
          name = '审核'
          billStatus = '2'
        } else if (command === '删除') {
          name = '删除'
          billStatus = '3'
        } else if (command === '启用') {
          name = '启用'
          isStop = false
        } else if (command === '禁用') {
          name = '禁用'
          isStop = true
        } else if (command === '复制') {
          name = '复制'
          billStatus = '4'
        }
      }
      const billIds = selectData.map(i => i.billId)
      const billNo = selectData.map(i => i.billNo)
      const $this = this
      this.$modal
        .confirm(`是否确认${name}方案编号为"` + billNo + '"的单据项?')
        .then(function () {
          if (['启用', '禁用'].includes(name)) {
            joinGiftIsStopAPI({ billIds: [...billIds], isStop: isStop }).then(
              res => {
                $this.$nextTick(() => {
                  $this.options.check = []
                })
                setTimeout(() => {
                  $this.$refs.tablePage.getList()
                }, 500)
                if (!isStop) {
                  $this.$modal.msgSuccess('方案启用成功')
                } else {
                  $this.$modal.msgSuccess('方案禁用成功')
                }
              }
            )
          } else if (['审核', '反审核', '删除'].includes(name)) {
            const index = selectData.findIndex(y => !y.isStop)
            if (['反审核'].includes(name) && index != '-1')
              return $this.$modal.msgError('已启用不能反审核')
            joinGiftUpdateStatusAPI({
              billIds: [...billIds],
              billStatus: billStatus
            }).then(res => {
              $this.$nextTick(() => {
                $this.options.check = []
              })
              setTimeout(() => {
                $this.$refs.tablePage.getList()
              }, 500)
              $this.$modal.msgSuccess(`${name}成功`)
            })
          }
        })
    }
  }
}
</script>

<!--<template>
  <div class="wrap" v-loading="loading">
    &lt;!&ndash; 表格和搜索右边盒子 &ndash;&gt;
    <div>
      <cardTitleCom cardTitle="搜索">
        <template slot="cardContent">
          <div class="navSearch">
            &lt;!&ndash; 高级搜索按钮 &ndash;&gt;
            <seniorSearch
              @isShowHighCom="getIsShowHigh"
              :dropDownList="dropDownList"
              :isSearchInput="true"
              isSearchInputTitle="输入方案编号、名称"
            />
            &lt;!&ndash; 条件 &ndash;&gt;
            <div class="marT10" v-show="showHigh">
              &lt;!&ndash; 是否启用 &ndash;&gt;
              <div>
                <span class="fS14MR10">启用状态</span>
                <el-select
                  class="inputWidth marR15"
                  v-model="queryParams.isStop"
                  placeholder="全部"
                  filterable
                  clearable
                >
                  <el-option label="启用" :value="false"></el-option>
                  <el-option label="停用" :value="true"></el-option>
                </el-select>
                <span class="fS14MR10">方案状态</span>
                <el-select
                  class="inputWidth"
                  v-model="queryParams.billStatus"
                  placeholder="全部"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="dict in dict.type.bill_status"
                    :key="dict.value"
                    :label="dict.label"
                    :value="dict.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </template>
      </cardTitleCom>
      &lt;!&ndash; 表格商品信息卡片 &ndash;&gt;
      <cardTitleCom cardTitle="源单信息" class="headGoodsTable">
        <template slot="cardContent">
          &lt;!&ndash; 商品信息按钮和表格整体盒子 &ndash;&gt;
          <div class="goodsMessage">
            <div class="operateBtn">
              &lt;!&ndash; 左边盒子 &ndash;&gt;
              <div>
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  size="mini"
                  @click="handleAdd"
                >新增
                </el-button
                >
                <el-button
                  class="marRBtn"
                  @click="auditBill('审核')"
                  icon="iconfont icon-a-weibiaoti-1_huaban1fuben28"
                  size="mini"
                  :disabled="multiple"
                  type="success"
                >审核
                </el-button
                >
                <el-dropdown
                  class="marRBtn"
                  split-button
                  type="warning"
                  icon="el-icon-circle-check"
                  :disabled="multiple"
                  @click="getEnable(false)"
                  @command="getEnable(true)"
                  size="mini"
                >
                  启用
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="停用">停用</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-button
                  class="marRBtn"
                  type="danger"
                  icon="el-icon-delete"
                  :disabled="multiple"
                  @click="auditBill('删除')"
                  size="mini"
                >删除
                </el-button>
              </div>
              &lt;!&ndash; 右边盒子 &ndash;&gt;
              <div>
                <el-button
                  size="mini"
                  icon="el-icon-refresh"
                  @click="test($event)"
                >刷新
                </el-button>
              </div>
            </div>
            <div>
              <el-table
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                @selection-change="handleSelectionChange"
                border
                max-height="587"
                height="587"
                ref="multipleTable"
                @row-click="handleRowClick"
              >
                <el-table-column type="selection" width="50" align="center"/>
                <el-table-column
                  label="序号"
                  align="center"
                  prop="userId"
                  width="80"
                  type="index"
                />
                <el-table-column
                  show-overflow-tooltip
                  label="方案编号"
                  align="center"
                  prop="billNo"
                  width="155"
                >
                  <template slot-scope="scope">
                    <el-link
                      type="primary"
                      @click="handleDetail(scope.row)"
                      target="_blank"
                    >
                      {{ scope.row.billNo }}
                    </el-link>
                  </template>
                </el-table-column>
                <el-table-column
                  label="方案名称"
                  align="center"
                  prop="billName"
                  width="130"
                />
                <el-table-column
                  label="适用会员"
                  align="center"
                  prop="vipLevelRangeType"
                  width="120"
                >
                  <template slot-scope="scope">
                    <dict-tag
                      :options="dict.type.fill_vip_level_range_type"
                      :value="scope.row.vipLevelRangeType"
                    />
                  </template>
                </el-table-column>
                <el-table-column
                  label="适用门店"
                  align="center"
                  prop="shopRangeType"
                  width="120"
                >
                  <template slot-scope="scope">
                    <dict-tag
                      :options="dict.type.fill_shop_range_type"
                      :value="scope.row.shopRangeType"
                    />
                  </template>
                </el-table-column>
                <el-table-column
                  label="适用渠道"
                  align="center"
                  prop="channel"
                  width="155"
                />
                <el-table-column
                  label="有效时间"
                  align="center"
                  prop="begTime"
                  width="155"
                />
                <el-table-column
                  label="失效时间"
                  align="center"
                  prop="endTime"
                  width="155"
                />
                <el-table-column
                  label="方案状态"
                  align="center"
                  prop="billStatusName"
                  width="100"
                />
                <el-table-column
                  label="启用状态"
                  align="center"
                  prop="isStopName"
                  width="100"
                />
                <el-table-column
                  label="备注"
                  align="center"
                  prop="billRemark"
                  min-width="135"
                />

                <el-table-column
                  label="创建人"
                  align="center"
                  prop="createBy"
                />
                <el-table-column
                  label="创建时间"
                  align="center"
                  prop="createTime"
                  width="155"
                />
                <el-table-column
                  label="修改人"
                  align="center"
                  prop="updateBy"
                  width="120"
                />
                <el-table-column
                  label="修改时间"
                  align="center"
                  prop="updateTime"
                  width="155"
                />
              </el-table>
              <bottomPage   v-model="queryParams"
  :getList="getList"
                :idsCom="ids"
                :totalCom="total"
                @selectAllCom="selectAll"
              />
            </div>
          </div>
        </template>
      </cardTitleCom>
    </div>
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import bottomPage from '@/views/components/bottomPage' //底部分页
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import {
  joinGiftListAPI,
  joinGiftUpdateStatusAPI,
  joinGiftIsStopAPI
} from '@/api/marketing/vipMarketing/joinGift' //会员入会有礼
export default {
  name: 'joinGift',
  dicts: ['bill_status', 'fill_vip_level_range_type', 'fill_shop_range_type'],
  components: { bottomPage, cardTitleCom, seniorSearch },
  data() {
    return {
      //搜索框搜索的条件
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'billNo', name: '方案编号' },
        { parameter: 'billName', name: '方案名称' }
      ],
      //是否显示高级搜索
      showHigh: false,
      // 遮罩层
      loading: false,
      loadingTable: false, //表格遮罩
      name: [], //选中数组的名称
      ids: [], // 选中数组
      billStatusIsStopNoId: [], //选中数组的停用的状态
      billStatusIsStopId: [], //选中数组的启用的状态
      billStatusDeleteId: [], //选中可以删除的数组  已停用状态
      billStatusZeroId: [], //筛选出来未审核的单据状态id0
      tableData: [], //配方表格
      // 非多个停用
      multiple: true,
      total: 0, // 总条数
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15
      }
    }
  },

  created() {
    //初始化表格数据
    this.getList()
  },
  methods: {
    //是否显示高级搜索
    getIsShowHigh(value) {
      this.showHigh = value
    },
    //点击行选中
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    //点击全选所有数据
    async selectAll(value) {
      if (value) {
        this.loadingTable = true
        const res = await joinGiftListAPI({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total
        })
        //重新渲染表格
        this.tableData = res.rows
        //表格多选
        this.tableData.map(row => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value)
          })
        })
        this.loadingTable = false
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection()
        })
        this.getList()
      }
    },
    //审核/删除/终止事件
    async auditBill(name) {
      if (name === '审核') {
        if (this.billStatusZeroId.length <= 0) {
          this.$message.error('只能审核：未审核的状态！')
          return
        }
        const obj = {
          billIds: this.billStatusZeroId,
          billStatus: '2'
        }
        //发送审核api
        await joinGiftUpdateStatusAPI(obj)
        try {
          await this.$confirm(`此单据已审核，是否要启用?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'success'
          })
          const obj = {
            billIds: this.billStatusZeroId,
            isStop: false
          }
          await joinGiftIsStopAPI(obj)
          // 弹出提示
          this.$message.success('审核并启用成功')
        } catch {
          // 弹出提示
          this.$message.success('审核成功')
        }
      } else if (name === '删除') {
        if (this.billStatusDeleteId.length <= 0) {
          this.$message.error('只能删除：停用的状态！')
          return
        }
        const obj = {
          billIds: this.billStatusDeleteId,
          billStatus: '3'
        }
        //发送删除api
        await joinGiftUpdateStatusAPI(obj)
        // 弹出提示
        this.$message.success('删除成功')
      }

      await this.getList()
    },
    //启用停用
    async getEnable(value) {
      if (!value) {
        if (this.billStatusIsStopNoId.length === 0) {
          this.$message.error('只能启用：已停用并且已审核的状态！')
          return
        }
        const obj = {
          billIds: this.billStatusIsStopNoId,
          isStop: value
        }
        await joinGiftIsStopAPI(obj)
        this.$message({
          message: `启用成功`,
          type: 'success'
        })
      } else {
        if (this.billStatusIsStopId.length === 0) {
          this.$message.error('只能停用：已启用的状态')
          return
        }
        const obj = {
          billIds: this.billStatusIsStopId,
          isStop: value
        }
        await joinGiftIsStopAPI(obj)
        this.$message({
          message: `停用成功`,
          type: 'success'
        })
      }
      await this.getList()
    },

    //新增单据事件
    handleAdd() {
      this.$router.push({
        name: 'joinGiftDetail'
      })
    },
    //跳转详情
    handleDetail(row) {
      const billId = row.billId
      this.$router.push({
        name: 'joinGiftDetail',
        query: {
          billId: billId,
          type: 'Update'
        }
      })
    },
    //搜索按钮
    handleQuery() {
      this.queryParams.pageNum = 1
      this.getList()
    },
    //刷新按钮
    test(e) {
      let target = e.target
      if (target.nodeName == 'I' || target.nodeName == 'SPAN') {
        target = e.target.parentNode
      }
      target.blur()
      this.getList()
    },

    /** 查询表格列表 */
    async getList() {
      this.loadingTable = true
      const { rows, total } = await joinGiftListAPI(this.queryParams)
      this.tableData = rows
      const channelMap = {
        0: '',
        1: '线上商城，线下门店',
        2: '线上商城',
        3: '线下门店'
      }
      this.tableData.forEach(item => {
        let channelFlag = '0' // 默认为 3（只支持线下门店）
        if (item.isAllowOfflineShop && item.isAllowOnlineShop) {
          channelFlag = '1' // 支持线上商城和线下门店
        } else if (item.isAllowOnlineShop) {
          channelFlag = '2' // 支持线上商城
        } else if (item.isAllowOfflineShop) {
          channelFlag = '3' // 支持线下门店
        }
        this.$set(item, 'channel', channelMap[channelFlag])
      })

      this.total = total
      this.loadingTable = false
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.billId) //单据id
      //筛选出来已审核、停用的单据状态
      this.billStatusIsStopNoId = selection
        .map(item => {
          if (item.isStop && item.billStatus == '2') {
            return item.billId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      //筛选出来已审核、启用的单据状态
      this.billStatusIsStopId = selection
        .map(item => {
          if (!item.isStop && item.billStatus == '2') {
            return item.billId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      //筛选出来已停用并且未删除的单据状态  删除接口
      this.billStatusDeleteId = selection
        .map(item => {
          if (item.isStop) {
            return item.billId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      //筛选出来未审核的单据状态id0
      this.billStatusZeroId = selection
        .map(item => {
          if (item.billStatus == '0') {
            return item.billId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      this.multiple = !selection.length
    }
  }
}
</script>
<style scoped lang="scss">
//整体盒子
.wrap {
  padding: 6px 10px 0 10px;
  background-color: #eaeaea;
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
  }

  //商品表格卡片
  .headGoodsTable {
    .goodsMessage {
      margin: 6px 10px 0 10px;

      .operateBtn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;
        line-height: 50px;
        padding: 0 6px;
        margin-bottom: 6px;
        background-color: #f9f9fa;

        .marRBtn {
          margin-right: 10px;
        }

        //审核
        .audibill {
          color: white;
          // background-color: #3cbf1d;
        }
      }
    }
  }

  //阿里图标
  ::v-deep .iconfont {
    font-size: 12px;
  }
}
</style>-->
