<template>
  <!-- 会员升级 -->
  <TablePage ref="tablePage" v-model="options" />
</template>

<script>
import TablePage from '@/components/tablePage/index.vue'
import { upgradelogList } from '@/api/marketing/vipMarketing/upgrade'
export default {
  name: 'upgradeLog',
  components: {
    TablePage,
  },
  data () {
    return {
      options: {
        listNo: true,
        mutiSelect: true,
        loading: true,
        check: [],
        pagination: {
          total: 0,
          pageNum: 1,
          pageSize: 15,
          query: undefined, // 输入方案编号名称
        },
        getListApi: upgradelogList,
        title: '会员升级日志',
        rowKey: 'billId',
        type: 'billId',
        search: [
          {
            type: "filters",
            tip: "全部/会员名称/手机号/方案编号",
            isRestore:true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "vipNames", label: "会员名称" },
              { filter: "tels", label: "手机号" },
              { filter: "upgradeCaseNos", label: "方案编号" },
            ],
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
        ],
        columns: [
          { prop: 'vipName', label: '会员名称', width: '120' },
          { prop: 'tel', label: '手机号', width: '160' },
          { prop: 'vipNo', label: '会员卡卡号', width: '160' },
          {
            prop: "upgradeCaseType",
            label: "方案类型",
            type: "dict",
            minWidth: 120,
            formatter: e => (e == 1 ? '升级' : '降级')
          },
          { prop: 'upgradeCaseNo', label: '方案编号', width: '160'},
          { prop: 'upgradeCaseName', label: '规则描述', width: '160'},
          { prop: 'oldVipLevelName', label: '变更前等级名称', width: '160'},
          { prop: 'newVipLevelName', label: '变更后等级名称', width: '160'},
          { prop: 'upgradeDesc', label: '满足条件', width: '200' },
          { prop: '', label: '原因', width: '200' },
          { prop: 'createTime', label: '变更日期', width: '200' },
          { prop: 'begDate', label: '生效日期', width: '200'},
          { prop: 'endDate', label: '失效日期', width: '200'},
        ],
        list: [],
      },
    }
  },
}
</script>