<template>
  <div class="wrap" v-loading="loading">
    <!-- 操作 -->
    <div class="navBtnTop">
      <el-button size="mini" @click="lastStep" v-show="activeStep !== 0"
        >上一步</el-button
      >
      <el-button size="mini" @click="nextStep" v-show="activeStep !== 3"
        >下一步</el-button
      >
      <el-button
        type="primary"
        size="mini"
        :disabled="disabled"
        v-show="activeStep === 3"
        @click="submitForm(true)"
        >保存
      </el-button>
      <el-button
        type="primary"
        size="mini"
        :disabled="disabled"
        v-show="activeStep === 3"
        @click="addBill"
        >保存并新增
      </el-button>
      <el-button
        @click="auditBill('审核')"
        icon="iconfont icon-a-weibiaoti-1_huaban1fuben28"
        size="mini"
        :disabled="showAudit"
        v-show="activeStep === 3"
        type="success"
        >审核</el-button
      >
      <el-button
        class="marL10"
        type="primary"
        icon="el-icon-plus"
        size="mini"
        v-show="showAdd"
        @click="handleAdd"
        >新增</el-button
      >
      <el-button size="mini" @click="getQuit">退出</el-button>
    </div>
    <div class="content">
      <el-form ref="form" :model="precisionForm" label-width="70px">
        <cardTitleCom
          cardTitle="当前进度"
          :auditOrder="auditOrder"
          :noAuditOrder="noAuditOrder"
          :stopOrder="stopOrder"
          :deleteOrder="deleteOrder"
          :disableOrder="disableOrder"
        >
          <template slot="cardContent">
            <div class="theSteps">
              <el-steps :active="activeStep" finish-status="success">
                <el-step title="筛选对象" class="firstStep"></el-step>
                <el-step title="营销对象确认" class="secondStep"></el-step>
                <el-step title="设置礼包" class="thirdStep"></el-step>
              </el-steps>
            </div>
          </template>
        </cardTitleCom>
        <!-- 基本信息 -->
        <cardTitleCom cardTitle="基本信息" v-show="activeStep === 0">
          <template slot="cardContent">
            <div class="modifyType">
              <el-form-item label="方案编号" prop="billNo">
                <el-input
                  class="inputWidth marR15"
                  :disabled="true"
                  v-model="precisionForm.billNo"
                  size="mini"
                  placeholder="方案编号"
                ></el-input>
              </el-form-item>
              <el-form-item label="方案名称" prop="billName">
                <el-input
                  class="inputWidth"
                  :disabled="disabled"
                  v-model="precisionForm.billName"
                  size="mini"
                  placeholder="方案名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="备注" prop="billRemark" label-width="60px">
                <el-input
                  class="inputRemark"
                  :disabled="disabled"
                  v-model="precisionForm.billRemark"
                  type="textarea"
                  size="mini"
                  placeholder="备注长度介于 1 和 80 字符之间"
                  maxlength="80"
                  :autosize="{ minRows: 1, maxRows: 5 }"
                />
              </el-form-item>
            </div>
          </template>
        </cardTitleCom>
        <!-- 目标对象筛选 -->
        <cardTitleCom cardTitle="目标对象筛选" v-show="activeStep === 0">
          <template slot="cardContent">
            <div class="tableContent">
              <el-form-item
                class="marB50"
                label="筛选目标用户"
                prop="precisionTargetType"
                label-width="100px"
              >
                <el-select
                  class="inputWidth"
                  v-model="precisionForm.precisionTargetType"
                  placeholder="目标会员"
                  size="mini"
                  :disabled="disabled"
                >
                  <el-option label="所有会员" :value="2"></el-option>
                  <el-option label="指定会员" :value="1"></el-option>
                </el-select>
              </el-form-item>

              <div
                class="filterCriteria"
                v-show="precisionForm.precisionTargetType == 1"
              >
                <!-- 按消费行为 -->
                <div class="x-x marB50">
                  <el-checkbox 
                  :disabled="disabled" v-model="precisionForm.isUseConsumeAction"
                    >按消费行为:</el-checkbox
                  >
                  <div
                    class="filterCriteriaTwo"
                    v-show="precisionForm.isUseConsumeAction"
                  >
                    <!-- 累计消费金额 -->
                    <div class="marB50 x-f">
                      <el-checkbox
                        :disabled="disabled"
                        v-model="precisionForm.detailItem[0].isSelected"
                        >累计消费金额:</el-checkbox
                      >
                      <el-radio-group
                        class="marL20 marR20"
                        v-model="precisionForm.detailItem[0].timeRangeType"
                        size="medium"
                        :disabled="disabled"
                        v-show="precisionForm.detailItem[0].isSelected"
                      >
                        <el-radio-button :label="1">近一周</el-radio-button>
                        <el-radio-button :label="2">近一个月</el-radio-button>
                        <el-radio-button :label="3">三个月</el-radio-button>
                        <el-radio-button :label="4">半年</el-radio-button>
                        <el-radio-button :label="5">自定义</el-radio-button>
                      </el-radio-group>
                      <div
                        v-show="
                          precisionForm.detailItem[0].isSelected &&
                          precisionForm.detailItem[0].timeRangeType == 5
                        "
                      >
                        <el-date-picker
                          class="marR20"
                          v-model="datePicker0"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :default-time="['00:00:00', '23:59:59']"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          size="mini"
                          :disabled="disabled"
                        />
                      </div>
                      <div
                        class="x-f"
                        v-show="precisionForm.detailItem[0].isSelected"
                      >
                        <el-input
                          type="number"
                          class="inputWidthPrecision"
                          size="mini"
                          v-model="precisionForm.detailItem[0].begValue"
                          placeholder="请输入内容"
                          :disabled="disabled"
                          oninput="if(value<=0){value=''} if(value>99999999){value=99999999}"
                        ></el-input>
                        <span class="marR10 marL10">——</span>
                        <el-input
                          type="number"
                          class="inputWidthPrecision"
                          v-model="precisionForm.detailItem[0].endValue"
                          placeholder="请输入内容"
                          size="mini"
                          :disabled="disabled"
                          oninput="if(value<=0){value=''} if(value>99999999){value=99999999}"
                        ></el-input>
                        <span class="marL10">元</span>
                      </div>
                    </div>
                    <!-- 平均消费金额 -->
                    <div class="marB50 x-f">
                      <el-checkbox
                        :disabled="disabled"
                        v-model="precisionForm.detailItem[1].isSelected"
                        >平均消费金额:</el-checkbox
                      >
                      <el-radio-group
                        class="marL20 marR20"
                        v-model="precisionForm.detailItem[1].timeRangeType"
                        size="medium"
                        :disabled="disabled"
                        v-show="precisionForm.detailItem[1].isSelected"
                      >
                        <el-radio-button :label="1">近一周</el-radio-button>
                        <el-radio-button :label="2">近一个月</el-radio-button>
                        <el-radio-button :label="3">三个月</el-radio-button>
                        <el-radio-button :label="4">半年</el-radio-button>
                        <el-radio-button :label="5">自定义</el-radio-button>
                      </el-radio-group>
                      <div
                        v-show="
                          precisionForm.detailItem[1].isSelected &&
                          precisionForm.detailItem[1].timeRangeType == 5
                        "
                      >
                        <el-date-picker
                          class="marR20"
                          v-model="datePicker1"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :default-time="['00:00:00', '23:59:59']"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          size="mini"
                          :disabled="disabled"
                        />
                      </div>
                      <div
                        class="x-f"
                        v-show="precisionForm.detailItem[1].isSelected"
                      >
                        <el-input
                          type="number"
                          class="inputWidthPrecision"
                          v-model="precisionForm.detailItem[1].begValue"
                          placeholder="请输入内容"
                          size="mini"
                          :disabled="disabled"
                          oninput="if(value<=0){value=''} if(value>99999999){value=99999999}"
                        ></el-input>
                        <span class="marR10 marL10">——</span>
                        <el-input
                          type="number"
                          class="inputWidthPrecision"
                          v-model="precisionForm.detailItem[1].endValue"
                          size="mini"
                          placeholder="请输入内容"
                          :disabled="disabled"
                          oninput="if(value<=0){value=''} if(value>99999999){value=99999999}"
                        ></el-input>
                        <span class="marL10">元</span>
                      </div>
                    </div>
                    <!-- 累计消费次数 -->
                    <div class="marB50 x-f">
                      <el-checkbox
                        :disabled="disabled"
                        v-model="precisionForm.detailItem[2].isSelected"
                        >累计消费次数:</el-checkbox
                      >
                      <el-radio-group
                        class="marL20 marR20"
                        v-model="precisionForm.detailItem[2].timeRangeType"
                        size="medium"
                        :disabled="disabled"
                        v-show="precisionForm.detailItem[2].isSelected"
                      >
                        <el-radio-button :label="1">近一周</el-radio-button>
                        <el-radio-button :label="2">近一个月</el-radio-button>
                        <el-radio-button :label="3">三个月</el-radio-button>
                        <el-radio-button :label="4">半年</el-radio-button>
                        <el-radio-button :label="5">自定义</el-radio-button>
                      </el-radio-group>
                      <div
                        v-show="
                          precisionForm.detailItem[2].isSelected &&
                          precisionForm.detailItem[2].timeRangeType == 5
                        "
                      >
                        <el-date-picker
                          class="marR20"
                          v-model="datePicker2"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :default-time="['00:00:00', '23:59:59']"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          size="mini"
                          :disabled="disabled"
                        />
                      </div>
                      <div
                        class="x-f"
                        v-show="precisionForm.detailItem[2].isSelected"
                      >
                        <el-input
                          type="number"
                          class="inputWidthPrecision"
                          v-model="precisionForm.detailItem[2].begValue"
                          placeholder="请输入内容"
                          size="mini"
                          :disabled="disabled"
                          oninput="if(value<=0){value=''} if(value>9999){value=9999}"
                        ></el-input>
                        <span class="marR10 marL10">——</span>
                        <el-input
                          type="number"
                          class="inputWidthPrecision"
                          v-model="precisionForm.detailItem[2].endValue"
                          placeholder="请输入内容"
                          size="mini"
                          :disabled="disabled"
                          oninput="if(value<=0){value=''} if(value>9999){value=9999}"
                        ></el-input>
                        <span class="marL10">次</span>
                      </div>
                    </div>
                    <!-- 未消费时长 -->
                    <div class="marB50 x-f">
                      <el-checkbox
                        :disabled="disabled"
                        v-model="precisionForm.detailItem[3].isSelected"
                        >未消费时长:</el-checkbox
                      >
                      <div
                        class="x-f"
                        v-show="precisionForm.detailItem[3].isSelected"
                      >
                        <el-input
                          type="number"
                          class="inputWidthPrecision marL33"
                          v-model="precisionForm.detailItem[3].begValue"
                          placeholder="请输入内容"
                          size="mini"
                          :disabled="disabled"
                          oninput="if(value<=0){value=''} if(value>9999){value=9999}"
                        ></el-input>
                        <span class="marR10 marL10"> —— </span>
                        <el-input
                          class="inputWidthPrecision"
                          v-model="precisionForm.detailItem[3].endValue"
                          placeholder="请输入内容"
                          size="mini"
                          :disabled="disabled"
                          oninput="if(value<=0){value=''} if(value>9999){value=9999}"
                        ></el-input>
                        <span class="marL10">天</span>
                      </div>
                    </div>
                    <!-- 会员消费门店 -->
                    <div class="x-f">
                      <el-checkbox
                        :disabled="disabled"
                        v-model="precisionForm.detailItem[4].isSelected"
                        >会员消费门店:</el-checkbox
                      >
                      <el-radio-group
                        class="marL20 marR20"
                        v-model="precisionForm.detailItem[4].timeRangeType"
                        size="medium"
                        :disabled="disabled"
                        v-show="precisionForm.detailItem[4].isSelected"
                      >
                        <el-radio-button :label="1">近一周</el-radio-button>
                        <el-radio-button :label="2">近一个月</el-radio-button>
                        <el-radio-button :label="3">三个月</el-radio-button>
                        <el-radio-button :label="4">半年</el-radio-button>
                        <el-radio-button :label="5">自定义</el-radio-button>
                      </el-radio-group>
                      <div
                        v-show="
                          precisionForm.detailItem[4].isSelected &&
                          precisionForm.detailItem[4].timeRangeType == 5
                        "
                      >
                        <el-date-picker
                          class="marR20"
                          v-model="datePicker4"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :default-time="['00:00:00', '23:59:59']"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          size="mini"
                          :disabled="disabled"
                        />
                      </div>
                      <div
                        class="x-f"
                        v-show="precisionForm.detailItem[4].isSelected"
                      >
                        <el-button type="text"
                        :disabled="disabled" @click="getOpenShops(4)"
                          >选择门店</el-button
                        >
                        <span
                          class="marL10"
                          v-show="
                            precisionForm.detailItem[4].shopItem.length > 0
                          "
                          >{{
                            precisionForm.detailItem[4].shopItem.length
                          }}个</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 按商品喜欢 -->
                <div class="x-x marB50">
                  <el-checkbox v-model="precisionForm.isUseGoodsLike"
                    >按商品喜欢:</el-checkbox
                  >
                  <div
                    class="filterCriteriaTwo"
                    v-show="precisionForm.isUseGoodsLike"
                  >
                    <!-- 消费过的商品 -->
                    <div class="marB50 x-f">
                      <el-checkbox
                        :disabled="disabled"
                        v-model="precisionForm.detailItem[5].isSelected"
                        >消费过的商品:</el-checkbox
                      >
                      <el-radio-group
                        class="marL20 marR20"
                        v-model="precisionForm.detailItem[5].timeRangeType"
                        size="medium"
                        :disabled="disabled"
                        v-show="precisionForm.detailItem[5].isSelected"
                      >
                        <el-radio-button :label="1">近一周</el-radio-button>
                        <el-radio-button :label="2">近一个月</el-radio-button>
                        <el-radio-button :label="3">三个月</el-radio-button>
                        <el-radio-button :label="4">半年</el-radio-button>
                        <el-radio-button :label="5">自定义</el-radio-button>
                      </el-radio-group>
                      <div
                        v-show="
                          precisionForm.detailItem[5].isSelected &&
                          precisionForm.detailItem[5].timeRangeType == 5
                        "
                      >
                        <el-date-picker
                          class="marR20"
                          v-model="datePicker5"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :default-time="['00:00:00', '23:59:59']"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          size="mini"
                          :disabled="disabled"
                        />
                      </div>
                      <div
                        class="x-f"
                        v-show="precisionForm.detailItem[5].isSelected"
                      >
                        <el-button type="text"
                        :disabled="disabled" @click="getOpenGoods(5)"
                          >选择商品</el-button
                        >
                        <span
                          class="marL10"
                          v-show="
                            precisionForm.detailItem[5].goodsItem.length > 0
                          "
                          >{{
                            precisionForm.detailItem[5].goodsItem.length
                          }}种</span
                        >
                      </div>
                    </div>
                    <!-- 消费过的品类 -->
                    <div class="marB50 x-f">
                      <el-checkbox
                        :disabled="disabled"
                        v-model="precisionForm.detailItem[6].isSelected"
                        >消费过的品类:</el-checkbox
                      >
                      <el-radio-group
                        class="marL20 marR20"
                        v-model="precisionForm.detailItem[6].timeRangeType"
                        size="medium"
                        :disabled="disabled"
                        v-show="precisionForm.detailItem[6].isSelected"
                      >
                        <el-radio-button :label="1">近一周</el-radio-button>
                        <el-radio-button :label="2">近一个月</el-radio-button>
                        <el-radio-button :label="3">三个月</el-radio-button>
                        <el-radio-button :label="4">半年</el-radio-button>
                        <el-radio-button :label="5">自定义</el-radio-button>
                      </el-radio-group>
                      <div
                        v-show="
                          precisionForm.detailItem[6].isSelected &&
                          precisionForm.detailItem[6].timeRangeType == 5
                        "
                      >
                        <el-date-picker
                          class="marR20"
                          v-model="datePicker6"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :default-time="['00:00:00', '23:59:59']"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          size="mini"
                          :disabled="disabled"
                        />
                      </div>
                      <div
                        class="x-f"
                        v-show="precisionForm.detailItem[6].isSelected"
                      >
                        <el-button type="text" 
                        :disabled="disabled" @click="getOpenCategorys(6)"
                          >选择品类</el-button
                        >
                        <span
                          class="marL10"
                          v-show="
                            precisionForm.detailItem[6].goodsItem.length > 0
                          "
                          >{{
                            precisionForm.detailItem[6].goodsItem.length
                          }}种</span
                        >
                      </div>
                    </div>
                    <!-- 消费过的品牌 -->
                    <div class="marB50 x-f">
                      <el-checkbox
                        :disabled="disabled"
                        v-model="precisionForm.detailItem[7].isSelected"
                        >消费过的品牌:</el-checkbox
                      >
                      <el-radio-group
                        class="marL20 marR20"
                        v-model="precisionForm.detailItem[7].timeRangeType"
                        size="medium"
                        :disabled="disabled"
                        v-show="precisionForm.detailItem[7].isSelected"
                      >
                        <el-radio-button :label="1">近一周</el-radio-button>
                        <el-radio-button :label="2">近一个月</el-radio-button>
                        <el-radio-button :label="3">三个月</el-radio-button>
                        <el-radio-button :label="4">半年</el-radio-button>
                        <el-radio-button :label="5">自定义</el-radio-button>
                      </el-radio-group>
                      <div
                        v-show="
                          precisionForm.detailItem[7].isSelected &&
                          precisionForm.detailItem[7].timeRangeType == 5
                        "
                      >
                        <el-date-picker
                          class="marR20"
                          v-model="datePicker7"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :default-time="['00:00:00', '23:59:59']"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          size="mini"
                          :disabled="disabled"
                        />
                      </div>
                      <div
                        class="x-f"
                        v-show="precisionForm.detailItem[7].isSelected"
                      >
                        <el-button
                        :disabled="disabled" type="text" @click="getOpenBrands(7)"
                          >选择品牌</el-button
                        >
                        <span
                          class="marL10"
                          v-show="
                            precisionForm.detailItem[7].goodsItem.length > 0
                          "
                          >{{
                            precisionForm.detailItem[7].goodsItem.length
                          }}种</span
                        >
                      </div>
                    </div>
                    <!-- 未消费过的商品 -->
                    <div class="marB50 x-f">
                      <el-checkbox
                        :disabled="disabled"
                        v-model="precisionForm.detailItem[8].isSelected"
                        >未消费的商品:</el-checkbox
                      >
                      <el-radio-group
                        class="marL20 marR20"
                        v-model="precisionForm.detailItem[8].timeRangeType"
                        size="medium"
                        :disabled="disabled"
                        v-show="precisionForm.detailItem[8].isSelected"
                      >
                        <el-radio-button :label="1">近一周</el-radio-button>
                        <el-radio-button :label="2">近一个月</el-radio-button>
                        <el-radio-button :label="3">三个月</el-radio-button>
                        <el-radio-button :label="4">半年</el-radio-button>
                        <el-radio-button :label="5">自定义</el-radio-button>
                      </el-radio-group>
                      <div
                        v-show="
                          precisionForm.detailItem[8].isSelected &&
                          precisionForm.detailItem[8].timeRangeType == 5
                        "
                      >
                        <el-date-picker
                          class="marR20"
                          v-model="datePicker8"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :default-time="['00:00:00', '23:59:59']"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          size="mini"
                          :disabled="disabled"
                        />
                      </div>
                      <div
                        class="x-f"
                        v-show="precisionForm.detailItem[8].isSelected"
                      >
                        <el-button
                        :disabled="disabled" type="text" @click="getOpenGoods(8)"
                          >选择商品</el-button
                        >
                        <span
                          class="marL10"
                          v-show="
                            precisionForm.detailItem[8].goodsItem.length > 0
                          "
                          >{{
                            precisionForm.detailItem[8].goodsItem.length
                          }}种</span
                        >
                      </div>
                    </div>
                    <!-- 未消费过的品类 -->
                    <div class="marB50 x-f">
                      <el-checkbox
                        :disabled="disabled"
                        v-model="precisionForm.detailItem[9].isSelected"
                        >未消费的品类:</el-checkbox
                      >
                      <el-radio-group
                        class="marL20 marR20"
                        v-model="precisionForm.detailItem[9].timeRangeType"
                        size="medium"
                        :disabled="disabled"
                        v-show="precisionForm.detailItem[9].isSelected"
                      >
                        <el-radio-button :label="1">近一周</el-radio-button>
                        <el-radio-button :label="2">近一个月</el-radio-button>
                        <el-radio-button :label="3">三个月</el-radio-button>
                        <el-radio-button :label="4">半年</el-radio-button>
                        <el-radio-button :label="5">自定义</el-radio-button>
                      </el-radio-group>
                      <div
                        v-show="
                          precisionForm.detailItem[9].isSelected &&
                          precisionForm.detailItem[9].timeRangeType == 5
                        "
                      >
                        <el-date-picker
                          class="marR20"
                          v-model="datePicker9"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :default-time="['00:00:00', '23:59:59']"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          size="mini"
                          :disabled="disabled"
                        />
                      </div>
                      <div
                        class="x-f"
                        v-show="precisionForm.detailItem[9].isSelected"
                      >
                        <el-button
                        :disabled="disabled" type="text" @click="getOpenCategorys(9)"
                          >选择品类</el-button
                        >
                        <span
                          class="marL10"
                          v-show="
                            precisionForm.detailItem[9].goodsItem.length > 0
                          "
                          >{{
                            precisionForm.detailItem[9].goodsItem.length
                          }}种</span
                        >
                      </div>
                    </div>
                    <!-- 未消费过的品牌 -->
                    <div class="x-f">
                      <el-checkbox
                        :disabled="disabled"
                        v-model="precisionForm.detailItem[10].isSelected"
                        >未消费的品牌:</el-checkbox
                      >
                      <el-radio-group
                        class="marL20 marR20"
                        v-model="precisionForm.detailItem[10].timeRangeType"
                        size="medium"
                        :disabled="disabled"
                        v-show="precisionForm.detailItem[10].isSelected"
                      >
                        <el-radio-button :label="1">近一周</el-radio-button>
                        <el-radio-button :label="2">近一个月</el-radio-button>
                        <el-radio-button :label="3">三个月</el-radio-button>
                        <el-radio-button :label="4">半年</el-radio-button>
                        <el-radio-button :label="5">自定义</el-radio-button>
                      </el-radio-group>
                      <div
                        v-show="
                          precisionForm.detailItem[10].isSelected &&
                          precisionForm.detailItem[10].timeRangeType == 5
                        "
                      >
                        <el-date-picker
                          class="marR20"
                          v-model="datePicker10"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :default-time="['00:00:00', '23:59:59']"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          size="mini"
                          :disabled="disabled"
                        />
                      </div>
                      <div
                        class="x-f"
                        v-show="precisionForm.detailItem[10].isSelected"
                      >
                        <el-button
                        :disabled="disabled" type="text" @click="getOpenBrands(10)"
                          >选择品牌</el-button
                        >
                        <span
                          class="marL10"
                          v-show="
                            precisionForm.detailItem[10].goodsItem.length > 0
                          "
                          >{{
                            precisionForm.detailItem[10].goodsItem.length
                          }}种</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 按会员账户 -->
                <div class="x-x marB50">
                  <el-checkbox v-model="precisionForm.isUseVipAccount"
                    >按会员账户:</el-checkbox
                  >
                  <div
                    class="filterCriteriaTwo"
                    v-show="precisionForm.isUseVipAccount"
                  >
                    <!-- 累计充值的金额 -->
                    <div class="marB50 x-f">
                      <el-checkbox
                        :disabled="disabled"
                        v-model="precisionForm.detailItem[11].isSelected"
                        >累计充值金额:</el-checkbox
                      >
                      <el-radio-group
                        class="marL20 marR20"
                        v-model="precisionForm.detailItem[11].timeRangeType"
                        size="medium"
                        :disabled="disabled"
                        v-show="precisionForm.detailItem[11].isSelected"
                      >
                        <el-radio-button :label="1">近一周</el-radio-button>
                        <el-radio-button :label="2">近一个月</el-radio-button>
                        <el-radio-button :label="3">三个月</el-radio-button>
                        <el-radio-button :label="4">半年</el-radio-button>
                        <el-radio-button :label="5">自定义</el-radio-button>
                      </el-radio-group>
                      <div
                        v-show="
                          precisionForm.detailItem[11].isSelected &&
                          precisionForm.detailItem[11].timeRangeType == 5
                        "
                      >
                        <el-date-picker
                          class="marR20"
                          v-model="datePicker11"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :default-time="['00:00:00', '23:59:59']"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          size="mini"
                          :disabled="disabled"
                        />
                      </div>
                      <div
                        class="x-f"
                        v-show="precisionForm.detailItem[11].isSelected"
                      >
                        <el-input
                          type="number"
                          class="inputWidthPrecision"
                          v-model="precisionForm.detailItem[11].begValue"
                          placeholder="请输入内容"
                          size="mini"
                          :disabled="disabled"
                          oninput="if(value<=0){value=''} if(value>99999999){value=99999999}"
                        ></el-input>
                        <span class="marR10 marL10">——</span>
                        <el-input
                          type="number"
                          class="inputWidthPrecision"
                          v-model="precisionForm.detailItem[11].endValue"
                          placeholder="请输入内容"
                          size="mini"
                          :disabled="disabled"
                          oninput="if(value<=0){value=''} if(value>99999999){value=99999999}"
                        ></el-input>
                        <span class="marL10">元</span>
                      </div>
                    </div>
                    <!-- 累计充值的次数 -->
                    <div class="marB50 x-f">
                      <el-checkbox
                        :disabled="disabled"
                        v-model="precisionForm.detailItem[12].isSelected"
                        >累计充值次数:</el-checkbox
                      >
                      <el-radio-group
                        class="marL20 marR20"
                        v-model="precisionForm.detailItem[12].timeRangeType"
                        size="medium"
                        :disabled="disabled"
                        v-show="precisionForm.detailItem[12].isSelected"
                      >
                        <el-radio-button :label="1">近一周</el-radio-button>
                        <el-radio-button :label="2">近一个月</el-radio-button>
                        <el-radio-button :label="3">三个月</el-radio-button>
                        <el-radio-button :label="4">半年</el-radio-button>
                        <el-radio-button :label="5">自定义</el-radio-button>
                      </el-radio-group>
                      <div
                        v-show="
                          precisionForm.detailItem[12].isSelected &&
                          precisionForm.detailItem[12].timeRangeType == 5
                        "
                      >
                        <el-date-picker
                          class="marR20"
                          v-model="datePicker12"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :default-time="['00:00:00', '23:59:59']"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          size="mini"
                          :disabled="disabled"
                        />
                      </div>
                      <div
                        class="x-f"
                        v-show="precisionForm.detailItem[12].isSelected"
                      >
                        <el-input
                          type="number"
                          class="inputWidthPrecision"
                          v-model="precisionForm.detailItem[12].begValue"
                          placeholder="请输入内容"
                          size="mini"
                          :disabled="disabled"
                          oninput="if(value<=0){value=''} if(value>9999){value=9999}"
                        ></el-input>
                        <span class="marR10 marL10">——</span>
                        <el-input
                          type="number"
                          class="inputWidthPrecision"
                          v-model="precisionForm.detailItem[12].endValue"
                          placeholder="请输入内容"
                          size="mini"
                          :disabled="disabled"
                          oninput="if(value<=0){value=''} if(value>9999){value=9999}"
                        ></el-input>
                        <span class="marL10">次</span>
                      </div>
                    </div>
                    <!-- 会员余额 -->
                    <div class="marB50 x-f">
                      <el-checkbox
                        :disabled="disabled"
                        v-model="precisionForm.detailItem[13].isSelected"
                        >会员余额:</el-checkbox
                      >
                      <div
                        class="x-f"
                        v-show="precisionForm.detailItem[13].isSelected"
                      >
                        <el-input
                          type="number"
                          class="inputWidthPrecision marL33"
                          v-model="precisionForm.detailItem[13].begValue"
                          placeholder="请输入内容"
                          size="mini"
                          :disabled="disabled"
                          oninput="if(value<=0){value=''} if(value>99999999){value=99999999}"
                        ></el-input>
                        <span class="marR10 marL10"> —— </span>
                        <el-input
                          class="inputWidthPrecision"
                          v-model="precisionForm.detailItem[13].endValue"
                          placeholder="请输入内容"
                          size="mini"
                          :disabled="disabled"
                          oninput="if(value<=0){value=''} if(value>99999999){value=99999999}"
                        ></el-input>
                        <span class="marL10">元</span>
                      </div>
                    </div>
                    <!-- 会员积分 -->
                    <div class="x-f">
                      <el-checkbox
                        :disabled="disabled"
                        v-model="precisionForm.detailItem[14].isSelected"
                        >会员积分:</el-checkbox
                      >
                      <div
                        class="x-f"
                        v-show="precisionForm.detailItem[14].isSelected"
                      >
                        <el-input
                          type="number"
                          class="inputWidthPrecision marL33"
                          v-model="precisionForm.detailItem[14].begValue"
                          placeholder="请输入内容"
                          size="mini"
                          :disabled="disabled"
                          oninput="if(value<=0){value=''} if(value>9999999){value=9999999}"
                        ></el-input>
                        <span class="marR10 marL10"> —— </span>
                        <el-input
                          type="number"
                          class="inputWidthPrecision"
                          v-model="precisionForm.detailItem[14].endValue"
                          placeholder="请输入内容"
                          size="mini"
                          :disabled="disabled"
                          oninput="if(value<=0){value=''} if(value>9999999){value=9999999}"
                        ></el-input>
                        <span class="marL10">分</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 按会员特征 -->
                <div class="x-x marB50">
                  <el-checkbox 
                        :disabled="disabled" v-model="precisionForm.isUseVipMark"
                    >按会员特征:</el-checkbox
                  >
                  <div
                    class="filterCriteriaTwo"
                    v-show="precisionForm.isUseVipMark"
                  >
                    <!-- 会员开卡门店 -->
                    <div class="marB50 x-f">
                      <el-checkbox
                        :disabled="disabled"
                        v-model="precisionForm.detailItem[15].isSelected"
                        >会员开卡门店:</el-checkbox
                      >
                      <el-button
                        class="marL33"
                        type="text"
                        :disabled="disabled"
                        v-show="precisionForm.detailItem[15].isSelected"
                        @click="getOpenShops(15)"
                        >选择门店</el-button
                      >
                      <span
                        class="marL10"
                        v-show="
                          precisionForm.detailItem[15].shopItem.length > 0
                        "
                        >{{
                          precisionForm.detailItem[15].shopItem.length
                        }}种</span
                      >
                    </div>
                    <!-- 会员年龄范围 -->
                    <div class="marB50 x-f">
                      <el-checkbox
                        :disabled="disabled"
                        v-model="precisionForm.detailItem[16].isSelected"
                        >会员年龄范围:</el-checkbox
                      >

                      <el-radio-group
                        class="marL20"
                        :disabled="disabled"
                        v-model="precisionForm.detailItem[16].ageRangeType"
                        v-show="precisionForm.detailItem[16].isSelected"
                      >
                        <el-radio :label="1">20~30</el-radio>
                        <el-radio :label="2">31~40</el-radio>
                        <el-radio :label="3">41~50</el-radio>
                        <el-radio :label="4">自定义</el-radio>
                      </el-radio-group>
                      <div
                        class="x-f"
                        v-show="precisionForm.detailItem[16].ageRangeType == 4"
                      >
                        <el-input
                          type="number"
                          class="inputWidthPrecision marL33"
                          v-model="precisionForm.detailItem[16].begValue"
                          placeholder="请输入内容"
                          size="mini"
                          :disabled="disabled"
                          oninput="if(value<=0){value=''} if(value>999){value=999}"
                        ></el-input>
                        <span class="marR10 marL10"> —— </span>
                        <el-input
                          type="number"
                          class="inputWidthPrecision"
                          v-model="precisionForm.detailItem[16].endValue"
                          placeholder="请输入内容"
                          size="mini"
                          :disabled="disabled"
                          oninput="if(value<=0){value=''} if(value>999){value=999}"
                        ></el-input>
                        <span class="marL10">岁</span>
                      </div>
                    </div>
                    <!-- 会员级别 -->
                    <div class="marB50 x-f">
                      <el-checkbox
                        :disabled="disabled"
                        v-model="precisionForm.detailItem[17].isSelected"
                        >会员级别:</el-checkbox
                      >
                      <el-button
                        class="marL33"
                        type="text"
                        :disabled="disabled"
                        v-show="precisionForm.detailItem[17].isSelected"
                        @click="getOpenVipLevels(17)"
                        >选择级别</el-button
                      >
                      <span
                        class="marL10"
                        v-show="
                          precisionForm.detailItem[17].vipLevelItem.length > 0
                        "
                        >{{
                          precisionForm.detailItem[17].vipLevelItem.length
                        }}种</span
                      >
                    </div>
                    <!-- 开卡时间 -->
                    <div class="marB50 x-f">
                      <el-checkbox
                        :disabled="disabled"
                        v-model="precisionForm.detailItem[18].isSelected"
                        >开卡时间:</el-checkbox
                      >
                      <el-radio-group
                        class="marL20 marR20"
                        v-model="precisionForm.detailItem[18].timeRangeType"
                        size="medium"
                        :disabled="disabled"
                        v-show="precisionForm.detailItem[18].isSelected"
                      >
                        <el-radio-button :label="1">近一周</el-radio-button>
                        <el-radio-button :label="2">近一个月</el-radio-button>
                        <el-radio-button :label="3">三个月</el-radio-button>
                        <el-radio-button :label="4">半年</el-radio-button>
                        <el-radio-button :label="5">自定义</el-radio-button>
                      </el-radio-group>
                      <div
                        v-show="
                          precisionForm.detailItem[18].isSelected &&
                          precisionForm.detailItem[18].timeRangeType == 5
                        "
                      >
                        <el-date-picker
                          class="marR20"
                          v-model="datePicker18"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :default-time="['00:00:00', '23:59:59']"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          size="mini"
                          :disabled="disabled"
                        />
                      </div>
                    </div>
                    <!--  会员生日 -->
                    <div class="marB50 x-f">
                      <el-checkbox
                        :disabled="disabled"
                        v-model="precisionForm.detailItem[19].isSelected"
                        >会员生日:</el-checkbox
                      >
                      <el-radio-group
                        class="marL20 marR20"
                        v-model="precisionForm.detailItem[19].timeRangeType"
                        size="medium"
                        :disabled="disabled"
                        v-show="precisionForm.detailItem[19].isSelected"
                      >
                        <el-radio-button :label="1">近一周</el-radio-button>
                        <el-radio-button :label="2">近一个月</el-radio-button>
                        <el-radio-button :label="3">三个月</el-radio-button>
                        <el-radio-button :label="4">半年</el-radio-button>
                        <el-radio-button :label="5">自定义</el-radio-button>
                      </el-radio-group>
                      <div
                        v-show="
                          precisionForm.detailItem[19].isSelected &&
                          precisionForm.detailItem[19].timeRangeType == 5
                        "
                      >
                        <el-date-picker
                          class="marR20"
                          v-model="datePicker19"
                          type="datetimerange"
                          :default-time="['00:00:00', '23:59:59']"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          size="mini"
                          :disabled="disabled"
                        />
                      </div>
                    </div>
                    <!--  会员性别 -->
                    <div class="x-f">
                      <el-checkbox
                        :disabled="disabled"
                        v-model="precisionForm.detailItem[20].isSelected"
                        >会员性别:</el-checkbox
                      >
                      <div
                        v-show="precisionForm.detailItem[20].isSelected"
                        class="marL15"
                      >
                        <el-select
                          v-model="precisionForm.detailItem[20].sexRangeType"
                          placeholder="请选择性别"
                          filterable
                          clearable
                          size="mini"
                          :disabled="disabled"
                        >
                          <el-option
                            v-for="dict in dict.type.sys_user_sex"
                            :key="dict.value"
                            :label="dict.label"
                            :value="parseInt(dict.value)"
                          />
                        </el-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </cardTitleCom>
        <!-- 目标对象列表 -->
        <cardTitleCom cardTitle="目标对象列表" v-show="activeStep === 1">
          <template slot="cardContent">
            <div class="tableContent">
              <div class="marB10">
                <!-- <el-button size="mini">批量贴标签</el-button>
                <el-button icon="el-icon-upload2" size="mini">导出</el-button> -->
              </div>

              <el-table
                tooltip-effect="dark"
                :data="vipList"
                border
                max-height="500"
                height="500"
                v-loading="loadingVip"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  prop="userId"
                  width="80"
                  type="index"
                >
                <template slot-scope="{ $index, row }">
                    <div>
                      {{
                        $index + 1 + (queryOptions.pageNum - 1) * queryOptions.pageSize
                      }}
                    </div>
                  </template>
                </el-table-column>
                <!-- <el-table-column
                  align="center"
                  label="操作"
                  width="80"
                  v-if="!disabled"
                >
                  <template v-slot="scope">
                    <i
                      @click="deleteRow(scope.$index, vipList)"
                      class="el-icon-remove operateDel"
                    ></i>
                  </template>
                </el-table-column> -->
                <el-table-column
                  label="会员编号"
                  width="160"
                  align="center"
                  prop="vipNo"
                />
                <el-table-column
                  label="会员名称"
                  width="160"
                  align="center"
                  prop="vipName"
                />
                <el-table-column
                  label="会员手机号"
                  align="center"
                  width="196"
                  prop="tel"
                />
                <el-table-column />
              </el-table>
                <!-- :page-size="100" -->
                <div style="text-align: right;padding: 20px;" v-if="refreshPinigation">
                  <el-pagination
                    background
                    @size-change="handleSizeChangeFun"
                    :page-sizes="[10, 20, 30]"
                    @current-change="handleCurrentChangeFun"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                  </el-pagination>
                </div>
            </div>
          </template>
        </cardTitleCom>
        <!-- 礼包领取设置 -->
        <cardTitleCom
          cardTitle="礼包领取设置"
          v-show="activeStep == 2 || activeStep == 3"
        >
          <template slot="cardContent">
            <div class="modifyType">
              <el-form-item
                label="用户领取方式"
                prop="billNo"
                label-width="96px"
              >
                <el-input
                  class="inputWidth"
                  :disabled="true"
                  v-model="precisionForm.billNo2"
                  placeholder="直接到账"
                  size="mini"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-radio-group
                  v-model="precisionForm.effectMode"
                  :disabled="disabled"
                >
                  <el-radio :label="1">立即生效</el-radio>
                  <el-radio :label="2">指定时间生效</el-radio>
                </el-radio-group>
                <span v-show="precisionForm.effectMode == 2" class="marL15">
                  <el-date-picker
                    :disabled="disabled"
                    v-model="precisionForm.effectBegTime"
                    type="datetime"
                    :picker-options="effectPickerOptions"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    size="mini"
                  />
                </span>
              </el-form-item>
            </div>
          </template>
        </cardTitleCom>
        <!-- 礼包内容 -->
        <cardTitleCom
          cardTitle="礼包内容"
          v-show="activeStep == 2 || activeStep == 3"
        >
          <template slot="cardContent">
            <div class="packageContents">
              <!-- 赠送积分 -->
              <div class="marB20 x-f" style="height: 30px">
                <el-checkbox
                  v-model="precisionForm.isLargessScore"
                  :disabled="disabled"
                  >赠送积分:</el-checkbox
                >
                <div v-show="precisionForm.isLargessScore">
                  <el-input
                    type="number"
                    class="inputWidth marL15 marR15"
                    :disabled="disabled"
                    v-model="precisionForm.largessScore"
                    placeholder="赠送积分"
                    size="mini"
                    oninput="if(value<=0){value=''} if(value>9999999){value=9999999}"
                  ></el-input>
                  <span class="fontS14">分</span>
                </div>
              </div>
              <!-- 赠送优惠券 -->
              <div>
                <el-checkbox
                  v-model="precisionForm.isLargessCoupon"
                  :disabled="disabled"
                  >赠送优惠券:</el-checkbox
                >
                <div v-show="precisionForm.isLargessCoupon" class="marT10">
                  <!-- <el-button
                    size="mini"
                    @click="openCoupon = true"
                    class="marB10"
                    >选择优惠券</el-button
                  > -->
                  <el-button
                    size="mini"
                    @click="handleEvent('openCouponDialog')"
                    class="marB10"
                    :disabled="disabled"
                    >选择优惠券</el-button
                  >
                  <!-- <el-button>编辑优惠券</el-button> -->
                  <el-table
                    style="width: 100%"
                    tooltip-effect="dark"
                    :data="precisionForm.largessCouponItem"
                    border
                    max-height="500"
                  >
                    <el-table-column
                      label="序号"
                      align="center"
                      prop="userId"
                      width="80"
                      type="index"
                    />
                    <el-table-column
                      align="center"
                      label="操作"
                      width="80"
                      v-if="!disabled"
                    >
                      <template v-slot="scope">
                        <el-button
                          @click.native.prevent="
                            deleteRow(
                              scope.$index,
                              precisionForm.largessCouponItem
                            )
                          "
                          type="text"
                          size="mini"
                        >
                          <i class="el-icon-remove operateDel" />
                        </el-button>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="优惠券名称"
                      width="160"
                      align="center"
                      prop="couponCaseName"
                    />
                    <el-table-column
                      label="赠送数量(单个会员)"
                      align="center"
                      width="196"
                      prop="couponEmitQty"
                    />
                    <el-table-column
                      label="赠送方式"
                      align="center"
                      prop="couponEmitModeName"
                      show-overflow-tooltip
                    />
                    <el-table-column
                      label="生效方式"
                      align="center"
                      prop="couponEffectModeName"
                      show-overflow-tooltip
                    />
                  </el-table>
                </div>
              </div>
            </div>
          </template>
        </cardTitleCom>
      </el-form>
    </div>
    <!-- 选择商品信息框 -->
    <selectGoods
      :OpenGoods.sync="openGoods"
      @getGoodS="getGoodsCom"
      ref="selectGoods"
    />
    <!-- 选择商品分类信息框 -->
    <selectCategory
      :OpenCategory.sync="openCategory"
      @categoryList="categoryListCom"
    />
    <!-- 选择商品品牌信息框 -->
    <selectBrand :OpenBrand.sync="openBrand" @brandList="brandListCom" />
    <!-- 选择门店信息框 -->
    <shopInfo
      :OpenShopInfo.sync="openShopInfo"
      @shopInfoList="shopInfoListCom"
    />
    <!-- 选择会员等级信息框 -->
    <vipLeve :OpenVipLeve.sync="openVipLeve" @vipLeveList="vipLeveListCom" />
    <!-- 选择优惠券对话框 -->
    <selectCoupon
      :OpenCoupon.sync="openCoupon"
      :isRadio="true"
      :isSuingEditorIsRadio="true"
      @getCoupons="getPitchData($event, 'coupon')"
    />
    <Dialog
      ref="tablePage"
      :options.sync="dialogOptions"
      @handleEvent="handleEvent"
    />
  </div>
</template>

<script>
import { uniqWith } from 'lodash' //去重
import Dialog from '@/components/Dialog'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
// import bottomPage from '@/views/components/bottomPage' //底部分页
import selectGoods from '@/views/components/selectGoodsUp' //选择商品
import selectCategory from '@/views/components/selectCategory' //选择商品分类
import selectBrand from '@/views/components/selectBrand' //选择商品品牌
import shopInfo from '@/views/components/shopInfo' //选择门店信息
import vipLeve from '@/views/components/vipLeve' //选择会员等级
import selectCoupon from '@/views/components/selectCoupon' //优惠券组件
import { getBillNo } from '@/api/codeRule' //方案号
import { getNewDate } from "@/utils/newDate"; //单据日期
import {
  precisionAddAPI,
  precisionDetailAPI,
  precisionUpdateAPI,
  precisionIsStopAPI,
  precisionUpdateStatusAPI,
  precisionGetVipsAPI
} from '@/api/marketing/vipMarketing/precision' //精准营销
export default {
  name: 'precisionDetail',
  dicts: ['sys_user_sex'],
  components: {
    cardTitleCom,
    // bottomPage,
    selectGoods,
    selectCategory,
    selectBrand,
    shopInfo,
    vipLeve,
    selectCoupon,
    Dialog
  },
  data () {
    return {
      
      dialogOptions: {
          curType: '', //当前类型(门店/会员/商品/类别/品牌)
          title: '选择商品',
          width: 1250,
          show: false,
          type: 'TreeAndTable'
      },
      pickerOptions: {
        //会员生日只能选择月日的日期组件
        disabledDate(time){
          const year = new Date().getFullYear();
          // 当年第一天
          const firstDayOfYear = new Date(year, 0, 1);
          // 当年最后一天
          const lastDayOfYear = new Date(year, 11, 31);
          return time.getTime() < firstDayOfYear || time.getTime() > lastDayOfYear;
        }
      },
      effectPickerOptions:{
        //设置礼包指定生效时间不能小于当前时间
        disabledDate(time){
          const lastDay = getNewDate(-1);
          return time.getTime() < new Date(lastDay);
        }
      },
      currentPage: 0,
      total: 0, // 总条数
      queryOptions: {
        pageNum: 1,
        pageSize: 10,
      },
      //会员数据
      vipList: [],
      //弹窗控制下标
      selectIndex: undefined,
      //选择优惠券开关
      openCoupon: false,
      //级别开关
      openVipLeve: false,
      //品牌开关
      openBrand: false,
      //分类开关
      openCategory: false,
      //选择商品信息开关
      openGoods: false,
      //门店弹窗
      openShopInfo: false,
      //日期范围
      datePicker0: undefined,
      datePicker1: undefined,
      datePicker2: undefined,
      datePicker4: undefined,
      datePicker5: undefined,
      datePicker6: undefined,
      datePicker7: undefined,
      datePicker8: undefined,
      datePicker9: undefined,
      datePicker10: undefined,
      datePicker11: undefined,
      datePicker12: undefined,
      datePicker18: undefined,
      datePicker19: undefined,
      //标识已审核
      auditOrder: false,
      //标识未审核
      noAuditOrder: false,
      //标识已关闭
      stopOrder: false,
      //标识已删除
      deleteOrder: false,
      //标识已禁用
      disableOrder: false,
      showAudit: true, //审核按钮禁用状态
      showAdd: false, //新增按钮
      disabled: false, //保存禁用
      activeStep: 0,
      loadingTable: false, //表格遮罩
      loadingVip: false, //会员表格
      loading: false, //大盒子遮罩层
      //旧的表单
      oldForm: {},
      // 表单参数
      precisionForm: {
        billStatus: '',
        isLargessScore: false, //赠送积分
        isLargessCoupon: false, //赠送优惠券
        effectMode: 1, //领取方式
        largessCouponItem: [], //优惠
        precisionTargetType: 2, //目标类型
        isUseConsumeAction: false, //按消费行为
        isUseGoodsLike: false, //按商品喜好
        isUseVipAccount: false, //按会员账号
        isUseVipMark: false, //按会员特征
        detailItem: [
          {
            precisionType: 1,
            isSelected: false,
            timeRangeType: 1
          },
          {
            precisionType: 2,
            isSelected: false,
            timeRangeType: 1
          },
          {
            precisionType: 3,
            isSelected: false,
            timeRangeType: 1
          },
          {
            precisionType: 4,
            isSelected: false
          },
          {
            precisionType: 5,
            shopItem: [],
            isSelected: false,
            timeRangeType: 1
          },
          {
            precisionType: 6,
            goodsItem: [],
            isSelected: false,
            timeRangeType: 1
          },
          {
            precisionType: 7,
            goodsItem: [],
            isSelected: false,
            timeRangeType: 1
          },
          {
            precisionType: 8,
            goodsItem: [],
            isSelected: false,
            timeRangeType: 1
          },
          {
            precisionType: 9,
            goodsItem: [],
            isSelected: false,
            timeRangeType: 1
          },
          {
            precisionType: 10,
            goodsItem: [],
            isSelected: false,
            timeRangeType: 1
          },
          {
            precisionType: 11,
            goodsItem: [],
            isSelected: false,
            timeRangeType: 1
          },
          {
            precisionType: 12,
            isSelected: false,
            timeRangeType: 1
          },
          {
            precisionType: 13,
            isSelected: false,
            timeRangeType: 1
          },
          {
            precisionType: 14,
            isSelected: false
          },
          {
            precisionType: 15,
            isSelected: false
          },
          {
            precisionType: 16,
            shopItem: [],
            isSelected: false
          },
          {
            precisionType: 17,
            isSelected: false
          },
          {
            precisionType: 18,
            vipLevelItem: [],
            isSelected: false
          },
          {
            precisionType: 19,
            isSelected: false,
            timeRangeType: 1
          },
          {
            precisionType: 20,
            isSelected: false,
            timeRangeType: 1
          },
          {
            precisionType: 21,
            isSelected: false,
            sexRangeType: undefined
          }
        ] //会员营销精准营销方案明细
      },
      refreshPinigation:true,//刷新分页
    }
  },
  watch: {
    //侦听是否开启关闭按钮
    'precisionForm.billStatus': {
      handler (newVal) {
        if (this.precisionForm.billStatus == '2') {
          //新增按钮
          this.showAdd = true
          //打开审核图标
          this.auditOrder = true
          //禁用输入按钮、保存按钮
          this.disabled = true
          //打开审核按钮
          this.showAudit = false
          //关闭已禁用图标
          this.disableOrder = false
          //关闭关闭图标
          this.stopOrder = false
          //关闭已删除图标
          this.deleteOrder = false
          //关闭未审核图标
          this.noAuditOrder = false
        } else if (this.precisionForm.billStatus == '3') {
          //新增按钮
          this.showAdd = false
          //打开已删除图标
          this.deleteOrder = true
          //关闭已禁用图标
          this.disableOrder = false
          //关闭关闭图标
          this.stopOrder = false
          //关闭审核图标
          this.auditOrder = false
          //关闭未审核图标
          this.noAuditOrder = false
          //禁用审核按钮
          this.showAudit = true
          //禁止全部输入按钮
          this.disabled = true
        } else if (this.precisionForm.billStatus == '0') {
          //新增按钮
          this.showAdd = false
          //未审核图标
          this.noAuditOrder = true
          //禁用审核按钮
          this.showAudit = false
          //关闭审核图标
          this.auditOrder = false
          //关闭已禁用图标
          this.disableOrder = false
          //关闭关闭图标
          this.stopOrder = false
          //关闭已删除图标
          this.deleteOrder = false
          //恢复全部输入
          this.disabled = false
        } else if (this.precisionForm.billStatus == '4') {
          //新增按钮
          this.showAdd = false
          //打开关闭图标
          this.stopOrder = true
          //未审核图标
          this.noAuditOrder = false
          //禁用审核按钮
          this.showAudit = false
          //关闭审核图标
          this.auditOrder = false
          //关闭已禁用图标
          this.disableOrder = false
          //关闭已删除图标
          this.deleteOrder = false
          //恢复全部输入
          this.disabled = false
        } else if (this.precisionForm.billStatus == '') {
          //新增按钮
          this.showAdd = false
          //关闭已禁用图标
          this.disableOrder = false
          //关闭关闭图标
          this.stopOrder = false
          //关闭已删除图标
          this.deleteOrder = false
          //关闭审核图标
          this.auditOrder = false
          //关闭未审核图标
          this.noAuditOrder = false
          //禁用审核按钮
          this.showAudit = true
          //恢复全部输入
          this.disabled = false
        }
      },
      immediate: true
    },
    //单据日期0
    datePicker0 (newVal) {
      this.handleDatePicker(newVal, 0)
    },
    //单据日期1
    datePicker1 (newVal) {
      this.handleDatePicker(newVal, 1)
    },
    //单据日期2
    datePicker2 (newVal) {
      this.handleDatePicker(newVal, 2)
    },
    //单据日期4
    datePicker4 (newVal) {
      this.handleDatePicker(newVal, 4)
    },
    //单据日期5
    datePicker5 (newVal) {
      this.handleDatePicker(newVal, 5)
    },
    //单据日期6
    datePicker6 (newVal) {
      this.handleDatePicker(newVal, 6)
    },
    //单据日期7
    datePicker7 (newVal) {
      this.handleDatePicker(newVal, 7)
    },
    //单据日期8
    datePicker8 (newVal) {
      this.handleDatePicker(newVal, 8)
    },
    //单据日期9
    datePicker9 (newVal) {
      this.handleDatePicker(newVal, 9)
    },
    //单据日期10
    datePicker10 (newVal) {
      this.handleDatePicker(newVal, 10)
    },
    //单据日期11
    datePicker11 (newVal) {
      this.handleDatePicker(newVal, 11)
    },
    //单据日期12
    datePicker12 (newVal) {
      this.handleDatePicker(newVal, 12)
    },
    //单据日期18
    datePicker18 (newVal) {
      this.handleDatePicker(newVal, 18)
    },
    //单据日期19
    datePicker19 (newVal) {
      this.handleDatePicker(newVal, 19)
    },
    //单据日期20
    datePicker20 (newVal) {
      this.handleDatePicker(newVal, 20)
    }
  },
  beforeRouteEnter (to, from, next) {
    next(async vm => {
      vm.loading = true
      vm.reset()
      // 访问组件实例 vm 中的数据和方法
      if (vm.$route.query.type === 'Update') {
        if (vm.$route.query.billId) {
          //获取单据详情
          const { data } = await precisionDetailAPI(vm.$route.query.billId)
          //单据赋值
          vm.precisionForm = data
          if (vm.precisionForm.largessCouponItem.length > 0) {
            vm.precisionForm.largessCouponItem.forEach((item, index) => {
              //组装数据
              let assemblyCouponObj = vm.assemblyCoupon(item)
              vm.$set(
                vm.precisionForm.largessCouponItem,
                index,
                assemblyCouponObj
              )
            })
          }
          //原始单据赋值
          vm.oldForm = JSON.parse(JSON.stringify(vm.precisionForm))
          vm.handleCallbackDatetime(vm.oldForm.detailItem)
        }
      } else {
        await vm.getOrderBillNo()
        //判断是否有以前新增时保存的数据
        if (JSON.stringify(vm.oldForm) != '{}') {
          vm.precisionForm = vm.oldForm
        }
        vm.oldForm = JSON.parse(JSON.stringify(vm.precisionForm))
        vm.handleCallbackDatetime(vm.oldForm.detailItem)
      }
      vm.loading = false
    })
  },

  async beforeRouteLeave (to, from, next) {
    // 在用户离开二级路由前弹出询问框
    if (this.precisionForm.billId) {
      this.oldForm = {}
    } else {
      this.oldForm = JSON.parse(JSON.stringify(this.precisionForm))
    }
    next()
  },
  methods: {
    async handleEvent (type, row) {
            if (row?.curTable) this.curTable = row.curTable
                console.log(this.curTable, 'this.curTable')
            switch (type) {
                case 'openCouponDialog':
                  //弹窗配置
                  this.dialogOptions = {
                    curTable: this.couponIndex == 'coupon'? 'couponItem' : 'recommendCouponItem',
                    curType: 'couponCaseId', //当前类型(会员/门店/优惠券)
                    title: '选择优惠券',
                    width: 1250,
                    show: true,
                    type: 'TreeAndTable',
                    isStep: true, //是否允许下一步(主要用于优惠券)
                    // formData: this.$dialog({ key: 'coupon', option:{ queryParams: { isStops: 0, isInvalid: 1}}})
                    formData: this.$dialog({ key: 'coupon', option:{ queryParams: { isStops: 0, isInvalids: [0, 1]}}})
                    // formData: this.$dialog({ key: 'coupon', option:{ queryParams: { isStop: 0, isInvalid: 1  } }})
                  }
                  this.dialogOptions.formData.table.radioSelect = true
                  this.dialogOptions.formData.table.mutiSelect = false
                break
                case 'dialogChange':
                    let list = []
                    let dialogType = this.dialogOptions.curType
                    if (dialogType != 'timeDate') {
                        if (this.dialogOptions.formData.table.check.length <= 0) return
                        list = this.dialogOptions.formData.table.check
                        console.log(dialogType, 'dialogType')
                        console.log(list, 'list')
                        console.log(this.curTable, 'this.curTable')
                        if (dialogType =='couponCaseId') {
                          let curType = this.dialogOptions.curType
                          let arr = []
                          arr = this.precisionForm.largessCouponItem
                          const couponDrawModes = {
                              0: '每天',
                              1: '每周',
                              2: '每月',
                              3: '总共'
                          }
                          list = list.map(item => ({
                              ...item,
                              // couponCaseId : item.couponCaseId
                          }))
                          list = [...list, ...arr]
                          list = uniqWith(list, (x, y) => x.couponCaseId == y.couponCaseId)
                          console.log(list, 'list')
                          this.precisionForm.largessCouponItem = list
                        }
                    }
                    break
                default:
                break
            }
        },
    handleSizeChangeFun(val) {
      this.queryOptions.pageSize = val
      this.getList(this.queryOptions.pageNum, this.queryOptions.pageSize, this.precisionForm)
    },
    handleCurrentChangeFun(val) {
      this.queryOptions.pageNum = val
      this.getList(this.queryOptions.pageNum, this.queryOptions.pageSize, this.precisionForm)
    },
    //回显日期时间
    handleCallbackDatetime(data){
      if(data instanceof Array){
        data.forEach((item,index)=>{
          if(item.begTime && item.endTime){
            let tArr = [];
            tArr.push(item.begTime);
            tArr.push(item.endTime);
            this['datePicker' + index] = tArr;
          }
        })
      }
    },
    //优惠券确定键
    getPitchData (value) {
      if (value.length == 0) return
      //去重
      value = value.filter(item => {
        return this.precisionForm.largessCouponItem.every(
          v => v.couponCaseId != item.couponCaseId
        )
      })
      if (value.length == 0) return
      //组装数据
      let assemblyCouponObj = this.assemblyCoupon(value[0])
      let obj = {}
      value.forEach(v => {
        obj = {
          couponCaseName: v.couponCaseName,
          couponEmitQty: v.couponEmitQty,
          ...assemblyCouponObj
        }
      })
      this.precisionForm.largessCouponItem.push(obj)
    },
    //组装优惠券数据
    assemblyCoupon (value) {
      //发放方式
      const couponEmitModes = {
        0: '即时发放',
        1: '按天分期发放',
        2: '按周分期发放',
        3: '按月分期发放'
      }
      const {
        couponEmitMode,
        couponEveryEmitQty,
        couponEmitTimes,
        couponEmitInterval
      } = value
      let couponEmitModeName = couponEmitModes[couponEmitMode]
      if ([1, 2, 3].includes(couponEmitMode)) {
        const timeUnit =
          couponEmitMode === 1 ? '天' : couponEmitMode === 2 ? '周' : '月'
        couponEmitModeName += `,每次发放数量：${couponEveryEmitQty},分期次数：${couponEmitTimes},每次间隔：${couponEmitInterval}${timeUnit}`
      }
      //生效方式
      const couponEffectModes = {
        0: '即时生效， ',
        1: '延迟生效， ',
        2: '固定日期生效， '
      }
      const {
        couponEffectMode,
        couponEffectDays,
        couponEffectDate,
        couponValidDays
      } = value
      let couponEffectModeName = couponEffectModes[couponEffectMode]
      if (couponEffectMode === 0) {
        couponEffectModeName += `生效后有效时长${couponValidDays}天`
      } else if (couponEffectMode === 1) {
        couponEffectModeName += `领取${couponEffectDays}天后生效,生效后有效时长${couponValidDays}天`
      } else if (couponEffectMode === 2) {
        couponEffectModeName += `${couponEffectDate},生效后有效时长${couponValidDays}天`
      }
      value.couponEmitModeName = couponEmitModeName
      value.couponEffectModeName = couponEffectModeName
      return value
    },
    //打开门店
    getOpenShops (index) {
      this.selectIndex = index
      this.openShopInfo = true
    },
    //选中的门店
    shopInfoListCom (value) {
      if (value.length == 0) return
      value = value.map(v => {
        return { shopId: v.shopId }
      })
      this.precisionForm.detailItem[this.selectIndex].shopItem = value
    },
    //打开商品
    getOpenGoods (index) {
      this.selectIndex = index
      this.openGoods = true
    },
    //选中的商品
    getGoodsCom (value) {
      if (value.length == 0) return
      value = value.map(v => {
        return { lineId: v.goodsId, lineType: 1 }
      })
      this.precisionForm.detailItem[this.selectIndex].goodsItem = value
    },
    //打开商品分类
    getOpenCategorys (index) {
      this.selectIndex = index
      this.openCategory = true
    },
    //选中的商品分类
    categoryListCom (value) {
      if (value.length == 0) return
      value = value.map(v => {
        return { lineId: v.categoryId, lineType: 3 }
      })
      this.precisionForm.detailItem[this.selectIndex].goodsItem = value
    },
    //打开商品品牌
    getOpenBrands (index) {
      this.selectIndex = index
      this.openBrand = true
    },
    //选中的商品品牌
    brandListCom (value) {
      if (value.length == 0) return
      value = value.map(v => {
        return { lineId: v.brandId, lineType: 2 }
      })
      this.precisionForm.detailItem[this.selectIndex].goodsItem = value
    },
    //打开会员级别
    getOpenVipLevels (index) {
      this.selectIndex = index
      this.openVipLeve = true
    },
    //选中的会员级别
    vipLeveListCom (value) {
      if (value.length == 0) return
      value = value.map(v => {
        return { vipLevelId: v.vipLevelId }
      })
      this.precisionForm.detailItem[this.selectIndex].vipLevelItem = value
    },
    // 单据日期处理函数
    handleDatePicker (newVal, index) {
      const detailItem = this.precisionForm.detailItem[index]
      if (newVal) {
        detailItem.begTime = newVal[0]
        detailItem.endTime = newVal[1]
      } else {
        detailItem.begTime = undefined
        detailItem.endTime = undefined
      }
    },
    //新增按钮
    async handleAdd () {
      this.loading = true
      this.reset()
      await this.getOrderBillNo()
      this.loading = false
    },
    //退出
    getQuit () {
      this.$store.state.tagsView.visitedViews.splice(
        this.$store.state.tagsView.visitedViews.findIndex(
          item => item.path === this.$route.path
        ),
        1
      )
      this.$destroy();
      this.$router.push('/marketing/vipMarketing/precision')
      try {
        this.reset();
      } catch (error) {}
    },
    /** 提交按钮 */
    async submitForm (isBool) {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          const validations = [
            {
              condition:
                this.precisionForm.effectMode === 2 &&
                !this.precisionForm.effectBegTime,
              message: '指定时间不能为空'
            },
            {
              condition:
                !this.precisionForm.isLargessScore &&
                !this.precisionForm.isLargessCoupon,
              message: '请选择赠送礼包'
            },
            {
              condition:
                this.precisionForm.isLargessScore &&
                !this.precisionForm.largessScore,
              message: '赠送积分不能为空！'
            },
            {
              condition:
                this.precisionForm.isLargessCoupon &&
                this.precisionForm.largessCouponItem.length <= 0,
              message: '请选择赠送优惠券!'
            }
          ]
          for (const validation of validations) {
            if (validation.condition) {
              this.$message.warning(validation.message)
              return false
            }
          }
          if (this.precisionForm.billId) {
            const res = await precisionUpdateAPI(this.precisionForm)
            //重新赋值给表单，可以把id赋值
            if (isBool) {
              this.precisionForm = res.data
              if (this.precisionForm.largessCouponItem.length > 0) {
                this.precisionForm.largessCouponItem.forEach((item, index) => {
                  //组装数据
                  let assemblyCouponObj = this.assemblyCoupon(item)
                  this.$set(
                    this.precisionForm.largessCouponItem,
                    index,
                    assemblyCouponObj
                  )
                })
              }
              this.$modal.msgSuccess('修改单据成功')
            } else {
              this.$modal.msgSuccess('保存并新增单据成功')
            }
          } else {
            const { data } = await precisionAddAPI(this.precisionForm)
            //重新赋值给表单，可以把id赋值
            if (isBool) {
              this.precisionForm = data
              if (this.precisionForm.largessCouponItem.length > 0) {
                this.precisionForm.largessCouponItem.forEach((item, index) => {
                  //组装数据
                  let assemblyCouponObj = this.assemblyCoupon(item)
                  this.$set(
                    this.precisionForm.largessCouponItem,
                    index,
                    assemblyCouponObj
                  )
                })
              }
              this.$modal.msgSuccess('新增单据成功')
            } else {
              this.$modal.msgSuccess('保存并修改单据成功')
            }
          }
        }
      })
    },
    //保存并新增方案
    async addBill () {
      this.$confirm('是否保存并新增方案?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success'
      })
        .then(async () => {
          this.loading = true
          this.submitForm(false)
          this.reset()
          await this.getOrderBillNo()
          this.loading = false
        })
        .catch()
    },
    //审核/删除/终止事件
    async auditBill (name) {
      if (name === '审核') {
        if (this.precisionForm.billStatus != '0') {
          this.$message.error('此单据不是未审核的状态哦~')
          return
        }
        const obj = {
          billIds: [this.precisionForm.billId],
          billStatus: '2'
        }
        //发送审核api
        const res1 = await precisionUpdateStatusAPI(obj)
        this.precisionForm = res1.data
      }
      try {
        await this.$confirm(`此单据已审核，是否要启用?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        })
        const obj = {
          billIds: [this.precisionForm.billId],
          isStop: false
        }
        const res2 = await precisionIsStopAPI(obj)
        this.precisionForm = res2.data
        // 弹出提示
        this.$message.success('审核并启用成功')
      } catch {
        // 弹出提示
        this.$message.success('审核成功')
      }
      if (this.precisionForm.largessCouponItem.length > 0) {
        this.precisionForm.largessCouponItem.forEach((item, index) => {
          //组装数据
          let assemblyCouponObj = this.assemblyCoupon(item)
          this.$set(
            this.precisionForm.largessCouponItem,
            index,
            assemblyCouponObj
          )
        })
      }
    },
    //组装优惠券数据
    assemblyCoupon (value) {
      //发放方式
      const couponEmitModes = {
        0: '即时发放',
        1: '按天分期发放',
        2: '按周分期发放',
        3: '按月分期发放'
      }
      const {
        couponEmitMode,
        couponEveryEmitQty,
        couponEmitTimes,
        couponEmitInterval
      } = value
      let couponEmitModeName = couponEmitModes[couponEmitMode]
      if ([1, 2, 3].includes(couponEmitMode)) {
        const timeUnit =
          couponEmitMode === 1 ? '天' : couponEmitMode === 2 ? '周' : '月'
        couponEmitModeName += `,每次发放数量：${couponEveryEmitQty},分期次数：${couponEmitTimes},每次间隔：${couponEmitInterval}${timeUnit}`
      }
      //生效方式
      const couponEffectModes = {
        0: '即时生效， ',
        1: '延迟生效， ',
        2: '固定日期生效， '
      }
      const {
        couponEffectMode,
        couponEffectDays,
        couponEffectDate,
        couponValidDays
      } = value
      let couponEffectModeName = couponEffectModes[couponEffectMode]
      if (couponEffectMode === 0) {
        couponEffectModeName += `生效后有效时长${couponValidDays}天`
      } else if (couponEffectMode === 1) {
        couponEffectModeName += `领取${couponEffectDays}天后生效,生效后有效时长${couponValidDays}天`
      } else if (couponEffectMode === 2) {
        couponEffectModeName += `${couponEffectDate},生效后有效时长${couponValidDays}天`
      }
      value.couponEmitModeName = couponEmitModeName
      value.couponEffectModeName = couponEffectModeName
      return value
    },
    //方案编号
    async getOrderBillNo () {
      this.precisionForm.billNo = await getBillNo(160302)
    },
    // 上一步骤
    lastStep () {
      if (this.activeStep > 0) {
        this.activeStep = this.activeStep - 1
      }
    },
    // 下一步骤
    async nextStep () {
      const { precisionForm, activeStep } = this
      if (precisionForm.precisionTargetType === 1) {
        if (!precisionForm.isUseConsumeAction && !precisionForm.isUseGoodsLike && !precisionForm.isUseVipAccount && !precisionForm.isUseVipMark) {
          return this.$message.error('请选择必填项')
        }
        if (precisionForm.isUseConsumeAction) {
          let isState = false
          for (const [i, v] of this.precisionForm.detailItem.entries()) {
            if (i< 5) {
              if (v.isSelected) {
                if (v.timeRangeType == 5 && [0, 1, 2, 3].includes(i)) {
                  if (!v.begTime || !v.begValue || !v.endTime || !v.endValue) {
                    isState = true
                    break
                  }
                } else if ([1,2,3,4].includes(v.timeRangeType) && [0, 1, 2, 3].includes(i)) {
                  if (!v.begValue || !v.endValue) {
                    isState = true
                    break
                  }
                } else if (i == 4) {
                  if (v.shopItem.length == 0 || !v.begTime || !v.endTime) {
                    isState = true
                  }
                }
                if(i == 3 && (!v.begValue || !v.endValue)){
                  isState = true
                }
              }
            } else {
              break;
            }
          }
          let findIndex = this.precisionForm.detailItem.findIndex((v, i) => {
              if (i< 5) {
                return v.isSelected == true
              }
          })
          if (isState || findIndex == -1) {
            return this.$message.error('请输入完整数据')
          }
        } else if (precisionForm.isUseGoodsLike) {
          let isState = false
          for (const [i, v] of this.precisionForm.detailItem.entries()) {
            if (i> 4 && i< 11) {
              if (v.isSelected && v.goodsItem.length == 0) {
                isState = true
              }
              if (v.timeRangeType == 5 ) {
                  if (!v.begTime || !v.endTime) {
                    isState = true
                  }
              }
            }
          }
          let findIndex = this.precisionForm.detailItem.findIndex((v, i) => {
              if (i> 4 && i< 11) {
                return v.isSelected == true
              }
          })
          if (isState || findIndex == -1) {
            return this.$message.error('请输入完整数据')
          }
        } else if (precisionForm.isUseVipAccount) {
          let isState = false
          for (const [i, v] of this.precisionForm.detailItem.entries()) {
            if (i> 10 && i< 15) {
              if (v.isSelected) {
                if (v.timeRangeType == 5 && [11, 12].includes(i)) {
                  if (!v.begTime || !v.begValue || !v.endTime || !v.endValue) {
                    isState = true
                    break
                  }
                } else if ([11, 12, 13, 14, 15].includes(v.precisionType) && [11, 12, 13, 14].includes(i)) {
                  if (!v.begValue || !v.endValue) {
                    isState = true
                    break
                  }
                }
              }
            }
          }
          let findIndex = this.precisionForm.detailItem.findIndex((v, i) => {
              if (i> 10 && i< 15) {
                return v.isSelected == true
              }
          })
          if (isState || findIndex == -1) {
            return this.$message.error('请输入完整数据')
          }
        } else if (precisionForm.isUseVipMark) {
          let isState = false
          for (const [i, v] of this.precisionForm.detailItem.entries()) {
            if (i> 14) {
              if (v.isSelected) {
                if ([16].includes(v.precisionType) && v.shopItem.length == 0) {
                  isState = true
                  break
                } else if ([17].includes(v.precisionType)) {
                  if (v.ageRangeType == 4) {
                    if (!v.begValue || !v.endValue) {
                      isState = true
                      break
                    }
                  } else if (!v.ageRangeType) {
                    isState = true
                    break
                  }
                } else if ([18].includes(v.precisionType) && v.vipLevelItem.length == 0) {
                  isState = true
                  break
                } else if ([19, 20].includes(v.precisionType)) {
                  if (v.timeRangeType == 5) {
                    if (!v.begTime || !v.endTime) {
                      isState = true
                      break
                    }
                  } else if (!v.timeRangeType) {
                    isState = true
                    break
                  }
                } else if ([21].includes(v.precisionType)) {
                  if (  v.sexRangeType === undefined || v.sexRangeType === '') {
                    isState = true
                    break
                  }
                } 
              }
            }
          }
          let findIndex = this.precisionForm.detailItem.findIndex((v, i) => {
              if (i> 14) {
                return v.isSelected == true
              }
          })
          if (isState || findIndex == -1) {
            return this.$message.error('请输入完整数据')
          }
        }
      }
      switch (activeStep) {
        case 0:
          if (!precisionForm.billNo) {
            this.$message.warning(
              '方案编号不能为空，请到编码规则配置单据类型！'
            )
            return
          }
          if (!precisionForm.billName) {
            this.$message.warning('方案名称不能为空！')
            return
          }
          this.queryOptions = {
            pageNum: 1,
            pageSize: 10,
          }
          this.refreshPinigation = false;
          setTimeout(()=>{
            this.refreshPinigation = true;
          })
          this.getList(this.queryOptions.pageNum, this.queryOptions.pageSize, this.precisionForm)
          this.activeStep++
          break
        case 1:
          this.activeStep++
          break
        case 2:
          this.activeStep++
          break
        default:
          break
      }
    },
    reset () {
      //新增按钮
      this.showAdd = false
      //关闭已删除图标
      this.deleteOrder = false
      //关闭审核图标
      this.auditOrder = false
      //关闭未审核图标
      this.noAuditOrder = false
      //关闭关闭图标
      this.stopOrder = false
      //关闭已禁用图标
      this.disableOrder = false
      //保存禁用
      this.disabled = false
      this.activeStep = 0 //第一步
      // 表单参数
      this.precisionForm = {
        billStatus: '',
        effectMode: 1, //领取方式
        largessCouponItem: [], //优惠
        precisionTargetType: 2, //目标类型
        isUseConsumeAction: false, //按消费行为
        isUseGoodsLike: false, //按商品喜好
        isUseVipAccount: false, //按会员账号
        isUseVipMark: false, //按会员特征
        isLargessScore: false, //赠送积分
        isLargessCoupon: false, //赠送优惠券
        detailItem: [
          {
            precisionType: 1,
            isSelected: false,
            timeRangeType: 1
          },
          {
            precisionType: 2,
            isSelected: false,
            timeRangeType: 1
          },
          {
            precisionType: 3,
            isSelected: false,
            timeRangeType: 1
          },
          {
            precisionType: 4,
            isSelected: false
          },
          {
            precisionType: 5,
            shopItem: [],
            isSelected: false,
            timeRangeType: 1
          },
          {
            precisionType: 6,
            goodsItem: [],
            isSelected: false,
            timeRangeType: 1
          },
          {
            precisionType: 7,
            goodsItem: [],
            isSelected: false,
            timeRangeType: 1
          },
          {
            precisionType: 8,
            goodsItem: [],
            isSelected: false,
            timeRangeType: 1
          },
          {
            precisionType: 9,
            goodsItem: [],
            isSelected: false,
            timeRangeType: 1
          },
          {
            precisionType: 10,
            goodsItem: [],
            isSelected: false,
            timeRangeType: 1
          },
          {
            precisionType: 11,
            goodsItem: [],
            isSelected: false,
            timeRangeType: 1
          },
          {
            precisionType: 12,
            isSelected: false,
            timeRangeType: 1
          },
          {
            precisionType: 13,
            isSelected: false,
            timeRangeType: 1
          },
          {
            precisionType: 14,
            isSelected: false
          },
          {
            precisionType: 15,
            isSelected: false
          },
          {
            precisionType: 16,
            shopItem: [],
            isSelected: false
          },
          {
            precisionType: 17,
            isSelected: false
          },
          {
            precisionType: 18,
            vipLevelItem: [],
            isSelected: false
          },
          {
            precisionType: 19,
            isSelected: false,
            timeRangeType: 1
          },
          {
            precisionType: 20,
            isSelected: false,
            timeRangeType: 1
          },
          {
            precisionType: 21,
            isSelected: false,
            sexRangeType: undefined
          }
        ] //会员营销精准营销方案明细
      }
      for (let i = 0; i <= 19; i++) {
        const datePickerKey = 'datePicker' + i
        this[datePickerKey] = undefined
      }
      this.resetForm('form')
    },
    async getList (pageNum, pageSize, obj) {
      this.loadingVip = true
      let params = obj
      params.pageNum = pageNum
      params.pageSize = pageSize
      const res = await precisionGetVipsAPI(params)
      this.vipList = res.rows
      this.total = res.total
      this.loadingVip = false
    },
    deleteRow (index, rows) {
      rows.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
//外面盒子
.wrap {
  background-color: #eaeaea;
  padding: 0 10px;
  height: calc(100vh - 90px);
  overflow-y: scroll;
  //操作按钮
  .navBtnTop {
    text-align: right;
    padding: 0 0px 10px 0;
    height: 40px;
    line-height: 40px;
  }
  //主体
  .content {
    //进度条
    .theSteps {
      margin: 22px;
    }
    //修改类型
    .modifyType {
      display: flex;
      margin: 20px 0 20px 20px;
    }
    .tableContent {
      margin: 6px;
      min-height: 500px;
      .filterCriteria {
        display: flex;
        flex-direction: column;
        padding-left: 22px;
        .filterCriteriaTwo {
          margin-left: 80px;
          display: flex;
          flex-direction: column;
        }
      }
      ::v-deep .el-form-item__content {
        margin-left: 0 !important;
      }
      ::v-deep .el-form-item__error {
        position: static;
      }
    }
    .packageContents {
      display: flex;
      flex-direction: column;
      margin: 20px 0 20px 20px;
      min-height: 600px;
    }
  }
}
//阿里图标
::v-deep .iconfont {
  font-size: 12px;
}
//输入框原生属性
/**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
::v-deep input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
// /* 单选框选中后的字体颜色 */
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #303133 !important;
}
/* 复选框选中后的字体颜色 */
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #303133 !important;
}
/* 进行中状态：圈线 */
::v-deep .el-step__head.is-process {
  color: #338df7;
  border-color: #338df7;
}
/* 进行中状态：圈内 */
::v-deep .el-step__head.is-process > .el-step__icon {
  background: #338df7;
  color: #fff;
}
/* 进行中状态：title（文字） */
::v-deep .el-step__title.is-process {
  font-size: 12px;
  font-weight: normal;
}

/* 完成状态：圈线 */
::v-deep .el-step__head.is-success {
  color: #338df7;
  border-color: #338df7;
}
/* 完成状态：title（文字） */
::v-deep .el-step__title.is-success {
  color: #303133;
  font-size: 12px;
  font-weight: normal;
}
// 未完成:title(文字)
::v-deep .el-step__title.is-wait {
  color: #303133;
  font-size: 12px;
  font-weight: normal;
}
/* 完成状态：line
 * 描述：第一步完成，第二步进行时，之间的进度条有颜色
 */
::v-deep .el-step__head.is-success > .el-step__line > .el-step__line-inner {
  width: 100% !important;
  border-width: 1px !important;
}
::v-deep .el-steps .el-steps--horizontal {
  padding: 0 20px;
}
//进度条
.firstStep {
  ::v-deep .el-step__head {
    margin-left: 10px;
  }
}
.secondStep {
  ::v-deep .el-step__head {
    margin-left: 10px;
  }
}
::v-deep .el-step.secondStep.is-horizontal {
  margin-right: -20px !important;
}
.thirdStep {
  ::v-deep .el-step__head {
    padding-left: 8px;
  }
}
//搜索框内部属性
::v-deep .el-input--medium .el-input__inner {
  height: 28px;
  line-height: 28px;
}
//内部表单
::v-deep .el-form-item {
  padding: 0;
  margin: 0;
}
//单据日期
::v-deep .el-date-editor--datetimerange.el-input,
.el-date-editor--datetimerange.el-input__inner {
  width: 355px;
}
//底部间距 50
.marB50 {
  margin-bottom: 50px;
}
//左边间距 33
.marL33 {
  margin-left: 33px;
}
//左边间距  60
.marL60 {
  margin-left: 60px;
}
//左边间距  47
.marL47 {
  margin-left: 47px;
}
//金额输入框长度
.inputWidthPrecision {
  width: 100px;
}
</style>
