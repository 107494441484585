var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap",
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.joinGiftForm,
            rules: _vm.rules,
            "label-width": "96px",
          },
        },
        [
          _c("topOperatingButton", {
            attrs: {
              disabled: _vm.disabled,
              showAudit: false,
              isAuditBillBtn: false,
              isSubmitAddBtn: false,
              isExamineBtn: true,
              id: "topOperatingButton",
            },
            on: {
              submitForm: function ($event) {
                return _vm.submitForm(false)
              },
              addBill: function ($event) {
                return _vm.addBill(true)
              },
              auditBill: _vm.auditBill,
              handleAdd: _vm.handleAdd,
              getQuit: _vm.getQuit,
            },
          }),
          _c(
            "cardTitleCom",
            {
              attrs: {
                cardTitle: "基本信息",
                auditOrder: _vm.auditOrder,
                noAuditOrder: _vm.noAuditOrder,
                stopOrder: _vm.stopOrder,
                deleteOrder: _vm.deleteOrder,
                disableOrder: _vm.disableOrder,
              },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "tableContent x-x" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "方案编号", prop: "billNo" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth marR15",
                          attrs: {
                            disabled: true,
                            placeholder: "方案编号",
                            size: "mini",
                          },
                          model: {
                            value: _vm.joinGiftForm.billNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.joinGiftForm, "billNo", $$v)
                            },
                            expression: "joinGiftForm.billNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "方案名称", prop: "billName" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "方案名称",
                            size: "mini",
                          },
                          model: {
                            value: _vm.joinGiftForm.billName,
                            callback: function ($$v) {
                              _vm.$set(_vm.joinGiftForm, "billName", $$v)
                            },
                            expression: "joinGiftForm.billName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "有效时间", prop: "begTime" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            disabled: _vm.disabled,
                            size: "mini",
                            type: "datetimerange",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            "default-time": ["00:00:00", "23:59:59"],
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                          },
                          model: {
                            value: _vm.datetime,
                            callback: function ($$v) {
                              _vm.datetime = $$v
                            },
                            expression: "datetime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注", prop: "billRemark" } },
                      [
                        _c("el-input", {
                          staticClass: "inputRemark",
                          attrs: {
                            disabled: _vm.disabled,
                            type: "textarea",
                            placeholder: "备注长度介于 1 和 80 字符之间",
                            maxlength: "80",
                            size: "mini",
                            autosize: { minRows: 1, maxRows: 5 },
                          },
                          model: {
                            value: _vm.joinGiftForm.billRemark,
                            callback: function ($$v) {
                              _vm.$set(_vm.joinGiftForm, "billRemark", $$v)
                            },
                            expression: "joinGiftForm.billRemark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "适用范围" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "tableContent" },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "适用会员",
                              prop: "vipLevelRangeType",
                            },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: { disabled: _vm.disabled },
                                model: {
                                  value: _vm.joinGiftForm.vipLevelRangeType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.joinGiftForm,
                                      "vipLevelRangeType",
                                      $$v
                                    )
                                  },
                                  expression: "joinGiftForm.vipLevelRangeType",
                                },
                              },
                              _vm._l(
                                _vm.dict.type.fill_vip_level_range_type,
                                function (dict) {
                                  return _c(
                                    "el-radio",
                                    {
                                      key: dict.value,
                                      attrs: { label: parseInt(dict.value) },
                                    },
                                    [_vm._v(_vm._s(dict.label))]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.joinGiftForm.vipLevelRangeType == 1 ||
                                  _vm.joinGiftForm.vipLevelRangeType == 2,
                                expression:
                                  "\n                joinGiftForm.vipLevelRangeType == 1 ||\n                joinGiftForm.vipLevelRangeType == 2\n              ",
                              },
                            ],
                            staticClass: "marL20",
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "marB10",
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEvent(
                                      "openVipLevelDialog",
                                      { curTable: "vipLevelItem" }
                                    )
                                  },
                                },
                              },
                              [_vm._v("选择会员级别")]
                            ),
                            _c(
                              "el-table",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "tooltip-effect": "dark",
                                  data: _vm.joinGiftForm.vipLevelItem,
                                  border: "",
                                  "max-height": "500",
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "序号",
                                    align: "center",
                                    prop: "userId",
                                    width: "80",
                                    type: "index",
                                  },
                                }),
                                !_vm.disabled
                                  ? _c("el-table-column", {
                                      attrs: {
                                        align: "center",
                                        label: "操作",
                                        width: "120",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "text",
                                                      size: "mini",
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.deleteRow(
                                                          scope.$index,
                                                          _vm.joinGiftForm
                                                            .vipLevelItem
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-remove operateDel",
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1451102359
                                      ),
                                    })
                                  : _vm._e(),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "会员级别编号",
                                    width: "200",
                                    align: "center",
                                    prop: "vipLevelNo",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "会员级别名称",
                                    align: "center",
                                    width: "200",
                                    prop: "vipLevelName",
                                  },
                                }),
                                _c("el-table-column"),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "适用门店", prop: "shopRangeType" },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: { disabled: _vm.disabled },
                                model: {
                                  value: _vm.joinGiftForm.shopRangeType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.joinGiftForm,
                                      "shopRangeType",
                                      $$v
                                    )
                                  },
                                  expression: "joinGiftForm.shopRangeType",
                                },
                              },
                              _vm._l(
                                _vm.dict.type.fill_shop_range_type,
                                function (dict) {
                                  return _c(
                                    "el-radio",
                                    {
                                      key: dict.value,
                                      attrs: { label: parseInt(dict.value) },
                                    },
                                    [_vm._v(_vm._s(dict.label))]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.joinGiftForm.shopRangeType == 1 ||
                                  _vm.joinGiftForm.shopRangeType == 2,
                                expression:
                                  "\n                joinGiftForm.shopRangeType == 1 ||\n                joinGiftForm.shopRangeType == 2\n              ",
                              },
                            ],
                            staticClass: "marL20",
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "marB10",
                                attrs: { disabled: _vm.disabled, size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEvent("openShopDialog", {
                                      curTable: "shopItem",
                                    })
                                  },
                                },
                              },
                              [_vm._v("选择门店")]
                            ),
                            _c(
                              "el-table",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "tooltip-effect": "dark",
                                  data: _vm.joinGiftForm.shopItem,
                                  border: "",
                                  "max-height": "500",
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "序号",
                                    align: "center",
                                    prop: "userId",
                                    width: "80",
                                    type: "index",
                                  },
                                }),
                                !_vm.disabled
                                  ? _c("el-table-column", {
                                      attrs: {
                                        align: "center",
                                        label: "操作",
                                        width: "120",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "text",
                                                      size: "mini",
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.deleteRow(
                                                          scope.$index,
                                                          _vm.joinGiftForm
                                                            .shopItem
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-remove operateDel",
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1942590442
                                      ),
                                    })
                                  : _vm._e(),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "门店编号",
                                    width: "200",
                                    align: "center",
                                    prop: "shopNo",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "门店名称",
                                    align: "center",
                                    width: "200",
                                    prop: "shopName",
                                  },
                                }),
                                _c("el-table-column"),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "适用渠道" } },
                      [
                        _c(
                          "el-checkbox",
                          {
                            attrs: { disabled: _vm.disabled },
                            model: {
                              value: _vm.joinGiftForm.isAllowOfflineShop,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.joinGiftForm,
                                  "isAllowOfflineShop",
                                  $$v
                                )
                              },
                              expression: "joinGiftForm.isAllowOfflineShop",
                            },
                          },
                          [_vm._v("线下门店")]
                        ),
                        _c(
                          "el-checkbox",
                          {
                            attrs: { disabled: _vm.disabled },
                            model: {
                              value: _vm.joinGiftForm.isAllowOnlineShop,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.joinGiftForm,
                                  "isAllowOnlineShop",
                                  $$v
                                )
                              },
                              expression: "joinGiftForm.isAllowOnlineShop",
                            },
                          },
                          [_vm._v("线上商城")]
                        ),
                        _c(
                          "el-checkbox",
                          {
                            attrs: { disabled: _vm.disabled },
                            model: {
                              value: _vm.joinGiftForm.isAllowOfflineHQ,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.joinGiftForm,
                                  "isAllowOfflineHQ",
                                  $$v
                                )
                              },
                              expression: "joinGiftForm.isAllowOfflineHQ",
                            },
                          },
                          [_vm._v("总部")]
                        ),
                        _c(
                          "el-checkbox",
                          {
                            attrs: { disabled: _vm.disabled },
                            model: {
                              value: _vm.joinGiftForm.isAllowOnlineWxCard,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.joinGiftForm,
                                  "isAllowOnlineWxCard",
                                  $$v
                                )
                              },
                              expression: "joinGiftForm.isAllowOnlineWxCard",
                            },
                          },
                          [_vm._v("微信会员卡")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "奖励设置" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticClass: "tableContent padL20" }, [
                  _c("div", [
                    _c("span", { staticClass: "fontS14" }, [
                      _vm._v("新会员奖励 "),
                    ]),
                    _c("span", { staticClass: "prompt" }, [
                      _vm._v("（新用户注册成为会员，将获得以下奖励）"),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "marT20 rewardPoints" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { disabled: _vm.disabled },
                          model: {
                            value: _vm.joinGiftForm.isNewJoinGiftScore,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.joinGiftForm,
                                "isNewJoinGiftScore",
                                $$v
                              )
                            },
                            expression: "joinGiftForm.isNewJoinGiftScore",
                          },
                        },
                        [_vm._v("赠送积分")]
                      ),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.joinGiftForm.isNewJoinGiftScore,
                            expression: "joinGiftForm.isNewJoinGiftScore",
                          },
                        ],
                        staticClass: "inputWidth marL15 marR15",
                        attrs: { disabled: _vm.disabled, size: "mini" },
                        model: {
                          value: _vm.joinGiftForm.newJoinGiftScore,
                          callback: function ($$v) {
                            _vm.$set(_vm.joinGiftForm, "newJoinGiftScore", $$v)
                          },
                          expression: "joinGiftForm.newJoinGiftScore",
                        },
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.joinGiftForm.isNewJoinGiftScore,
                              expression: "joinGiftForm.isNewJoinGiftScore",
                            },
                          ],
                        },
                        [_vm._v("分")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "marT20" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { disabled: _vm.disabled },
                          model: {
                            value: _vm.joinGiftForm.isNewJoinGiftCoupon,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.joinGiftForm,
                                "isNewJoinGiftCoupon",
                                $$v
                              )
                            },
                            expression: "joinGiftForm.isNewJoinGiftCoupon",
                          },
                        },
                        [_vm._v("赠送优惠券")]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.joinGiftForm.isNewJoinGiftCoupon,
                              expression: "joinGiftForm.isNewJoinGiftCoupon",
                            },
                          ],
                          staticClass: "marL20",
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "marB10 marT10",
                              attrs: { disabled: _vm.disabled, size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.giveCoupons("newVip")
                                },
                              },
                            },
                            [_vm._v("赠送优惠券")]
                          ),
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                "tooltip-effect": "dark",
                                data: _vm.joinGiftForm.newCouponItem,
                                border: "",
                                "max-height": "500",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "序号",
                                  align: "center",
                                  prop: "userId",
                                  width: "80",
                                  type: "index",
                                },
                              }),
                              !_vm.disabled
                                ? _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "操作",
                                      width: "120",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "text",
                                                    size: "mini",
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.deleteRow(
                                                        scope.$index,
                                                        _vm.joinGiftForm
                                                          .newCouponItem
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-remove operateDel",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1368322586
                                    ),
                                  })
                                : _vm._e(),
                              _c("el-table-column", {
                                attrs: {
                                  label: "优惠券名称",
                                  width: "200",
                                  align: "center",
                                  prop: "couponCaseName",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "赠送数量(单个会员)",
                                  align: "center",
                                  width: "200",
                                  prop: "couponEmitQty",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "赠送方式",
                                  align: "center",
                                  prop: "couponEmitModeName",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "生效方式",
                                  align: "center",
                                  prop: "couponEffectModeName",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _c("el-table-column"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "marT20" }, [
                    _c("span", { staticClass: "fontS14" }, [
                      _vm._v("推荐新人奖励 "),
                    ]),
                    _c("span", { staticClass: "prompt" }, [
                      _vm._v("（新用户注册成为会员，将获得以下奖励）"),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "marT20 rewardPoints" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { disabled: _vm.disabled },
                          model: {
                            value: _vm.joinGiftForm.isRecommendJoinGiftScore,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.joinGiftForm,
                                "isRecommendJoinGiftScore",
                                $$v
                              )
                            },
                            expression: "joinGiftForm.isRecommendJoinGiftScore",
                          },
                        },
                        [_vm._v("赠送积分")]
                      ),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.joinGiftForm.isRecommendJoinGiftScore,
                            expression: "joinGiftForm.isRecommendJoinGiftScore",
                          },
                        ],
                        staticClass: "inputWidth marL15 marR15",
                        attrs: { disabled: _vm.disabled, size: "mini" },
                        model: {
                          value: _vm.joinGiftForm.recommendJoinGiftScore,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.joinGiftForm,
                              "recommendJoinGiftScore",
                              $$v
                            )
                          },
                          expression: "joinGiftForm.recommendJoinGiftScore",
                        },
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.joinGiftForm.isRecommendJoinGiftScore,
                              expression:
                                "joinGiftForm.isRecommendJoinGiftScore",
                            },
                          ],
                        },
                        [_vm._v("分")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "marT20" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { disabled: _vm.disabled },
                          model: {
                            value: _vm.joinGiftForm.isRecommendJoinGiftCoupon,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.joinGiftForm,
                                "isRecommendJoinGiftCoupon",
                                $$v
                              )
                            },
                            expression:
                              "joinGiftForm.isRecommendJoinGiftCoupon",
                          },
                        },
                        [_vm._v("赠送优惠券")]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.joinGiftForm.isRecommendJoinGiftCoupon,
                              expression:
                                "joinGiftForm.isRecommendJoinGiftCoupon",
                            },
                          ],
                          staticClass: "marL20",
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "marB10 marT10",
                              attrs: { disabled: _vm.disabled, size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.giveCoupons("recommendVip")
                                },
                              },
                            },
                            [_vm._v("赠送优惠券")]
                          ),
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                "tooltip-effect": "dark",
                                data: _vm.joinGiftForm.recommendCouponItem,
                                border: "",
                                "max-height": "500",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "序号",
                                  align: "center",
                                  prop: "userId",
                                  width: "80",
                                  type: "index",
                                },
                              }),
                              !_vm.disabled
                                ? _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "操作",
                                      width: "120",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "text",
                                                    size: "mini",
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.deleteRow(
                                                        scope.$index,
                                                        _vm.joinGiftForm
                                                          .recommendCouponItem
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-remove operateDel",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      198330904
                                    ),
                                  })
                                : _vm._e(),
                              _c("el-table-column", {
                                attrs: {
                                  label: "优惠券名称",
                                  width: "200",
                                  align: "center",
                                  prop: "couponCaseName",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "赠送数量(单个会员)",
                                  align: "center",
                                  width: "200",
                                  prop: "couponEveryEmitQty",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.couponDrawModesData[
                                                scope.row.couponEmitMode
                                              ]
                                            ) +
                                            " " +
                                            _vm._s(
                                              scope.row.couponEveryEmitQty
                                            ) +
                                            "张 "
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "赠送方式",
                                  align: "center",
                                  prop: "couponEmitModeName",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "生效方式",
                                  align: "center",
                                  prop: "couponEffectModeName",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _c("el-table-column"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("selectCoupon", {
        attrs: {
          OpenCoupon: _vm.openCoupon,
          isRadio: true,
          isSuingEditorIsRadio: true,
        },
        on: {
          "update:OpenCoupon": function ($event) {
            _vm.openCoupon = $event
          },
          "update:open-coupon": function ($event) {
            _vm.openCoupon = $event
          },
          getCoupons: function ($event) {
            return _vm.getPitchData($event, "coupon")
          },
        },
      }),
      _c("vipLeve", {
        attrs: { OpenVipLeve: _vm.openVipLeve },
        on: {
          "update:OpenVipLeve": function ($event) {
            _vm.openVipLeve = $event
          },
          "update:open-vip-leve": function ($event) {
            _vm.openVipLeve = $event
          },
          vipLeveList: _vm.vipLeveListCom,
        },
      }),
      _c("shopInfo", {
        attrs: { OpenShopInfo: _vm.openShopInfo },
        on: {
          "update:OpenShopInfo": function ($event) {
            _vm.openShopInfo = $event
          },
          "update:open-shop-info": function ($event) {
            _vm.openShopInfo = $event
          },
          shopInfoList: _vm.shopInfoListCom,
        },
      }),
      _c("Dialog", {
        ref: "tablePage",
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }