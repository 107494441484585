<template>
  <div class="wrap" v-loading="loading" id="wrap">
    <cardTitleCom cardTitle="搜索" id="search-card">
      <template slot="cardContent">
        <div class="navSearch x-f">
          <span class="fS14MR10">发送时间</span>
          <el-date-picker
            class="marR10"
            v-model="datetime"
            type="datetimerange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
            value-format="yyyy-MM-dd HH:mm:ss"
          />
          <searchBox
            :resetInputText="resetInputText"
            :dropDownList="dropDownList"
            placeholderText="输入对象编号、名称、手机号"
            @parameter="parameter"
            @searchTextInput="searchTextInput"
            @searchInputEnter="searchInputEnter"
            class="marR10"
          />
          <el-button
            class="marR10"
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="getList"
            >搜索</el-button
          >
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="短信历史明细" class="content">
      <template slot="cardContent">
        <div class="tableContent">
          <el-table
            v-loading="loadingTable"
            ref="multipleTable"
            tooltip-effect="dark"
            :data="tableData"
            border
            :max-height="tableHeight"
            :height="tableHeight"
          >
            <el-table-column
              label="序号"
              align="center"
              prop="userId"
              width="80"
              type="index"
            />
            <el-table-column
              label="发送时间"
              align="center"
              prop="createTime"
              width="155"
            />
            <el-table-column
              show-overflow-tooltip
              label="发送内容"
              align="center"
              prop="sendContent"
              width="300"
            />
            <el-table-column
              label="短信类型"
              align="center"
              prop="remindTypeName"
            />
            <el-table-column
              label="对象类型"
              align="center"
              prop="clientTypeName"
            />
            <el-table-column label="对象编号" align="center" prop="clientNo" />
            <el-table-column
              label="对象名称"
              align="center"
              prop="clientName"
            />
            <el-table-column label="手机号" align="center" prop="clientTel" />
            <el-table-column
              label="发送条数"
              align="center"
              prop="sendChargeCount"
            />
             <el-table-column
              label="剩余条数"
              align="center"
              prop="usableFillCount"
            />
          </el-table>
          <bottomPage
            v-model="queryParams"
            :getList="getList"
            :isSelectAll="false"
            :totalCom="total"
          />
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import bottomPage from "@/views/components/bottomPage"; //底部分页
import { listWithDetailAPI } from "@/api/marketing/sms/history"; //短信历史
export default {
  name: "historyDetail",
  components: {
    bottomPage,
    cardTitleCom,
  },
  data() {
    return {
      // 总条数
      total: 0,
      //表格数据
      tableData: [],
      resetInputText: false, //清空高搜索输入框
      dropDownList: [
        { parameter: "query", name: "全部" },
        { parameter: "clientNo", name: "对象编号" },
        { parameter: "clientName", name: "对象名称" },
        { parameter: "clientTel", name: "对象手机号" },
      ], //查询
      datetime: undefined, //发送日期
      loading: false, //加载框
      loadingTable: false, //表格遮罩
      queryParams: { pageNum: 1, pageSize: 15 }, //查询
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40,
    };
  },
  watch: {
    //单据日期
    datetime(newVal) {
      if (newVal) {
        this.queryParams.dateBegin = newVal[0];
        this.queryParams.dateEnd = newVal[1];
      } else {
        this.queryParams.dateBegin = undefined;
        this.queryParams.dateEnd = undefined;
      }
    },
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.paginationH - this.searchH - 90;
    },
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.loading = true;
      //清空查询
      vm.queryParams = { pageNum: 1, pageSize: 15 };
      //清空输入框
      vm.resetInputText = true;
      // 访问组件实例 vm 中的数据和方法
      if (vm.$route.query.type === "Update") {
        if (vm.$route.query.paramsObj.remindTypeId) {
          vm.queryParams = vm.$route.query.paramsObj;
          //获取单据详情
          await vm.getList();
        }
      }
      vm.loading = false;
    });
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById("wrap")?.clientHeight;
      this.searchH = document.getElementById("search-card")?.clientHeight;
    });
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.vivwH = document.getElementById("wrap")?.clientHeight;
      this.searchH = document.getElementById("search-card")?.clientHeight;
    },
    //输入框的回车事件
    async searchInputEnter(value) {
      this.searchTextInput(value);
      await this.getList();
    },
    //搜索框输入值默认搜索全部
    searchTextInput(value) {
      let valueObj = { query: value };
      this.dropDownList.forEach((item) => {
        const keys = Object.keys(valueObj);
        if (item.parameter != keys[0]) {
          this.queryParams[item.parameter] = undefined;
        } else {
          this.queryParams = {
            ...this.queryParams,
            ...valueObj,
          };
        }
      });
    },
    //搜索框参数  点击事件
    async parameter(value) {
      this.dropDownList.forEach((item) => {
        const keys = Object.keys(value);
        if (item.parameter != keys[0]) {
          this.queryParams[item.parameter] = undefined;
        } else {
          this.queryParams = {
            ...this.queryParams,
            ...value,
          };
        }
      });
      await this.getList();
    },
    /** 查询表格列表 */
    async getList() {
      this.loadingTable = true;
      const { rows, total } = await listWithDetailAPI(this.queryParams);
      this.tableData = rows;
      this.total = total;
      console.log("data", rows);
      this.loadingTable = false;
    },
  },
};
</script>
<style lang="scss" scoped>
//外面盒子
.wrap {
  padding: 6px 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
    .marL30 {
      margin-left: 30px;
    }
  }
  .content {
    height: calc(100% - 94px);
    //源单信息表格
    .tableContent {
      margin: 6px 10px 10px 10px;
    }
  }
}
//单据日期
::v-deep .el-date-editor--datetimerange.el-input,
.el-date-editor--datetimerange.el-input__inner {
  width: 355px;
  height: 28px;
}
//时钟
::v-deep .el-range-editor--medium .el-range__icon,
.el-range-editor--medium .el-range__close-icon {
  line-height: 23px;
}
//时间横线
::v-deep .el-range-separator {
  line-height: 19px;
}
</style>
