<template>
  <!-- 会员升级 -->
  <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
</template>

<script>
import TablePagination from "@/components/tablePage/tablePagination";
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import seniorSearch from '@/views/components/seniorSearch' //高级搜索

import TablePage from '@/components/tablePage/index.vue'
import { precisionListAPI, precisionIsStopAPI, precisionUpdateStatusAPI,serviceCopyAPI } from '@/api/marketing/vipMarketing/precision'

export default {
  name: 'RetailTotal',
  components: {
    TablePage,
    cardTitleCom,
    seniorSearch,
    TablePagination
  },
  dicts: ['bill_status'],
  data () {
    return {
      options: {
        listNo: true,
        mutiSelect: true,
        loading: true,
        check: [],
        pagination: {
          total: 0,
          pageNum: 1,
          pageSize: 15,
          query: undefined, // 输入方案编号名称
          isStop: undefined, // 启用状态
        },
        buttons: [
          {
            click: 'add',
            label: '新增',
            type: 'primary',
            icon: 'el-icon-plus'
          },
          {
            click: "audit",
            label: "审核",
            type: "success",
            icon:'el-icon-document',
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "enable",
            label: "启用",
            type: "warning",
            btnType: "dropdown",
            other: [
              {
                click: "redoEnable",
                label: "禁用",
              },
            ],
            disabled: () => !this.options?.check?.length,
          },
          {
            click: 'copy',
            label: "复制",
            icon: "el-icon-document-copy",
            type: "success",
            disabled: () => !this.options?.check?.length
          },
          {
            click: 'del',
            label: '删除',
            type: 'danger',
            icon: 'el-icon-delete',
            disabled: () => !this.options?.check?.length
          },
          {
            click: 'getList',
            label: '刷新',
            right: 'true'
          }
        ],
        getListApi: precisionListAPI,
        title: '源单信息',
        rowKey: 'couponCaseId',
        type: 'couponCaseId',
        search: [
          {
            type: "filters",
            tip: "全部/方案编号/方案名称",
            isRestore:true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "billNos", label: "方案编号" },
              { filter: "billNames", label: "方案名称" },
            ],
          },
          this.$select({ key: "isStopState", option: { seniorSearch: true, option: { multiple: true } }}),
          this.$select({ key: "bill_status", option: { label: '方案状态', seniorSearch: true, option: { multiple: true } }}),
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          { type: 'button', tip: '高级查询', btnType: 'primary', click: 'seniorSearch' },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        columns: [
          { prop: 'billNo', type: 'link', click: 'link', label: '方案编号', width: '160' },
          { prop: 'billName', label: '方案名称', width: '160' },
          { prop: 'effectiveTime',
          activeText(row) {
            return +row.effectMode === 1 ? '立即生效' : row.effectBegTime
          }, label: '生效时间', width: '160' },
          { prop: 'billStatusName', label: '方案状态'},
          { prop: 'isStopName', label: '启用状态'},
          { prop: 'billRemark', label: '备注', width: '200' },
          { prop: 'updateBy', label: '修改人', width: '140' },
          { prop: 'updateTime', label: '修改时间', width: '200' },
          { prop: 'createBy', label: '创建人', width: '140'},
          { prop: 'createTime', label: '创建时间', width: '200'},
        ],
        list: [],
      },

      levelList: [],
    }
  },
  computed: {
    bill_status() {
      console.log(this.dict.type.bill_status)
      return this.dict.type.bill_status
    }
  },
  methods: {
    async handleEvent(type, row) {
      const selectData = this.options.check;
      switch (type) {
        case 'link':
          const billId = row.billId
          await this.$router.push({
            name: 'precisionDetail',
            query: {
              billId: billId,
              type: 'Update'
            }
          })
          break;
        case 'add':
          await this.$router.push({
            name: 'precisionDetail'
          })
          break;
        case "audit":
          {
            if (!selectData.length) return;
            this.handleBillStatus("审核", selectData);
          }
          break;
        case "redoAudit":
          {
            if (!selectData.length) return;
            this.handleBillStatus("反审核", selectData);
          }
          break;
        case "enable":
          {
            if (!selectData.length) return;
            this.handleBillStatus("启用", selectData);
          }
          break;
        case "redoEnable":
          {
            if (!selectData.length) return;
            this.handleBillStatus("禁用", selectData);
          }
          break;
        case "del":
          {
            if (!selectData.length) return;
            this.handleBillStatus("删除", selectData);
          }
          break;
        case "copy":
          {
            if (!selectData.length) return;
            this.handleBillStatus("复制", selectData);
          }
          break;
        default:
      }
    },
    handleBillStatus(command, selectData) {
      let name = "";
      let billStatus = "";
      let isStop = "";
      if (command === "反审核") {
        name = "反审核";
        billStatus = "0";
      } else {
        if (command === "审核") {
          name = "审核";
          billStatus = "2";
        } else if (command === "删除") {
          name = "删除";
          billStatus = "3";
        } else if (command === "启用") {
          name = "启用";
          isStop = false;
        } else if (command === "禁用") {
          name = "禁用";
          isStop = true;
        } else if (command === "复制") {
          name = "复制";
          billStatus = "4";
        } 
      }
      const billIds = selectData.map((i) => i.billId);
      const billNo = selectData.map((i) => i.billNo);
      const $this = this;
      this.$modal
        .confirm(`是否确认${name}方案编号为"` + billNo + '"的单据项?')
        .then(function () {
          if (['启用', '禁用'].includes(name)) {
            precisionIsStopAPI({billIds: [...billIds],isStop: isStop} ).then(res => {
              $this.$nextTick(() => {
                $this.options.check = [];
              });
              setTimeout(() => {
                $this.$refs.tablePage.getList();
              }, 500);
              if (!isStop) {
                $this.$modal.msgSuccess('方案启用成功')
              } else {
                $this.$modal.msgSuccess('方案禁用成功')
              }
            })
          } else if (['审核', '反审核', '删除'].includes(name)) {
            const index = selectData.findIndex(y => !y.isStop)
            if (['反审核'].includes(name) && index != '-1') return $this.$modal.msgError('已启用不能反审核')
            precisionUpdateStatusAPI({billIds: [...billIds], billStatus: billStatus} ).then(res => {
              $this.$nextTick(() => {
                $this.options.check = [];
              });
              setTimeout(() => {
                $this.$refs.tablePage.getList();
              }, 500);
              $this.$modal.msgSuccess(`${name}成功`);
            })
          } else {
            serviceCopyAPI({billIds: [...billIds], billStatus: billStatus}).then(res => {
              $this.$nextTick(() => {
                $this.options.check = [];
              });
              setTimeout(() => {
                $this.$refs.tablePage.getList();
              }, 500);
              $this.$modal.msgSuccess(`${name}成功`);
            })
          }
        });
    },
  }
}
</script>
