<template>
  <div>
    <div>
      <div class="tableBtn" v-if="isOperation">
        <div>
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="mini"
            @click="getAddTemplate"
            >新增</el-button
          >
          <el-button
            type="danger"
            icon="el-icon-delete"
            @click="handleRemove"
            :disabled="multiple"
            size="mini"
            >删除
          </el-button>
        </div>
        <!-- 右边盒子 -->
        <div>
          <el-button
            size="mini"
            class="marR10"
            icon="el-icon-refresh"
            @click="test($event)"
            >刷新
          </el-button>
        </div>
      </div>
      <el-tabs
        v-model="queryParams.templateType"
        v-loading="loadingTable"
        @tab-click="getList"
      >
        <el-tab-pane label="节日祝福" name="1">
          <el-table
            tooltip-effect="dark"
            :data="tableData"
            border
            max-height="600"
            height="600"
            ref="multipleTable"
          >
            <el-table-column width="55" align="center">
              <template v-slot="scope">
                <el-radio v-model="templateItem" :label="scope.row">{{
                  ''
                }}</el-radio>
              </template>
            </el-table-column>
            <el-table-column
              label="序号"
              align="center"
              prop="userId"
              width="80"
              type="index"
            />
            <el-table-column
              label="模版名称"
              prop="templateName"
              align="center"
              min-width="120"
            />
            <el-table-column
              label="模版签名"
              prop="signatureContent"
              align="center"
              min-width="120"
            />
            <el-table-column
              label="模版类型"
              prop="templateType"
              align="center"
              min-width="60"
            >
              <template slot-scope="scope">{{
                scope.row.templateType == 1
                  ? '节日祝福'
                  : scope.row.templateType == 2
                  ? '会员营销'
                  : '其他'
              }}</template>
            </el-table-column>
            <el-table-column
              label="模版内容"
              prop="templateContent"
              align="center"
              min-width="160"
            /> </el-table
        ></el-tab-pane>
        <el-tab-pane label="会员营销" name="2">
          <el-table
            tooltip-effect="dark"
            :data="tableData"
            border
            max-height="600"
            height="600"
            ref="multipleTable"
          >
            <el-table-column width="55" align="center">
              <template v-slot="scope">
                <el-radio v-model="templateItem" :label="scope.row">{{
                  ''
                }}</el-radio>
              </template>
            </el-table-column>
            <el-table-column
              label="序号"
              align="center"
              prop="userId"
              width="80"
              type="index"
            />
            <el-table-column
              label="模版名称"
              prop="templateName"
              align="center"
              min-width="120"
            />
            <el-table-column
              label="模版签名"
              prop="signatureContent"
              align="center"
              min-width="120"
            />
            <el-table-column
              label="模版类型"
              prop="templateType"
              align="center"
              min-width="60"
            >
              <template slot-scope="scope">{{
                scope.row.templateType == 1
                  ? '节日祝福'
                  : scope.row.templateType == 2
                  ? '会员营销'
                  : '其他'
              }}</template>
            </el-table-column>
            <el-table-column
              label="模版内容"
              prop="templateContent"
              align="center"
              min-width="160"
            /> </el-table
        ></el-tab-pane>
        <el-tab-pane label="其他" name="3">
          <el-table
            tooltip-effect="dark"
            :data="tableData"
            border
            max-height="600"
            height="600"
            ref="multipleTable"
            @row-click="handleRowClick"
          >
            <el-table-column width="55" align="center">
              <template v-slot="scope">
                <el-radio v-model="templateItem" :label="scope.row">{{
                  ''
                }}</el-radio>
              </template>
            </el-table-column>
            <el-table-column
              label="序号"
              align="center"
              prop="userId"
              width="80"
              type="index"
            />
            <el-table-column
              label="模版名称"
              prop="templateName"
              align="center"
              min-width="100"
            />
            <el-table-column
              label="模版签名"
              prop="signatureContent"
              align="center"
              min-width="100"
            />
            <el-table-column
              label="模版类型"
              prop="templateType"
              align="center"
              min-width="60"
            >
              <template slot-scope="scope">{{
                scope.row.templateType == 1
                  ? '节日祝福'
                  : scope.row.templateType == 2
                  ? '会员营销'
                  : '其他'
              }}</template>
            </el-table-column>
            <el-table-column
              label="模版内容"
              prop="templateContent"
              align="center"
              min-width="160"
            /> </el-table
        ></el-tab-pane>
      </el-tabs>
      <bottomPage
        :isSelectAll="false"
        v-model="queryParams"
        :getList="getList"
        :totalCom="total"
      />
    </div>
    <!-- 新增 -->

    <c-dialog title="新增模板" :width="500" :showDialog.sync="dialogVisibleTem">
      <template #content>
        <el-form
          :model="templateForm"
          :rules="rules"
          ref="form"
          label-width="100px"
        >
          <el-form-item prop="templateName" label="模版名称">
            <el-input
              maxlength="100"
              placeholder="请输入模版名称"
              v-model="templateForm.templateName"
            />
          </el-form-item>
          <el-form-item prop="signatureId" label="模版签名">
            <SelectRemote
              v-model="templateForm.signatureId"
              :option="
                $select({
                  key: 'listSignature'
                }).option
              "
            />
          </el-form-item>
          <el-form-item prop="templateType" label="模版类型">
            <el-select
              v-model="templateForm.templateType"
              placeholder="请选择模版类型"
              size="mini"
            >
              <el-option label="节日祝福" :value="1"></el-option>
              <el-option label="会员营销" :value="2"></el-option>
              <el-option label="其他" :value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="templateContent" label="模版内容">
            <el-input
              class="marB10"
              type="textarea"
              :rows="10"
              maxlength="300"
              show-word-limit
              placeholder="请输入模版内容"
              v-model="templateForm.templateContent"
            />
          </el-form-item>
        </el-form>
      </template>
      <template #buttonList>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" @click="getConfirm">确 定</el-button>
        </span>
      </template>
    </c-dialog>
  </div>
</template>

<script>
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
import {
  templateListAPI,
  templateAddAPI,
  templateDeleteAPI
} from '@/api/marketing/sms/template' //短信模板
import bottomPage from '@/views/components/bottomPage' //底部分页
export default {
  name: 'send',
  components: {
    CDialog,
    SelectRemote,
    bottomPage
  },
  props: {
    //操作按钮显示
    isOperation: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      //模板表单
      templateForm: { templateType: 3 },
      //新增模板
      dialogVisibleTem: false,
      //选择对象遮罩
      loadingTable: false,
      tableData: [],
      templateItem: {},
      multiple: false,
      //查询
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        templateType: '1'
      },
      rules: {
        templateName: [
          {
            required: true,
            message: '模版名称不能为空',
            trigger: ['blur', 'change']
          }
        ],
        signatureId: [
          {
            required: true,
            message: '模版签名不能为空',
            trigger: ['blur', 'change']
          }
        ],
        templateType: [
          {
            required: true,
            message: '模版类型不能为空',
            trigger: ['blur', 'change']
          }
        ],
        templateContent: [
          {
            required: true,
            message: '模板内容不能为空',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  watch: {
    templateItem () {
      if (this.templateItem) {
        this.$emit('templateItemCom', this.templateItem)
      }
    }
  },
  async created () {
    await this.getList()
  },
  methods: {
    //删除请求
    handleRemove () {
      this.$modal
        .confirm('是否确认删除所选的数据项？')
        .then(() => {
          templateDeleteAPI([this.templateItem.templateId]) //删除请求
        })
        .then(() => {
          this.getList() //渲染列表
          this.$modal.msgSuccess('删除成功')
        })
        .catch(error => {})
    },
    //自定义弹窗取消按钮
    cancel () {
      this.templateReset()
      this.dialogVisibleTem = false
    },
    //自定义弹窗确认按钮
    async getConfirm () {
      await templateAddAPI(this.templateForm)
      this.$message({
        message: '新增成功',
        type: 'success'
      })
      this.dialogVisibleTem = false
      await this.getList()
    },
    //重置
    templateReset () {
      // 表单重置
      this.templateForm = {
        templateType: 3
      }
      this.resetForm('form')
    },
    //刷新按钮
    test (e) {
      let target = e.target
      if (target.nodeName == 'I' || target.nodeName == 'SPAN') {
        target = e.target.parentNode
      }
      target.blur()
      this.getList()
      this.$message({
        message: '刷新成功',
        type: 'success'
      })
    },
    /** 查询表格列表 */
    async getList () {
      this.loadingTable = true
      const { rows, total } = await templateListAPI(this.queryParams)
      this.tableData = rows
      this.total = total
      this.loadingTable = false
    },
    //点击行选中
    handleRowClick (row) {
      // 获取表格对象
      // 切换行选中状态
      this.templateItem = row
    },
    //新增模板
    getAddTemplate () {
      this.templateReset()
      this.dialogVisibleTem = true
    }
  }
}
</script>

<style lang="scss" scoped>
.tableBtn {
  height: 50px;
  line-height: 50px;
  background-color: #f9f9fa;
  padding-left: 5px;
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
}
</style>
