<template>
  <div class="wrap">
    <!-- 表格商品信息卡片 -->
    <cardTitleCom cardTitle="提醒设置">
      <template slot="cardContent">
        <!-- 整体盒子 -->
        <div class="wholeMessage">
          <!-- 第一行 -->

          <div class="marT10 marB10 tableBtn">
            <div v-if="$store.state.user.userinfo.tenantId == 2">
              <el-button type="primary" size="mini" @click="getAddRemind"
                >新增</el-button
              >
              <el-button
                type="primary"
                size="mini"
                @click="getEditRemind"
                :disabled="single"
                >编辑</el-button
              >
              <el-button
                type="danger"
                size="mini"
                :disabled="single"
                @click="getDelRemind"
                >删除</el-button
              >
            </div>
            <div>
              <el-button
                size="mini"
                class="marR10"
                icon="el-icon-refresh"
                @click="getList"
                >刷新
              </el-button>
            </div>
          </div>
          <el-table
            v-loading="loadingTable"
            tooltip-effect="dark"
            :data="tableData"
            border
            max-height="700"
            height="700"
            ref="multipleTable"
            @row-click="handleRowClick"
          >
            <el-table-column width="55" align="center">
              <template v-slot="scope">
                <el-radio v-model="goodsItem" :label="scope.row">{{
                  ''
                }}</el-radio>
              </template>
            </el-table-column>
            <el-table-column
              label="序号"
              align="center"
              width="80"
              type="index"
            />
            <el-table-column
              label="提醒类型"
              align="center"
              prop="remindTypeId"
            >
              <template slot-scope="scope">
                <dict-tag
                  :options="dict.type.remind_type_id"
                  :value="scope.row.remindTypeId"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="提醒标题"
              align="center"
              prop="remindTitle"
            />
            <el-table-column
              label="模板内容"
              align="center"
              prop="remindContent"
              show-overflow-tooltip
            />
            <el-table-column
              label="推送时间"
              align="center"
              prop="sendTimeName"
            />
            <el-table-column
              label="发送对象"
              align="center"
              prop="sendClientName"
            />
            <el-table-column
              prop="isStart"
              label="启用"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.isStart"
                  :active-value="true"
                  :inactive-value="false"
                  @change="changeIsStart(scope.row)"
                ></el-switch>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
    </cardTitleCom>
    <!-- 短信模板弹窗 -->

    <c-dialog title="编辑模板" :width="650" :showDialog.sync="dialogVisible">
      <template #content>
        <el-form
          v-loading="loadingDialog"
          :model="remindForm"
          :rules="rules"
          ref="form"
          label-width="100px"
          style="padding-right: 30px"
        >
          <el-form-item prop="remindTypeId" label="提醒类型">
            <SelectLocal
              @selectChange="selectChange"
              v-model="remindForm.remindTypeId"
              :option="{
                data: dict.type.remind_type_id,
                value: 'value',
                label: 'label'
              }"
              placeholder="请输入提醒类型"
            />
          </el-form-item>
          <el-form-item prop="remindTitle" label="提醒标题">
            <el-input
              size="mini"
              v-model="remindForm.remindTitle"
              placeholder="请输入提醒标题"
            />
          </el-form-item>
          <el-form-item prop="sendTimeName" label="推送时间">
            <el-input
              size="mini"
              v-model="remindForm.sendTimeName"
              placeholder="请输入推送时间"
            />
          </el-form-item>
          <el-form-item prop="sendClientName" label="发送对象">
            <el-input
              size="mini"
              v-model="remindForm.sendClientName"
              placeholder="请输入发送对象"
            />
          </el-form-item>
          <el-form-item prop="remindContent" label="模版内容">
            <el-input
              size="mini"
              type="textarea"
              :rows="10"
              maxlength="255"
              show-word-limit
              v-model="remindForm.remindContent"
              placeholder="请输入模版内容"
            />
          </el-form-item>
          <div class="x-w" style="margin-left: 100px">
            <el-button
              class="marB10"
              size="mini"
              style="margin: 0 10px 10px 0"
              @click="quickContent(item)"
              v-for="(item, index) in messageList"
              :key="index"
              >{{ item }}</el-button
            >
          </div>
          <el-form-item prop="tel" label="手机号">
            <div class="x-f">
              <el-input
                style="margin-right: 10px"
                size="mini"
                v-model="remindForm.tel"
                placeholder="请输入手机号"
              />
              <el-button type="primary" size="mini" @click="codeTel"
                >测试发送</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </template>
      <template #buttonList>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submitForm">确定</el-button>
          <el-button @click="cancel">取消</el-button>
        </span>
      </template>
    </c-dialog>
  </div>
</template>

<script>
import SelectLocal from '@/components/tablePage/select/select-local/index.vue'
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import {
  remindListAPI,
  remindDetailAPI,
  remindUpdateAPI,
  remindSaveAPI,
  remindRemoveAPI,
  celTelAPI,
  getMarketingSmsRemindConfigParameter
} from '@/api/marketing/sms/remind' //短信提醒
export default {
  name: 'remind',
  components: { cardTitleCom, CDialog, SelectLocal },
  dicts: ['remind_type_id'],
  data () {
    return {
      messageList: [], //短信快捷消息
      rules: {
        remindTypeId: [
          {
            required: true,
            message: '提醒类型不能为空',
            trigger: ['blur', 'change']
          }
        ],
        remindTitle: [
          {
            required: true,
            message: '提醒标题不能为空',
            trigger: ['blur', 'change']
          }
        ],
        sendTimeName: [
          {
            required: true,
            message: '推送时间不能为空',
            trigger: ['blur', 'change']
          }
        ],
        sendClientName: [
          {
            required: true,
            message: '发送对象不能为空',
            trigger: ['blur', 'change']
          }
        ],
        remindContent: [
          {
            required: true,
            message: '模版内容不能为空',
            trigger: ['blur', 'change']
          }
        ]
      },
      //编辑按钮
      single: true,
      //编辑提醒设置模板弹框
      dialogVisible: false,
      //表单
      remindForm: {
        isCustom: true
      },
      //表格遮罩
      loadingTable: false,
      //编辑提醒弹框遮罩
      loadingDialog: false,
      //提醒设置表格
      tableData: [],
      //查询
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      goodsItem: undefined
    }
  },
  watch: {
    goodsItem () {
      if (this.goodsItem) {
        this.single = false
      } else {
        this.single = true
      }
    }
  },
  async created () {
    const res = await remindListAPI(this.queryParams)
    this.tableData = res.rows
  },
  methods: {
    //快捷内容
    quickContent (val) {
      if (!this.remindForm.remindContent) {
        this.$set(this.remindForm, 'remindContent', '')
      }
      let remindContentStr = String(this.remindForm.remindContent) + String(val)
      this.$set(this.remindForm, 'remindContent', remindContentStr)
    },
    //切换详情
    async selectChange (val) {
      try {
        if (!this.loadingDialog) {
          this.loadingDialog = true
        }
        const res = await getMarketingSmsRemindConfigParameter(val)
        this.messageList = res.data || []
      } catch (error) {
      } finally {
        this.loadingDialog = false
      }
    },
    //发送短信
    async codeTel () {
      if (!this.remindForm.tel) {
        return this.$message({
          message: `手机号不能为空`,
          type: 'error'
        })
      }
      await this.$refs['form'].validate()
      await celTelAPI(this.remindForm)
      this.$message({
        message: `发送成功`,
        type: 'success'
      })
    },
    //启用
    async changeIsStart (row) {
      await remindUpdateAPI(row)
      this.$message({
        message: `修改成功`,
        type: 'success'
      })
      this.getList()
    },
    //弹窗确认按钮
    async submitForm () {
      this.$refs['form'].validate(async v => {
        if (v) {
          if (this.remindForm.remindId) {
            await remindUpdateAPI(this.remindForm)
            this.$message({
              message: `编辑成功`,
              type: 'success'
            })
          } else {
            await remindSaveAPI(this.remindForm)
            this.$message({
              message: `新增成功`,
              type: 'success'
            })
          }
          await this.getList()
          this.dialogVisible = false
          this.reset()
        }
      })
    },
    //弹框取消按钮
    cancel () {
      this.dialogVisible = false
      this.reset()
    },
    //新增
    getAddRemind () {
      this.reset()
      this.dialogVisible = true
    },
    //编辑提醒
    async getEditRemind () {
      if (this.goodsItem.isStart) {
        this.$message.error('已启用的提醒不能编辑！')
        return
      }
      this.dialogVisible = true
      this.loadingDialog = true
      const res = await remindDetailAPI(this.goodsItem.remindId)
      this.remindForm = res.data
      await this.selectChange(this.remindForm.remindTypeId)
      //短信内容
      // this.remindContent = JSON.parse(
      //   JSON.stringify(this.remindForm.remindContent)
      // )
      this.loadingDialog = false
    },
    //删除提醒
    async getDelRemind () {
      if (this.goodsItem.isStart) {
        this.$message.error('已启用的提醒不能删除！')
        return
      }
      await remindRemoveAPI([this.goodsItem.remindId])
      await this.getList()
      this.$message({
        message: `删除成功`,
        type: 'success'
      })
    },
    // 表单重置
    reset () {
      this.messageList = []
      this.remindForm = {
        isCustom: true
      }
      this.resetForm('form')
    },
    //点击行选中
    handleRowClick (row) {
      // 获取表格对象
      // 切换行选中状态
      this.goodsItem = row
    },
    /** 查询表格列表 */
    async getList () {
      this.loadingTable = true
      const res = await remindListAPI(this.queryParams)
      this.tableData = res.rows
      this.total = res.total
      this.loadingTable = false
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 10px 10px 1px 10px;
  background-color: #dbdbdb;
  //表格
  .wholeMessage {
    padding: 6px 10px;
    .tableBtn {
      height: 50px;
      line-height: 50px;
      background-color: #f9f9fa;
      padding-left: 5px;
      margin-bottom: 6px;
      display: flex;
      justify-content: space-between;
    }
  }
  //弹窗标题盒
  .cardTitle {
    padding-left: 10px;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #dedede;
    width: 100%;
    //标题
    .title {
      color: #333333;
      font-weight: 700;
      font-size: 14px;
      padding-left: 6px;
      border-left: 3px solid #1596fd;
    }
  }
  // 短信
  .shortMessage {
    width: 100%;
    //标题
    .message {
      width: 110px;
      padding-left: 38px;
    }
  }
}
</style>
