<template>
  <div class="wrap" v-loading="loading">
    <el-form ref="form" :model="upgradeForm" :rules="rules" label-width="125px">
      <topOperatingButton :disabled="disabled" :showAudit="showAudit" :isAddBtn="showAdd" :isExamineBtn="true"
        @submitForm="submitForm(false)" @addBill="addBill(true)" @auditBill="auditBill" @handleAdd="handleAdd"
        @getQuit="getQuit" id="topOperatingButton">
      </topOperatingButton>
      <!-- 顶部按钮 -->
      <!-- <div class="btnTOP">
        <el-button
          type="primary"
          size="mini"
          :disabled="disabled"
          @click="submitForm(true)"
          >保存
        </el-button>
        <el-button
          type="primary"
          size="mini"
          :disabled="disabled"
          @click="addBill"
          >保存并新增
        </el-button>
        <el-button
          @click="auditBill('审核')"
          icon="iconfont icon-a-weibiaoti-1_huaban1fuben28"
          size="mini"
          :disabled="showAudit"
          type="success"
          >审核</el-button
        >
        <el-button
          class="marL10"
          type="primary"
          icon="el-icon-plus"
          size="mini"
          v-show="showAdd"
          @click="handleAdd"
          >新增</el-button
        >
        <el-button size="small" @click="getQuit">退出</el-button>
      </div> -->
      <!-- 基本信息 -->
      <cardTitleCom cardTitle="基本信息" :auditOrder="auditOrder" :noAuditOrder="noAuditOrder" :stopOrder="stopOrder"
        :deleteOrder="deleteOrder" :disableOrder="disableOrder">
        <template slot="cardContent">
          <div class="tableContent">
            <div class="x-x marB10">
              <el-form-item label="方案编号" prop="upgradeCaseNo">
                <el-input class="inputWidth" :disabled="true" size="mini" v-model="upgradeForm.upgradeCaseNo"
                  placeholder="方案编号"></el-input>
              </el-form-item>
              <el-form-item label="方案名称" prop="upgradeCaseName">
                <el-input class="inputWidth" :disabled="disabled" v-model="upgradeForm.upgradeCaseName"
                  placeholder="方案名称" maxlength="40" size="mini" clearable></el-input>
              </el-form-item>
              <el-form-item label="方案类型" prop="upgradeCaseType">
                <el-select class="inputWidth" v-model="upgradeForm.upgradeCaseType" placeholder="方案类型"
                  :disabled="disabled" size="mini" filterable clearable>
                  <el-option label="升级" :value="1"></el-option>
                  <el-option label="降级" :value="2"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="当前级别" prop="currentVipLevelId">
                <SelectRemote @selectChange="changeVipBrand" v-model="upgradeForm.currentVipLevelId" :option="
                  $select({
                    key: 'viplevel',
                    option: {
                      option: {
                        disabled: disabled,
                      },
                    },
                  }).option
                " />
              </el-form-item>
            </div>
            <div class="x-x">
              <el-form-item label="目标级别" prop="upgradeVipLevelId">
                <SelectRemote v-model="upgradeForm.upgradeVipLevelId" :option="
                  $select({
                    key: 'viplevel',
                    option: {
                      option: {
                        disabled: disabled||!upgradeForm.currentVipLevelId,
                        remoteBody: { vipBrandId: vipBrandId },
                      },
                    },
                  }).option
                " />
              </el-form-item>
              <el-form-item label="目标级别有效期" prop="validValue" label-width="125px">
                <el-input class="inputWidth" :disabled="disabled" size="mini" v-model.number="upgradeForm.validValue"
                  @input="valueBlur(11)" placeholder="目标级别有效期" clearable />
              </el-form-item>

              <el-radio-group :disabled="disabled" v-model="upgradeForm.validType" class="marT10 marL10 marR20">
                <el-radio :label="1">日</el-radio>
                <el-radio :label="2">周</el-radio>
                <el-radio :label="3">月</el-radio>
                <el-radio :label="4">年</el-radio>
              </el-radio-group>
              <el-form-item label="备注">
                <el-input class="inputRemark marL10" :disabled="disabled" size="mini" v-model="upgradeForm.remark"
                  type="textarea" placeholder="备注长度介于 1 和 80 字符之间" maxlength="80"
                  :autosize="{ minRows: 1, maxRows: 5 }" />
              </el-form-item>
            </div>
          </div>
        </template>
      </cardTitleCom>

      <!-- 升级规则设置 -->
      <cardTitleCom cardTitle="升级规则设置" tooltipText="自成为当前级别会员之日起计算，满足规则所设条件时自动升级">
        <template slot="cardContent">
          <div class="tableContent">
            <!-- 条件设置 -->
            <div class="conditionSettings marB20">
              <span class="fontS14">条件设置</span>
              <el-radio-group :disabled="disabled" v-model="upgradeForm.conditionType" class="marL20">
                <el-radio :label="1">满足所有条件</el-radio>
                <el-radio :label="2">满足任意条件</el-radio>
              </el-radio-group>
            </div>
            <!-- 条件内容 -->
            <div class="condition y-c">
              <!-- 第一行 -->
              <div class="x-fc marB20">
                <!-- 累计积分 -->
                <div class="marR20">
                  <el-checkbox :disabled="disabled" v-model="upgradeForm.isUseAllScore"
                    class="inputW140">累计积分</el-checkbox>
                  <el-input :disabled="disabled" size="mini" v-model.number="upgradeForm.allScoreValue"
                    class="inputWidth marL15 marR15" @input="valueBlur(1)"></el-input>
                </div>
                <!-- 30天内消费金额 -->
                <div class="marL20">
                  <el-checkbox :disabled="disabled" v-model="upgradeForm.isUseThirtyDayConsume"
                    class="inputW140">30天内消费金额</el-checkbox>
                  <el-input :disabled="disabled" size="mini" v-model.number="upgradeForm.thirtyDayConsumeValue"
                    class="inputWidth marL15 marR15" @input="valueBlur(2)"></el-input>
                </div>
              </div>

              <!-- 第二行 -->
              <div class="x-fc marB20">
                <!-- 单笔充值金额 -->
                <div class="marR20">
                  <el-checkbox :disabled="disabled" v-model="upgradeForm.isUseOneFill"
                    class="inputW140">单笔充值金额</el-checkbox>
                  <el-input :disabled="disabled" size="mini" v-model.number="upgradeForm.oneFillValue"
                    class="inputWidth marL15 marR15" @input="valueBlur(3)"></el-input>
                </div>
                <!-- 一年内消费金额 -->
                <div class="marL20">
                  <el-checkbox :disabled="disabled" v-model="upgradeForm.isUseOneYearConsume"
                    class="inputW140">一年内消费金额</el-checkbox>
                  <el-input :disabled="disabled" size="mini" v-model.number="upgradeForm.oneYearConsumeValue"
                    class="inputWidth marL15 marR15" @input="valueBlur(4)"></el-input>
                </div>
              </div>

              <!-- 第三行 -->
              <div class="x-fc marB20">
                <!-- 累计充值金额 -->
                <div class="marR20">
                  <el-checkbox :disabled="disabled" v-model="upgradeForm.isUseAllFill"
                    class="inputW140">累计充值金额</el-checkbox>
                  <el-input :disabled="disabled" size="mini" v-model.number="upgradeForm.allFillValue"
                    class="inputWidth marL15 marR15" @input="valueBlur(5)"></el-input>
                </div>
                <!-- 累计消费金额 -->
                <div class="marL20">
                  <el-checkbox :disabled="disabled" v-model="upgradeForm.isUseAllConsume"
                    class="inputW140">累计消费金额</el-checkbox>
                  <el-input :disabled="disabled" size="mini" v-model.number="upgradeForm.allConsumeValue"
                    class="inputWidth marL15 marR15" @input="valueBlur(6)"></el-input>
                </div>
              </div>

              <!-- 第四行 -->
              <div class="x-fc marB20">
                <!-- 当日充值金额 -->
                <div class="marR20">
                  <el-checkbox :disabled="disabled" v-model="upgradeForm.isUseCurrentDayFill"
                    class="inputW140">当日充值金额</el-checkbox>
                  <el-input :disabled="disabled" size="mini" v-model.number="upgradeForm.currentDayFillValue"
                    class="inputWidth marL15 marR15" @input="valueBlur(7)"></el-input>
                </div>
                <!-- 累计消费次数 -->
                <div class="marL20">
                  <el-checkbox :disabled="disabled" v-model="upgradeForm.isUseAllConsumeCount"
                    class="inputW140">累计消费次数</el-checkbox>
                  <el-input :disabled="disabled" size="mini" v-model.number="upgradeForm.allConsumeCountValue"
                    class="inputWidth marL15 marR15" @input="valueBlur(8)"></el-input>
                </div>
              </div>

              <!-- 第五行 -->
              <div class="x-fc marB20">
                <!-- 一周内消费金额 -->
                <div class="marR20">
                  <el-checkbox :disabled="disabled" v-model="upgradeForm.isUseOneWeekConsume"
                    class="inputW140">一周内消费金额</el-checkbox>
                  <el-input :disabled="disabled" size="mini" v-model.number="upgradeForm.oneWeekConsumeValue"
                    class="inputWidth marL15 marR15" @input="valueBlur(9)"></el-input>
                </div>
                <!-- 单笔消费金额 -->
                <div class="marL20">
                  <el-checkbox :disabled="disabled" v-model="upgradeForm.isUseOneConsume"
                    class="inputW140">单笔消费金额</el-checkbox>
                  <el-input :disabled="disabled" size="mini" v-model.number="upgradeForm.oneConsumeValue"
                    class="inputWidth marL15 marR15" @input="valueBlur(10)"></el-input>
                </div>
              </div>
              <!-- 第六行 -->
              <div class="x-x marB20">
                <!-- 一周内消费金额 -->
                <div class="marR20">
                  <el-checkbox :disabled="disabled" v-model="upgradeForm.isUseThirtyDayConsumeCount"
                    class="inputW140">30天内消费次数</el-checkbox>
                  <el-input :disabled="disabled" size="mini" v-model.number="upgradeForm.thirtyDayConsumeCountValue"
                    class="inputWidth marL15 marR15" @input="valueBlur(12)"></el-input>
                </div>
                <div class="marL20" style="width:420px">
                </div>
              </div>
            </div>
          </div>
        </template>
      </cardTitleCom>
    </el-form>
  </div>
</template>

<script>
import topOperatingButton from '@/views/components/topOperatingButton' //顶部按钮
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import { listlevel } from '@/api/vip/base/level' //会员等级
import { getBillNo } from '@/api/codeRule' //方案号
import {
  upgradeAddAPI,
  upgradeDetailAPI,
  upgradeUpdateAPI,
  upgradeIsStopAPI,
  upgradeUpdateStatusAPI
} from '@/api/marketing/vipMarketing/upgrade' //会员升级
export default {
  name: 'upgradeDetail',
  components: {
    cardTitleCom,
    topOperatingButton,
    SelectRemote: () => import("@/components/tablePage/select/select-remote/index.vue")
  },
  data() {
    return {
      //会员等级
      levelList: [],
      //标识已审核
      auditOrder: false,
      //标识未审核
      noAuditOrder: false,
      //标识已关闭
      stopOrder: false,
      //标识已删除
      deleteOrder: false,
      //标识已禁用
      disableOrder: false,
      showAudit: true, //审核按钮禁用状态
      showAdd: false, //新增按钮
      disabled: false, //保存禁用
      loadingTable: false, //表格遮罩
      loading: false, //大盒子遮罩层
      //旧的表单
      oldForm: {},
      // 表单参数
      upgradeForm: {
        caseStatus: '', //方案状态
        conditionType: 1, //条件设置
        validType: 1, //有效类型
        upgradeCaseType: 1, //类型
        isUseAllScore: false,
        isUseThirtyDayConsume: false,
        isUseOneFill: false,
        isUseOneYearConsume: false,
        isUseAllFill: false,
        isUseAllConsume: false,
        isUseCurrentDayFill: false,
        isUseAllConsumeCount: false,
        isUseOneWeekConsume: false,
        isUseOneConsume: false,
        isUseThirtyDayConsumeCount: false,
        upgradeCaseNo: ''
      },
      // 表单校验
      rules: {
        upgradeCaseNo: [
          {
            required: true,
            message: '请到编码规则配置方案类型',
            trigger: ['blur', 'change']
          }
        ],
        upgradeCaseName: [
          {
            required: true,
            message: '方案名称不能为空',
            trigger: ['blur', 'change']
          }
        ],
        upgradeCaseType: [
          {
            required: true,
            message: '方案类型不能为空',
            trigger: ['blur', 'change']
          }
        ],
        currentVipLevelId: [
          {
            required: true,
            message: '当前级别不能为空',
            trigger: ['blur', 'change']
          }
        ],
        upgradeVipLevelId: [
          {
            required: true,
            message: '升级目标级别不能为空',
            trigger: ['blur', 'change']
          }
        ],
        validValue: [
          {
            required: true,
            message: '目标级别有效期不能为空',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },

  beforeRouteEnter(to, from, next) {
    next(async vm => {
      vm.loading = true
      // 重置数据
      vm.reset()
      //初始化数据
      vm.getInitializeData()
      // 访问组件实例 vm 中的数据和方法
      if (vm.$route.query.type === 'Update') {
        if (vm.$route.query.upgradeCaseId) {
          console.log(vm.$route.query.upgradeCaseId)
          //获取方案详情
          const { data } = await upgradeDetailAPI(vm.$route.query.upgradeCaseId)
          //方案赋值
          vm.upgradeForm = data
          //原始方案赋值
          vm.oldForm = JSON.parse(JSON.stringify(vm.upgradeForm))
        }
      } else {
        await vm.getOrderBillNo()
        //判断是否有以前新增时保存的数据
        if (JSON.stringify(vm.oldForm) != '{}') {
          vm.upgradeForm = vm.oldForm
        }
        vm.oldForm = JSON.parse(JSON.stringify(vm.upgradeForm))
      }
      vm.loading = false
    })
  },
  async beforeRouteLeave(to, from, next) {
    // 在用户离开二级路由前弹出询问框
    if (this.upgradeForm.upgradeCaseId) {
      this.oldForm = {}
    } else {
      this.oldForm = JSON.parse(JSON.stringify(this.upgradeForm))
    }
    next()
  },
  watch: {
    //侦听单据状态
    'upgradeForm.caseStatus': {
      handler(newVal) {
        if (this.upgradeForm.caseStatus == 2) {
          //新增按钮
          this.showAdd = true
          //打开审核图标
          this.auditOrder = true
          //禁用输入按钮、保存按钮
          this.disabled = true
          //打开审核按钮
          this.showAudit = true
          //关闭已禁用图标
          this.disableOrder = false
          //关闭关闭图标
          this.stopOrder = false
          //关闭已删除图标
          this.deleteOrder = false
          //关闭未审核图标
          this.noAuditOrder = false
        } else if (this.upgradeForm.caseStatus == 0) {
          //新增按钮
          this.showAdd = false
          //未审核图标
          this.noAuditOrder = true
          //禁用审核按钮
          this.showAudit = false
          //关闭审核图标
          this.auditOrder = false
          //关闭已禁用图标
          this.disableOrder = false
          //关闭关闭图标
          this.stopOrder = false
          //关闭已删除图标
          this.deleteOrder = false
          //恢复全部输入
          this.disabled = false
        } else if (this.upgradeForm.caseStatus == '') {
          //引入源单按钮
          this.showImportBill = true
          //新增按钮
          this.showAdd = false
          //关闭已禁用图标
          this.disableOrder = false
          //关闭关闭图标
          this.stopOrder = false
          //关闭已删除图标
          this.deleteOrder = false
          //关闭审核图标
          this.auditOrder = false
          //关闭未审核图标
          this.noAuditOrder = false
          //禁用审核按钮
          this.showAudit = true
          //恢复全部输入
          this.disabled = false
        }
      },
      immediate: true
    }
  },
  methods: {
    changeVipBrand(v, row) {
      this.vipBrandId = row.vipBrandId
    },
    valueBlur(type) {
      const obj = {
        1: 'allScoreValue',
        2: 'thirtyDayConsumeValue',
        3: 'oneFillValue',
        4: 'oneYearConsumeValue',
        5: 'allFillValue',
        6: 'allConsumeValue',
        7: 'currentDayFillValue',
        8: 'allConsumeCountValue',
        9: 'oneWeekConsumeValue',
        10: 'oneConsumeValue',
        11: 'validValue',
        12: 'thirtyDayConsumeCountValue',
      }
      let regex = ''
      if (type != 11) {
        regex = /^([1-9]\d{0,7})?$/;
      } else {
        regex = /^([1-9]\d{0,3})?$/;
      }
      const value = this.upgradeForm[obj[type]]

      if (!regex.test(value)) {
        const text = `${this.upgradeForm[obj[type]]}`
        this.upgradeForm[obj[type]] = text.substring(0, text.length - 1).replace(/[^\d^\.]+/g, "")
      }
    },
    //审核/删除/终止事件
    async auditBill(name) {
      let valid = await this.submitForm()
      if (!valid) return
      if (name === '审核') {
        if (this.upgradeForm.caseStatus != 0) {
          this.$message.error('此单据不是未审核的状态哦~')
          return
        }
        const obj = {
          upgradeCaseIds: [this.upgradeForm.upgradeCaseId],
          caseStatus: 2
        }
        //发送审核api
        const { data } = await upgradeUpdateStatusAPI(obj)
        this.upgradeForm = data
      }
      try {
        await this.$confirm(`此单据已审核，是否要启用?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        })
        const obj = {
          upgradeCaseIds: [this.upgradeForm.upgradeCaseId],
          isStop: false
        }
        await upgradeIsStopAPI(obj)
        // 弹出提示
        this.$message.success('审核并启用成功')
      } catch {
        // 弹出提示
        this.$message.success('审核成功')
      }
    },
    /** 提交按钮 */
    async submitForm(isBool) {
      // this.$refs['form'].validate(async valid => {
      let valid = await this.$refs['form'].validate()
      console.log(valid, 'valid');
      if (valid) {
        //判断级别和目标级别是否相等
        if (
          this.upgradeForm.currentVipLevelId ==
          this.upgradeForm.upgradeVipLevelId
        ) {
          this.$message.error('当前会员级别和目标会员级别不能相同')
          return false
        }
        //判断条件规则是否全部为空
        if (
          !this.upgradeForm.isUseAllScore &&
          !this.upgradeForm.isUseThirtyDayConsume &&
          !this.upgradeForm.isUseOneFill &&
          !this.upgradeForm.isUseOneYearConsume &&
          !this.upgradeForm.isUseAllFill &&
          !this.upgradeForm.isUseAllConsume &&
          !this.upgradeForm.isUseCurrentDayFill &&
          !this.upgradeForm.isUseAllConsumeCount &&
          !this.upgradeForm.isUseOneWeekConsume &&
          !this.upgradeForm.isUseOneConsume &&
          !this.upgradeForm.isUseThirtyDayConsumeCount
        ) {
          this.$message.error('请设置条件规则')
          return false
        }
        //判断条件规则内容是否为空  累计积分
        if (
          this.upgradeForm.isUseAllScore &&
          !this.upgradeForm.allScoreValue
        ) {
          this.$message.error('请设置条件规则:累计积分')
          return false
        }
        //判断条件规则内容是否为空  30天内消费金额
        if (
          this.upgradeForm.isUseThirtyDayConsume &&
          !this.upgradeForm.thirtyDayConsumeValue
        ) {
          this.$message.error('请设置条件规则:30天内消费金额')
          return false
        }
        //判断条件规则内容是否为空  单笔充值金额
        if (this.upgradeForm.isUseOneFill && !this.upgradeForm.oneFillValue) {
          this.$message.error('请设置条件规则:单笔充值金额')
          return false
        }
        //判断条件规则内容是否为空  一年内消费金额
        if (
          this.upgradeForm.isUseOneYearConsume &&
          !this.upgradeForm.oneYearConsumeValue
        ) {
          this.$message.error('请设置条件规则:一年内消费金额')
          return false
        }
        //判断条件规则内容是否为空  累计充值金额
        if (this.upgradeForm.isUseAllFill && !this.upgradeForm.allFillValue) {
          this.$message.error('请设置条件规则:累计充值金额')
          return false
        }
        //判断条件规则内容是否为空  累计消费金额
        if (
          this.upgradeForm.isUseAllConsume &&
          !this.upgradeForm.allConsumeValue
        ) {
          this.$message.error('请设置条件规则:累计消费金额')
          return false
        }
        //判断条件规则内容是否为空  当日充值金额
        if (
          this.upgradeForm.isUseCurrentDayFill &&
          !this.upgradeForm.currentDayFillValue
        ) {
          this.$message.error('请设置条件规则:当日充值金额')
          return false
        }
        //判断条件规则内容是否为空  累计消费次数
        if (
          this.upgradeForm.isUseAllConsumeCount &&
          !this.upgradeForm.allConsumeCountValue
        ) {
          this.$message.error('请设置条件规则:累计消费次数')
          return false
        }
        //判断条件规则内容是否为空  一周内消费金额
        if (
          this.upgradeForm.isUseOneWeekConsume &&
          !this.upgradeForm.oneWeekConsumeValue
        ) {
          this.$message.error('请设置条件规则:一周内消费金额')
          return false
        }
        //判断条件规则内容是否为空  单笔消费金额
        if (
          this.upgradeForm.isUseOneConsume &&
          !this.upgradeForm.oneConsumeValue
        ) {
          this.$message.error('请设置条件规则:单笔消费金额')
          return false
        }
        //判断条件规则内容是否为空  30天消费
        if (
          this.upgradeForm.isUseThirtyDayConsumeCount &&
          !this.upgradeForm.thirtyDayConsumeCountValue
        ) {
          this.$message.error('请设置条件规则:30天内消费次数')
          return false
        }
        if (this.upgradeForm.upgradeCaseId) {
          const res = await upgradeUpdateAPI(this.upgradeForm)
          //重新赋值给表单，可以把id赋值
          if (isBool) {
            this.reset()
            this.$modal.msgSuccess('保存并新增方案成功')
          } else {
            this.$modal.msgSuccess('修改方案成功')
            this.upgradeForm = res.data
          }
          return true
        } else {
          const { data } = await upgradeAddAPI(this.upgradeForm)
          //重新赋值给表单，可以把id赋值
          if (isBool) {
            this.upgradeForm = data
            this.reset()
            this.$modal.msgSuccess('保存并修改方案成功')
          } else {
            this.upgradeForm = data
            this.$modal.msgSuccess('新增方案成功')
          }
          return true
        }
      }
      // })
    },
    //初始化全部的数据
    async getInitializeData() {
      this.loading = true
      //会员等级
      const res = await listlevel({ pageNum: 1, pageSize: 9999999 })
      this.levelList = res.rows
      this.loading = false
    },
    // 表单重置
    reset() {
      //新增按钮
      this.showAdd = false
      //关闭已删除图标
      this.deleteOrder = false
      //关闭审核图标
      this.auditOrder = false
      //关闭未审核图标
      this.noAuditOrder = false
      //关闭已禁用图标
      this.disableOrder = false
      //保存禁用
      this.disabled = false
      this.upgradeForm = {
        caseStatus: '', //方案审核状态
        conditionType: 1, //条件设置
        validType: 1, //有效类型
        upgradeCaseType: 1, //类型
        isUseAllScore: false,
        isUseThirtyDayConsume: false,
        isUseOneFill: false,
        isUseOneYearConsume: false,
        isUseAllFill: false,
        isUseAllConsume: false,
        isUseCurrentDayFill: false,
        isUseAllConsumeCount: false,
        isUseOneWeekConsume: false,
        isUseOneConsume: false,
        isUseThirtyDayConsumeCount: false,
        upgradeCaseNo: '',
      }
      this.getOrderBillNo()
      // this.resetForm('form')
    },
    //新增按钮
    async handleAdd() {
      this.loading = true
      this.reset()
      await this.getOrderBillNo()
      await this.getInitializeData()
      this.loading = false
    },
    //保存并新增方案
    async addBill() {
      this.$confirm('是否保存并新增方案?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success'
      })
        .then(async () => {
          // this.loading = true
          this.submitForm(true)
          // this.reset()
          // await this.getOrderBillNo()
          // this.loading = false
        })
        .catch()
    },
    //方案编号
    async getOrderBillNo() {
      this.upgradeForm.upgradeCaseNo = await getBillNo(160301)
    },
    //退出
    getQuit() {
      this.$store.state.tagsView.visitedViews.splice(
        this.$store.state.tagsView.visitedViews.findIndex(
          item => item.path === this.$route.path
        ),
        1
      )
      this.$destroy()
      this.$router.push('/marketing/vipMarketing/upgrade')
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  background-color: #eaeaea;
  padding: 40px 10px 6px 10px;
  min-height: 100vh;
  // 顶部按钮
  .btnTOP {
    text-align: right;
    padding: 0 10px 6px 0;
  }
  .tableContent {
    margin: 15px 10px 15px 10px;
    // 条件设置
    .conditionSettings {
      margin-left: 46px;
    }
    //条件
    .condition {
      margin-left: 122px;
      background-color: #f8f8f9;
      width: 1230px;
      height: 522px;
      .inputW140 {
        width: 140px;
      }
    }
  }
  //内部表单
  ::v-deep .el-form-item {
    padding: 0;
    margin: 0;
  }
  // /* 单选框选中后的字体颜色 */
  ::v-deep .el-radio__input.is-checked + .el-radio__label {
    color: #303133 !important;
  }
  /* 复选框选中后的字体颜色 */
  ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #303133 !important;
  }
  //输入框原生属性
  /**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
  ::v-deep .el-input__inner {
    line-height: 1px !important;
  }

  /**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
  ::v-deep input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  //阿里图标
  ::v-deep .iconfont {
    font-size: 12px;
  }
}
</style>
