<template>
  <div class="wrap">
    <cardTitleCom cardTitle="搜索">
      <template slot="cardContent">
        <div class="navSearch">
          <el-input
            class="inputWidth marR10"
            placeholder="请搜索短信签名"
            size="mini"
            v-model="queryParams.signatureContent"
          />
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="getList"
            >搜索</el-button
          >
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="单据信息">
      <template slot="cardContent">
        <div class="tableContent">
          <div class="tableBtn">
            <div class="x-f">
              <el-button
                type="primary"
                icon="el-icon-plus"
                size="mini"
                @click="handleAdd"
                >新增</el-button
              >
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-circle-check"
                @click="getDefault"
                :disabled="single"
                >设为默认</el-button
              >
              <el-dropdown
                trigger="click"
                class="marL10 marR10"
                split-button
                type="danger"
                size="mini"
                icon="el-icon-delete"
                @click="handleDelete"
                @command="dialogOptions.show = true"
              >
                删除
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="回收站">回收站</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <!-- 右边盒子 -->
            <div>
              <el-button
                size="mini"
                class="marR10"
                icon="el-icon-refresh"
                @click="test($event)"
                >刷新
              </el-button>
            </div>
          </div>
          <el-table
            v-loading="loadingTable"
            ref="multipleTable"
            @row-click="handleRowClick"
            tooltip-effect="dark"
            :data="tableData"
            @selection-change="handleSelectionChange"
            border
            max-height="700"
            height="700"
          >
            <el-table-column type="selection" width="50" align="center" />
            <el-table-column
              label="序号"
              align="center"
              width="80"
              type="index"
            />
            <el-table-column
              show-overflow-tooltip
              label="短信签名"
              align="center"
              prop="signatureContent"
              min-width="200"
            >
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  @click="handleDetail(scope.row)"
                  target="_blank"
                >
                  {{ scope.row.signatureContent }}
                </el-link>
              </template>
            </el-table-column>
            <el-table-column
              label="是否默认"
              align="center"
              prop="isDefaultName"
              min-width="60"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.isDefault ? '是' : '否' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="创建人"
              align="center"
              prop="createBy"
              min-width="70"
            />
            <el-table-column
              label="创建时间"
              align="center"
              prop="createTime"
              min-width="70"
            />
          </el-table>
          <bottomPage
            v-model="queryParams"
            :getList="getList"
            :idsCom="ids"
            :totalCom="total"
            @selectAllCom="selectAll"
          />
        </div>
      </template>
    </cardTitleCom>
    <!-- 新增 -->
    <el-dialog
      :visible.sync="dialogVisible"
      title="短信签名"
      v-dialogDrag="true"
      width="30%"
    >
      <el-form :model="signForm" :rules="rules" ref="form">
        <el-form-item prop="signatureContent">
          <el-input
            class="marB10"
            type="textarea"
            :rows="10"
            placeholder="请输入内容"
            v-model="signForm.signatureContent"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="getConfirm">确 定</el-button>
        <el-button @click="cancel">取消</el-button>
      </span>
    </el-dialog>
    <Dialog2 :options.sync="dialogOptions" @handleEvent="getList" />
  </div>
</template>

<script>
import Dialog2 from '@/components/Dialog'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import bottomPage from '@/views/components/bottomPage' //底部分页
import {
  signListAPI,
  signAddAPI,
  signDeleteAPI,
  signDefaultAPI,
  restoreSignature,
  signDetailAPI,
  signUpdateAPI
} from '@/api/marketing/sms/sign' //短信签名
export default {
  name: 'sign',
  components: { bottomPage, cardTitleCom, Dialog2 },
  data () {
    return {
      dialogOptions: {
        type: 'ShopInfo',
        show: false,
        title: '回收站',
        width: '75vw',
        data: {
          getListApi: signListAPI,
          restoreListApi: restoreSignature,
          id: 'signatureId',
          search: [
            {
              type: 'input',
              tip: '请输入签名内容',
              model: '',
              filter: 'signatureContent'
            },
            {
              type: 'button',
              tip: '查询',
              btnType: 'primary',
              click: 'search',
              isRestore: true,
              isLeft: true
            }
          ],
          columns: [
            {
              prop: 'signatureContent',
              label: '短信签名',
              minWidth: 180
            },
            {
              prop: 'createBy',
              label: '创建人',
              minWidth: 80
            },
            {
              prop: 'createTime',
              label: '创建时间',
              minWidth: 120
            }
          ]
        }
      },
      dialogVisible: false, //新增弹窗
      //表格遮罩
      loadingTable: false,
      single: true, // 单个禁用
      multiple: true, // 非单个禁用
      billStatusNoDefaultId: [], //选中未默认的签名
      ids: [], // 选中数组
      total: 0, // 总条数
      tableData: [], //表格数据
      //查询
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      //新增表单
      signForm: {},
      //校验
      rules: {
        signatureContent: [
          {
            required: true,
            message: '短信签名不能为空',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },

  async created () {
    //初始化表
    await this.getList()
  },
  methods: {
    //详情
    async handleDetail (row) {
      try {
        this.loadingDia = true
        this.signReset()
        this.dialogVisible = true
        const res = await signDetailAPI(row.signatureId)
        this.signForm = res.data
      } catch (error) {
      } finally {
        this.loadingDia = false
      }
    },
    //默认
    async getDefault () {
      await signDefaultAPI(this.ids[0])
      this.$message({
        message: '设为默认成功',
        type: 'success'
      })

      await this.getList()
    },
    //删除请求
    handleDelete () {
      if (this.billStatusNoDefaultId.length <= 0) {
        this.$message.error('勾选不能为空且不能删除系统默认签名')
        return
      }
      this.$modal
        .confirm('是否确认删除所选的数据项？')
        .then(() => {
          signDeleteAPI(this.billStatusNoDefaultId) //删除请求
        })
        .then(() => {
          this.getList() //渲染列表
          this.$modal.msgSuccess('删除成功')
        })
        .catch(error => {})
    },
    //自定义弹窗取消按钮
    cancel () {
      this.signReset()
      this.dialogVisible = false
    },
    //自定义弹窗确认按钮
    async getConfirm () {
      if (!this.signForm?.signatureContent) {
        this.$message.error('短信签名不能为空')
        return
      }
      if (this.signForm.signatureId) {
        await signUpdateAPI(this.signForm)
        this.$message({
          message: '修改成功',
          type: 'success'
        })
      } else {
        await signAddAPI(this.signForm)
        this.$message({
          message: '新增成功',
          type: 'success'
        })
      }
      this.dialogVisible = false
      await this.getList()
    },
    //重置
    signReset () {
      // 表单重置
      this.signForm = {}
      this.resetForm('form')
    },
    //新增单据事件
    handleAdd () {
      this.signReset()
      this.dialogVisible = true
    },
    //点击行选中
    handleRowClick (row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    //刷新按钮
    test (e) {
      let target = e.target
      if (target.nodeName == 'I' || target.nodeName == 'SPAN') {
        target = e.target.parentNode
      }
      target.blur()
      this.getList()
    },

    /** 查询表格列表 */
    async getList () {
      this.loadingTable = true
      const { rows, total } = await signListAPI(this.queryParams)
      this.tableData = rows
      this.total = total
      this.loadingTable = false
    },

    // 多选框选中数据
    handleSelectionChange (selection) {
      this.ids = selection.map(item => item.signatureId) //单据id
      //选中未默认的签名
      this.billStatusNoDefaultId = selection
        .map(item => {
          if (!item.isDefault) {
            return item.signatureId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      //单个按钮禁用
      this.single = selection.length == 1 ? false : true
      //多个按钮禁用
      this.multiple = !selection.length
    },
    //点击全选所有数据
    async selectAll (value) {
      if (value) {
        this.loadingTable = true
        const res = await signListAPI({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total
        })
        //重新渲染表格
        this.tableData = res.rows
        //表格多选
        this.tableData.map(row => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value)
          })
        })
        this.loadingTable = false
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection()
        })
        this.getList()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
//外面盒子
.wrap {
  padding: 6px 10px;
  background-color: #eaeaea;
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
  }
  //源单信息表格
  .tableContent {
    padding: 6px 10px;
    .tableBtn {
      height: 50px;
      line-height: 50px;
      background-color: #f9f9fa;
      padding-left: 5px;
      margin-bottom: 6px;
      display: flex;
      justify-content: space-between;
    }
  }
  ::v-deep .el-dialog__body {
    padding: 0 20px;
  }
  ::v-deep .el-form-item {
    margin: 0;
  }
}
</style>
