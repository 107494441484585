<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options"> </TablePage>
  </div>
</template>
<script>
import TablePage from '@/components/tablePage' //表格页面组件
import {
  vipMarketingTimesCardPurchase,
  vipMarketingTimesCardPurchaseSummary
} from '@/api/marketing/timesCard/buyQuery'
export default {
  name: 'buyQuery',
  components: {
    TablePage
  },
  data() {
    return {
      options: {
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        title: '会员次卡购买查询',
        getListApi: vipMarketingTimesCardPurchase,
        getSummaryApi: vipMarketingTimesCardPurchaseSummary,
        listNo: true, // 序号
        exportOption: {
          show: true,
          fastExportUrl: '/api/system/marketing/times/card/reportForms/vipMarketingTimesCardPurchaseExport',
          exportName: '会员次卡购买查询'
        },
        //搜索
        search: [
          {
            label: '单据日期',
            type: 'datetimerange',
            model: '',
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: 'filters',
            tip: '门店编号/门店名称',
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'vipNos', label: '会员卡号' },
              { filter: 'vipNames', label: '会员名称' },
              { filter: 'timesCardNos', label: '次卡编码' },
              { filter: 'timesCardNames', label: '次卡名称' }
            ]
          },
          this.$select({
            key: 'vip',
            option: {
              filter: 'vipIds',
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择会员',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'vip' })
                    }
                  }
                ]
              }
            }
          }),
          this.$select({
            key: 'listShop',
            option: {
              filter: 'shopIds',
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择门店',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'shop' })
                    }
                  }
                ]
              }
            }
          }),
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        //表格
        columns: [
          {
            prop: 'vipNo',
            label: '会员卡号',
            minWidth: 180,
            align: 'center'
          },
          {
            prop: 'vipName',
            label: '会员名称',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'vipLevelNo',
            label: '级别编码',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'vipLevelName',
            label: '级别名称',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'timesCardNo',
            label: '次卡编码',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'timesCardName',
            label: '次卡名称',
            minWidth: 180,
            align: 'center'
          },
          {
            prop: 'goodsName',
            label: '商品',
            minWidth: 180,
            align: 'center'
          },
          {
            prop: 'totalCount',
            label: '总次数',
            minWidth: 100,
            align: 'center'
          },
          {
            prop: 'usedCount',
            label: '使用次数',
            minWidth: 100,
            align: 'center'
          },
          {
            prop: 'usableCount',
            label: '剩余次数',
            minWidth: 100,
            align: 'center'
          },
          {
            prop: 'begTime',
            label: '开始时间',
            minWidth: 155,
            align: 'center'
          },
          {
            prop: 'effectiveTime',
            label: '到期时间',
            minWidth: 155,
            align: 'center'
          },
          {
            prop: 'salePrice',
            label: '购买金额',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'purchaseTime',
            label: '购买时间',
            minWidth: 155,
            align: 'center'
          },
          {
            prop: 'shopName',
            label: '购买门店',
            minWidth: 155,
            align: 'center'
          },
          {
            prop: 'remark',
            label: '备注',
            minWidth: 130,
            align: 'center'
          }
        ],
        summary: ['totalCount', 'usedCount','usableCount','salePrice']
      }
    }
  }
}
</script>
