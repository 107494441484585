<template>
  <!-- 会员升级 -->
  <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
</template>

<script>
import TablePagination from "@/components/tablePage/tablePagination";
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import seniorSearch from '@/views/components/seniorSearch' //高级搜索

import TablePage from '@/components/tablePage/index.vue'
import { upgradeListAPI, upgradeUpdateStatusAPI, upgradeIsStopAPI, upgradeDeleteAPI } from '@/api/marketing/vipMarketing/upgrade'
import { listlevel } from '@/api/vip/base/level'

export default {
  name: 'RetailTotal',
  components: {
    TablePage,
    cardTitleCom,
    seniorSearch,
    TablePagination
  },
  data () {
    return {
      options: {
        listNo: true,
        mutiSelect: true,
        loading: true,
        check: [],
        labelWidth: '100',
        pagination: {
          total: 0,
          pageNum: 1,
          pageSize: 15,
          query: undefined, // 输入方案编号名称
          isStop: undefined, // 方案状态
          upgradeCaseType: undefined, // 方案类型
          upgradeVipLevelId: undefined, // 升级目标级别
          currentVipLevelId: undefined, // 当前级别
        },
        buttons: [
          {
            click: 'add',
            label: '新增',
            type: 'primary',
            icon: 'el-icon-plus'
          },
          {
            click: "audit",
            label: "审核",
            type: "success",
            icon:'el-icon-document',
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "enable",
            label: "启用",
            type: "warning",
            btnType: "dropdown",
            other: [
              {
                click: "redoEnable",
                label: "禁用",
              },
            ],
            disabled: () => !this.options?.check?.length,
          },
          {
            click: 'del',
            label: '删除',
            type: 'danger',
            icon: 'el-icon-delete',
            disabled: () => !this.options?.check?.length
          },
        ],
        getListApi: upgradeListAPI,
        title: '源单信息',
        rowKey: 'upgradeCaseId',
        type: 'upgradeCaseId',
        search: [
          {
            type: "filters",
            tip: "方案名称/方案编号",
            isRestore:true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "upgradeCaseNames", label: "方案名称" },
              { filter: "upgradeCaseNos", label: "方案编号" },
            ],
          },
          {
            label: '当前级别',
            seniorSearch: true,
            type: 'local',
            model: '',
            filter: 'currentVipLevelIds',
            addNum: 2,
            width: '220px',
            option: {
              remote: listlevel,
              dataKey: 'rows',
              label: 'vipLevelName',
              value: 'vipLevelId',
              filterable: true,
              multiple: true
            },
          },
          {
            label: '升级目标级别',
            seniorSearch: true,
            type: 'local',
            model: '',
            filter: 'upgradeVipLevelIds',
            addNum: 2,
            width: '220px',
            option: {
              remote: listlevel,
              dataKey: 'rows',
              label: 'vipLevelName',
              value: 'vipLevelId',
              filterable: true,
              multiple: true
            },
          },
          {
            label: '方案类型',
            seniorSearch: true,
            type: 'local',
            model: '',
            filter: 'upgradeCaseTypes',
            addNum: 2,
            width: '220px',
            option: {
              data: [
                {
                  label: '升级',
                  id: 1
                },
                {
                  label: '降级',
                  id: 2
                }
              ],
              dataKey: 'rows',
              label: 'label',
              value: 'id',
              filterable: true,
              multiple: true
            },
          },
          {
            label: '方案状态',
            seniorSearch: true,
            type: 'local',
            model: '',
            filter: 'isStops',
            addNum: 2,
            width: '220px',
            option: {
              data: [
                {
                  label: '启用',
                  id: false
                },
                {
                  label: '禁用',
                  id: true
                }
              ],
              dataKey: 'rows',
              label: 'label',
              value: 'id',
              filterable: true,
              multiple: true
            },
          },
          this.$select({
            key: 'bill_status',
            option: {
              filter: 'caseStatuss',
              seniorSearch: true,
              option:{
                multiple:true,
              }
            }
          }),
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          { type: 'button', tip: '高级查询', btnType: 'primary', click: 'seniorSearch' },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        columns: [
          { prop: 'upgradeCaseNo', type: "link", click: "routerLink", label: '方案编号', width: '160' },
          { prop: 'upgradeCaseName', label: '方案名称', width: '160' },
          { prop: 'currentVipLevelName', label: '当前级别', width: '160' },
          { prop: 'upgradeVipLevelName', label: '升级目标级别', width: '160' },
          { prop: 'upgradeCaseTypeName', label: '方案类型'},
          { prop: 'caseStatusName', label: '方案状态'},
          { prop: 'isStopName', label: '启用状态'},
          { prop: 'remark', label: '备注', width: '200' },
          { prop: 'updateBy', label: '修改人', width: '200' },
          { prop: 'updateTime', label: '修改时间', width: '200' },
          { prop: 'createBy', label: '创建人', width: '200'},
          { prop: 'createTime', label: '创建时间', width: '200'},
        ],
        list: [],
      },

      levelList: [],
    }
  },
  methods: {
    async handleEvent(type, row) {
      const selectData = this.options.check;
      switch (type) {
        case 'add':
          await this.$router.push({
            name: 'upgradeDetail'
          })
          break;
        case "routerLink":
          {
            await this.$router.push({
              name: 'upgradeDetail',
              query: {
                upgradeCaseId: row.upgradeCaseId,
                type: 'Update'
              }
            })
          }
          break;
        case "audit":
          {
            if (!selectData.length) return;
            this.handleBillStatus("审核", selectData);
          }
          break;
        case "redoAudit":
          {
            if (!selectData.length) return;
            this.handleBillStatus("反审核", selectData);
          }
          break;
        case "enable":
          {
            if (!selectData.length) return;
            this.handleBillStatus("启用", selectData);
          }
          break;
        case "redoEnable":
          {
            if (!selectData.length) return;
            this.handleBillStatus("禁用", selectData);
          }
          break;
        case "del":
          {
            if (!selectData.length) return;
            this.handleBillStatus("删除", selectData);
          }
          break;
        default:
          break;
      }
    },
    handleBillStatus(command, selectData, url) {
      let name = "";
      let caseStatus = "";
      let isStop = "";
      if (command === "反审核") {
        name = "反审核";
        caseStatus = "0";
      } else {
        if (command === "审核") {
          name = "审核";
          caseStatus = "2";
        } else if (command === "删除") {
          name = "删除";
          caseStatus = "3";
        } else if (command === "启用") {
          name = "启用";
          isStop = false;
        } else if (command === "禁用") {
          name = "禁用";
          isStop = true;
        } 
      }
      const upgradeCaseIds = selectData.map((i) => i.upgradeCaseId);
      const upgradeCaseNo = selectData.map((i) => i.upgradeCaseNo);
      const $this = this;
      this.$modal
        .confirm(`是否确认${name}方案编号为"` + upgradeCaseNo + '"的单据项?')
        .then(function () {
          if (['启用', '禁用'].includes(name)) {
            upgradeIsStopAPI({upgradeCaseIds: [...upgradeCaseIds],isStop: isStop} ).then(res => {
              $this.$nextTick(() => {
                $this.options.check = [];
              });
              setTimeout(() => {
                $this.$refs.tablePage.getList();
              }, 500);
              if (!isStop) {
                $this.$modal.msgSuccess('方案启用成功')
              } else {
                $this.$modal.msgSuccess('方案禁用成功')
              }
            })
          } else if (['审核', '反审核'].includes(name)) {
            const index = selectData.findIndex(y => !y.isStop)
            if (['反审核'].includes(name) && index != '-1') return $this.$modal.msgError('已启用不能反审核')
            upgradeUpdateStatusAPI({upgradeCaseIds: [...upgradeCaseIds], caseStatus: caseStatus} ).then(res => {
              $this.$nextTick(() => {
                $this.options.check = [];
              });
              setTimeout(() => {
                $this.$refs.tablePage.getList();
              }, 500);
              $this.$modal.msgSuccess(`${name}成功`);
            })
          } else {
            const index = selectData.findIndex(y => !y.isStop)
            if (index != '-1') return $this.$modal.msgError('只能删除：已禁用的状态！')
            upgradeDeleteAPI([...upgradeCaseIds]).then(res => {
              $this.$nextTick(() => {
                $this.options.check = [];
              });
              setTimeout(() => {
                $this.$refs.tablePage.getList();
              }, 500);
              $this.$modal.msgSuccess(`${name}成功`);
            })
          }
        });
    },
  }
}
</script>
