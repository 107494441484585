var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c(
        "cardTitleCom",
        { attrs: { cardTitle: "模板设置" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c("div", [_c("templateCom", { staticClass: "padd10" })], 1),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }