<template>
  <div class="wrap" v-loading="loading">
    <el-form ref="form" :model="joinGiftForm" :rules="rules" label-width="96px">
      <topOperatingButton
        :disabled="disabled"
        :showAudit="false"
        :isAuditBillBtn="false"
        :isSubmitAddBtn="false"
        :isExamineBtn="true"
        @submitForm="submitForm(false)"
        @addBill="addBill(true)"
        @auditBill="auditBill"
        @handleAdd="handleAdd"
        @getQuit="getQuit"
        id="topOperatingButton">
      </topOperatingButton>
      <!-- 顶部按钮 -->
      <!-- <div class="btnTOP">
        <el-button
          type="primary"
          size="mini"
          :disabled="disabled"
          @click="submitForm(true)"
          >保存
        </el-button>
        <el-button
          type="primary"
          size="mini"
          :disabled="disabled"
          @click="addBill"
          >保存并新增
        </el-button>
        <el-button
          @click="auditBill('审核')"
          icon="iconfont icon-a-weibiaoti-1_huaban1fuben28"
          size="mini"
          :disabled="showAudit"
          type="success"
          >审核</el-button
        >
        <el-button
          class="marL10"
          type="primary"
          icon="el-icon-plus"
          size="mini"
          v-show="showAdd"
          @click="handleAdd"
          >新增</el-button
        >
        <el-button size="small" @click="getQuit">退出</el-button>
      </div> -->
      <!-- 基本信息 -->
      <cardTitleCom
        cardTitle="基本信息"
        :auditOrder="auditOrder"
        :noAuditOrder="noAuditOrder"
        :stopOrder="stopOrder"
        :deleteOrder="deleteOrder"
        :disableOrder="disableOrder"
      >
        <template slot="cardContent">
          <div class="tableContent x-x">
            <el-form-item label="方案编号" prop="billNo">
              <el-input
                class="inputWidth marR15"
                :disabled="true"
                v-model="joinGiftForm.billNo"
                placeholder="方案编号"
                size="mini"
              ></el-input>
            </el-form-item>
            <el-form-item label="方案名称" prop="billName">
              <el-input
                class="inputWidth"
                :disabled="disabled"
                v-model="joinGiftForm.billName"
                placeholder="方案名称"
                size="mini"
              ></el-input>
            </el-form-item>
            <el-form-item label="有效时间" prop="begTime">
              <el-date-picker
                :disabled="disabled"
                size="mini"
                v-model="datetime"
                type="datetimerange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="备注" prop="billRemark">
              <el-input
                class="inputRemark"
                :disabled="disabled"
                v-model="joinGiftForm.billRemark"
                type="textarea"
                placeholder="备注长度介于 1 和 80 字符之间"
                maxlength="80"
                size="mini"
                :autosize="{ minRows: 1, maxRows: 5 }"
              />
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>

      <!-- 适用范围 -->
      <cardTitleCom cardTitle="适用范围">
        <template slot="cardContent">
          <div class="tableContent">
            <!-- 适用会员 -->
            <div>
              <el-form-item label="适用会员" prop="vipLevelRangeType">
                <el-radio-group
                  :disabled="disabled"
                  v-model="joinGiftForm.vipLevelRangeType"
                >
                  <el-radio
                    v-for="dict in dict.type.fill_vip_level_range_type"
                    :key="dict.value"
                    :label="parseInt(dict.value)"
                    >{{ dict.label }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
              <!-- 非全部会员 -->
              <div
                class="marL20"
                v-show="
                  joinGiftForm.vipLevelRangeType == 1 ||
                  joinGiftForm.vipLevelRangeType == 2
                "
              >
                <!-- <el-button
                  size="mini"
                  class="marB10"
                  @click="openVipLeve = true"
                  >选择会员级别</el-button
                > -->
                <el-button
                  size="mini"
                  class="marB10"
                  @click="handleEvent('openVipLevelDialog', {curTable: 'vipLevelItem'})"
                  >选择会员级别</el-button
                >
                <el-table
                  style="width: 100%"
                  tooltip-effect="dark"
                  :data="joinGiftForm.vipLevelItem"
                  border
                  max-height="500"
                >
                  <el-table-column
                    label="序号"
                    align="center"
                    prop="userId"
                    width="80"
                    type="index"
                  />
                  <el-table-column
                    align="center"
                    label="操作"
                    width="120"
                    v-if="!disabled"
                  >
                    <template v-slot="scope">
                      <el-button
                        @click.native.prevent="
                          deleteRow(scope.$index, joinGiftForm.vipLevelItem)
                        "
                        type="text"
                        size="mini"
                      >
                        <i class="el-icon-remove operateDel" />
                      </el-button>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="会员级别编号"
                    width="200"
                    align="center"
                    prop="vipLevelNo"
                  />
                  <el-table-column
                    label="会员级别名称"
                    align="center"
                    width="200"
                    prop="vipLevelName"
                  />
                  <el-table-column />
                </el-table>
              </div>
            </div>
            <!-- 实用门店 -->
            <div>
              <el-form-item label="适用门店" prop="shopRangeType">
                <el-radio-group
                  :disabled="disabled"
                  v-model="joinGiftForm.shopRangeType"
                >
                  <el-radio
                    v-for="dict in dict.type.fill_shop_range_type"
                    :key="dict.value"
                    :label="parseInt(dict.value)"
                    >{{ dict.label }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
              <!-- 非全部门店 -->
              <div
                class="marL20"
                v-show="
                  joinGiftForm.shopRangeType == 1 ||
                  joinGiftForm.shopRangeType == 2
                "
              >
                <!-- <el-button
                  :disabled="disabled"
                  size="mini"
                  class="marB10"
                  @click="openShopInfo = true"
                  >选择门店</el-button
                > -->
                <el-button
                  :disabled="disabled"
                  size="mini"
                  class="marB10"
                  @click="handleEvent('openShopDialog', { curTable: 'shopItem' })"
                  >选择门店</el-button
                >
                <el-table
                  style="width: 100%"
                  tooltip-effect="dark"
                  :data="joinGiftForm.shopItem"
                  border
                  max-height="500"
                >
                  <el-table-column
                    label="序号"
                    align="center"
                    prop="userId"
                    width="80"
                    type="index"
                  />
                  <el-table-column
                    align="center"
                    label="操作"
                    width="120"
                    v-if="!disabled"
                  >
                    <template v-slot="scope">
                      <el-button
                        @click.native.prevent="
                          deleteRow(scope.$index, joinGiftForm.shopItem)
                        "
                        type="text"
                        size="mini"
                      >
                        <i class="el-icon-remove operateDel" />
                      </el-button>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="门店编号"
                    width="200"
                    align="center"
                    prop="shopNo"
                  />
                  <el-table-column
                    label="门店名称"
                    align="center"
                    width="200"
                    prop="shopName"
                  />
                  <el-table-column />
                </el-table>
              </div>
            </div>
            <!-- 适用渠道 -->
            <el-form-item label="适用渠道">
              <el-checkbox
                :disabled="disabled"
                v-model="joinGiftForm.isAllowOfflineShop"
                >线下门店</el-checkbox
              >
              <el-checkbox
                :disabled="disabled"
                v-model="joinGiftForm.isAllowOnlineShop"
                >线上商城</el-checkbox
              >
              <el-checkbox
                :disabled="disabled"
                v-model="joinGiftForm.isAllowOfflineHQ"
                >总部</el-checkbox
              >
              <el-checkbox
                :disabled="disabled"
                v-model="joinGiftForm.isAllowOnlineWxCard"
                >微信会员卡</el-checkbox
              >
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>

      <!-- 奖励设置 -->
      <cardTitleCom cardTitle="奖励设置">
        <template slot="cardContent">
          <div class="tableContent padL20">
            <div>
              <span class="fontS14">新会员奖励 </span>
              <span class="prompt">（新用户注册成为会员，将获得以下奖励）</span>
            </div>
            <!-- 赠送积分 -->
            <div class="marT20 rewardPoints">
              <el-checkbox
                :disabled="disabled"
                v-model="joinGiftForm.isNewJoinGiftScore"
                >赠送积分</el-checkbox
              >
              <el-input
                :disabled="disabled"
                class="inputWidth marL15 marR15"
                v-model="joinGiftForm.newJoinGiftScore"
                v-show="joinGiftForm.isNewJoinGiftScore"
                size="mini"
              ></el-input>
              <span v-show="joinGiftForm.isNewJoinGiftScore">分</span>
            </div>
            <!-- 赠送优惠券 -->
            <div class="marT20">
              <el-checkbox
                :disabled="disabled"
                v-model="joinGiftForm.isNewJoinGiftCoupon"
                >赠送优惠券</el-checkbox
              >
              <!-- 选择赠送优惠券 -->
              <div class="marL20" v-show="joinGiftForm.isNewJoinGiftCoupon">
                <el-button
                  :disabled="disabled"
                  size="mini"
                  class="marB10 marT10"
                  @click="giveCoupons('newVip')"
                  >赠送优惠券</el-button
                >
                <el-table
                  style="width: 100%"
                  tooltip-effect="dark"
                  :data="joinGiftForm.newCouponItem"
                  border
                  max-height="500"
                >
                  <el-table-column
                    label="序号"
                    align="center"
                    prop="userId"
                    width="80"
                    type="index"
                  />
                  <el-table-column
                    align="center"
                    label="操作"
                    width="120"
                    v-if="!disabled"
                  >
                    <template v-slot="scope">
                      <el-button
                        @click.native.prevent="
                          deleteRow(scope.$index, joinGiftForm.newCouponItem)
                        "
                        type="text"
                        size="mini"
                      >
                        <i class="el-icon-remove operateDel" />
                      </el-button>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="优惠券名称"
                    width="200"
                    align="center"
                    prop="couponCaseName"
                  />
                  <el-table-column
                    label="赠送数量(单个会员)"
                    align="center"
                    width="200"
                    prop="couponEmitQty"
                  >
                    <!-- <template v-slot="scope">
                      {{ couponDrawModesData[scope.row.couponEmitMode] }}
                      {{ scope.row.couponEveryEmitQty }}张
                    </template> -->
                  </el-table-column>
                  <el-table-column
                    label="赠送方式"
                    align="center"
                    prop="couponEmitModeName"
                    show-overflow-tooltip
                  />
                  <el-table-column
                    label="生效方式"
                    align="center"
                    prop="couponEffectModeName"
                    show-overflow-tooltip
                  />
                  <el-table-column />
                </el-table>
              </div>
            </div>
            <div class="marT20">
              <span class="fontS14">推荐新人奖励 </span>
              <span class="prompt">（新用户注册成为会员，将获得以下奖励）</span>
            </div>
            <!-- 赠送积分 -->
            <div class="marT20 rewardPoints">
              <el-checkbox
                :disabled="disabled"
                v-model="joinGiftForm.isRecommendJoinGiftScore"
                >赠送积分</el-checkbox
              >
              <el-input
                :disabled="disabled"
                class="inputWidth marL15 marR15"
                v-model="joinGiftForm.recommendJoinGiftScore"
                v-show="joinGiftForm.isRecommendJoinGiftScore"
                size="mini"
              ></el-input>
              <span v-show="joinGiftForm.isRecommendJoinGiftScore">分</span>
            </div>
            <!-- 赠送优惠券 -->
            <div class="marT20">
              <el-checkbox
                :disabled="disabled"
                v-model="joinGiftForm.isRecommendJoinGiftCoupon"
                >赠送优惠券</el-checkbox
              >
              <!-- 选择赠送优惠券 -->
              <div
                class="marL20"
                v-show="joinGiftForm.isRecommendJoinGiftCoupon"
              >
                <el-button
                  :disabled="disabled"
                  size="mini"
                  class="marB10 marT10"
                  @click="giveCoupons('recommendVip')"
                  >赠送优惠券</el-button
                >
                <el-table
                  style="width: 100%"
                  tooltip-effect="dark"
                  :data="joinGiftForm.recommendCouponItem"
                  border
                  max-height="500"
                >
                  <el-table-column
                    label="序号"
                    align="center"
                    prop="userId"
                    width="80"
                    type="index"
                  />
                  <el-table-column
                    align="center"
                    label="操作"
                    width="120"
                    v-if="!disabled"
                  >
                    <template v-slot="scope">
                      <el-button
                        @click.native.prevent="
                          deleteRow(
                            scope.$index,
                            joinGiftForm.recommendCouponItem
                          )
                        "
                        type="text"
                        size="mini"
                      >
                        <i class="el-icon-remove operateDel" />
                      </el-button>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="优惠券名称"
                    width="200"
                    align="center"
                    prop="couponCaseName"
                  />
                  <el-table-column
                    label="赠送数量(单个会员)"
                    align="center"
                    width="200"
                    prop="couponEveryEmitQty"
                  >
                    <template v-slot="scope">
                      {{ couponDrawModesData[scope.row.couponEmitMode] }}
                      {{ scope.row.couponEveryEmitQty }}张
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="赠送方式"
                    align="center"
                    prop="couponEmitModeName"
                    show-overflow-tooltip
                  />
                  <el-table-column
                    label="生效方式"
                    align="center"
                    prop="couponEffectModeName"
                    show-overflow-tooltip
                  />
                  <el-table-column />
                </el-table>
              </div>
            </div>
          </div>
        </template>
      </cardTitleCom>
    </el-form>
    <!-- 选择优惠券对话框 -->
    <selectCoupon
      :OpenCoupon.sync="openCoupon"
      :isRadio="true"
      :isSuingEditorIsRadio="true"
      @getCoupons="getPitchData($event, 'coupon')"
    />
    <!-- 会员等级 -->
    <vipLeve :OpenVipLeve.sync="openVipLeve" @vipLeveList="vipLeveListCom" />
    <!-- 门店 -->
    <shopInfo
      :OpenShopInfo.sync="openShopInfo"
      @shopInfoList="shopInfoListCom"
    />
    <Dialog
        ref="tablePage"
        :options.sync="dialogOptions"
        @handleEvent="handleEvent"
    />
  </div>
</template>

<script>
import { uniqWith } from 'lodash' //去重
import Dialog from '@/components/Dialog'
import topOperatingButton from '@/views/components/topOperatingButton' //顶部按钮
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import selectCoupon from '@/views/components/selectCoupon' //优惠券组件
import vipLeve from '@/views/components/vipLeve' //会员等级
import shopInfo from '@/views/components/shopInfo' //门店
import { getBillNo } from '@/api/codeRule' //方案号
import {
  joinGiftAddAPI,
  joinGiftDetailAPI,
  joinGiftUpdateAPI,
  joinGiftIsStopAPI,
  joinGiftUpdateStatusAPI
} from '@/api/marketing/vipMarketing/joinGift' //会员入会有礼
export default {
  name: 'joinGiftDetail',
  dicts: ['fill_vip_level_range_type', 'fill_shop_range_type'],
  components: { Dialog, cardTitleCom, topOperatingButton, selectCoupon, vipLeve, shopInfo },
  data () {
    return {
      couponDrawModesData: {
          0: '每天',
          1: '每周',
          2: '每月',
          3: '总共'
      },
      curTable: '',
      //弹窗配置
      dialogOptions: {
          curType: '', //当前类型(门店/会员/商品/类别/品牌)
          title: '选择商品',
          width: 1250,
          show: false,
          type: 'TreeAndTable'
      },
      //门店弹窗开关
      openShopInfo: false,
      //选择会员级别开关
      openVipLeve: false,
      //优惠券的坐标
      couponIndex: undefined,
      //选择优惠券开关
      openCoupon: false,
      //单据时间
      datetime: undefined,
      //标识已审核
      auditOrder: false,
      //标识未审核
      noAuditOrder: false,
      //标识已关闭
      stopOrder: false,
      //标识已删除
      deleteOrder: false,
      //标识已禁用
      disableOrder: false,
      showAudit: true, //审核按钮禁用状态
      showAdd: false, //新增按钮
      disabled: false, //保存禁用
      openGoods: false, //选择商品信息开关
      queryCode: undefined, //传送商品参数
      loadingTable: false, //表格遮罩
      loading: false, //大盒子遮罩层
      //旧的表单
      oldForm: {},
      // 表单参数
      joinGiftForm: {
        billStatus: '',
        vipLevelRangeType: 0, //适用会员
        shopRangeType: 0, //适用门店
        isAllowOfflineShop: true, //线下门店
        isAllowOnlineShop: true, //线上商城
        isAllowOnlineWxCard: true, //线下总部
        isAllowOfflineHQ: true, //线上微信会员卡
        vipLevelItem: [], //适用会员
        shopItem: [], //适用门店
        newCouponItem: [], //新人送券
        recommendCouponItem: [] //推荐送券
      },
      // 表单校验
      rules: {
        billNo: [
          {
            required: true,
            message: '请到编码规则配置单据类型',
            trigger: ['blur', 'change']
          }
        ],
        billName: [
          {
            required: true,
            message: '方案名称不能为空',
            trigger: ['blur', 'change']
          }
        ],
        begTime: [
          {
            required: true,
            message: '有效时间不能为空',
            trigger: ['blur', 'change']
          }
        ],
        birthdayBeforeDay: [
          {
            required: true,
            message: '推送时间不能为空',
            trigger: ['blur', 'change']
          }
        ],
        vipLevelRangeType: [
          {
            required: true,
            message: '适用会员不能为空',
            trigger: ['blur', 'change']
          }
        ],
        shopRangeType: [
          {
            required: true,
            message: '适用门店不能为空',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  watch: {
    //侦听单据状态
    'joinGiftForm.billStatus': {
      handler (newVal) {
        if (this.joinGiftForm.billStatus == '2') {
          //新增按钮
          this.showAdd = true
          //打开审核图标
          this.auditOrder = true
          //禁用输入按钮、保存按钮
          this.disabled = true
          //打开审核按钮
          this.showAudit = false
          //关闭已禁用图标
          this.disableOrder = false
          //关闭关闭图标
          this.stopOrder = false
          //关闭已删除图标
          this.deleteOrder = false
          //关闭未审核图标
          this.noAuditOrder = false
        } else if (this.joinGiftForm.billStatus == '3') {
          //新增按钮
          this.showAdd = false
          //打开已删除图标
          this.deleteOrder = true
          //关闭已禁用图标
          this.disableOrder = false
          //关闭关闭图标
          this.stopOrder = false
          //关闭审核图标
          this.auditOrder = false
          //关闭未审核图标
          this.noAuditOrder = false
          //禁用审核按钮
          this.showAudit = true
          //禁止全部输入按钮
          this.disabled = true
        } else if (this.joinGiftForm.billStatus == '0') {
          //新增按钮
          this.showAdd = false
          //未审核图标
          this.noAuditOrder = true
          //禁用审核按钮
          this.showAudit = false
          //关闭审核图标
          this.auditOrder = false
          //关闭已禁用图标
          this.disableOrder = false
          //关闭关闭图标
          this.stopOrder = false
          //关闭已删除图标
          this.deleteOrder = false
          //恢复全部输入
          this.disabled = false
        } else if (this.joinGiftForm.billStatus == '4') {
          //新增按钮
          this.showAdd = false
          //打开关闭图标
          this.stopOrder = true
          //未审核图标
          this.noAuditOrder = false
          //禁用审核按钮
          this.showAudit = false
          //关闭审核图标
          this.auditOrder = false
          //关闭已禁用图标
          this.disableOrder = false
          //关闭已删除图标
          this.deleteOrder = false
          //恢复全部输入
          this.disabled = false
        } else if (this.joinGiftForm.billStatus == '') {
          //引入源单按钮
          this.showImportBill = true
          //新增按钮
          this.showAdd = false
          //关闭已禁用图标
          this.disableOrder = false
          //关闭关闭图标
          this.stopOrder = false
          //关闭已删除图标
          this.deleteOrder = false
          //关闭审核图标
          this.auditOrder = false
          //关闭未审核图标
          this.noAuditOrder = false
          //禁用审核按钮
          this.showAudit = true
          //恢复全部输入
          this.disabled = false
        }
      },
      immediate: true
    },
    //单据日期
    datetime (newVal) {
      if (newVal) {
        this.joinGiftForm.begTime = newVal[0]
        this.joinGiftForm.endTime = newVal[1]
        console.log(this.joinGiftForm)
      } else {
        this.joinGiftForm.begTime = undefined
        this.joinGiftForm.endTime = undefined
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(async vm => {
      vm.loading = true
      // 重置数据
      vm.reset()
      // 访问组件实例 vm 中的数据和方法
      if (vm.$route.query.type === 'Update') {
        if (vm.$route.query.billId) {
          console.log(vm.$route.query.billId)
          //获取方案详情
          const { data } = await joinGiftDetailAPI(vm.$route.query.billId)
          //方案赋值
          vm.joinGiftForm = data
          vm.dateAssembly(vm.joinGiftForm.begTime, vm.joinGiftForm.endTime)
          //新人券拼装
          vm.updateCouponItems(vm.joinGiftForm.newCouponItem)
          //推荐券拼装
          vm.updateCouponItems(vm.joinGiftForm.recommendCouponItem)
          //原始方案赋值
          vm.oldForm = JSON.parse(JSON.stringify(vm.joinGiftForm))
        }
      } else {
        await vm.getOrderBillNo()
        //判断是否有以前新增时保存的数据
        if (JSON.stringify(vm.oldForm) != '{}') {
          vm.joinGiftForm = vm.oldForm
        }
        vm.oldForm = JSON.parse(JSON.stringify(vm.joinGiftForm))
      }
      vm.loading = false
    })
  },
  async beforeRouteLeave (to, from, next) {
    // 在用户离开二级路由前弹出询问框
    if (this.joinGiftForm.billId) {
      this.oldForm = {}
    } else {
      this.oldForm = JSON.parse(JSON.stringify(this.joinGiftForm))
    }
    next()
  },
  methods: {
    async handleEvent (type, row) {
            if (row?.curTable) this.curTable = row.curTable
                console.log(this.curTable, 'this.curTable')
            switch (type) {
                case 'openCouponDialog':
                  //弹窗配置
                  this.dialogOptions = {
                    curTable: this.couponIndex == 'newVip'? 'newCouponItem' : 'recommendCouponItem',
                    curType: 'couponCaseId', //当前类型(会员/门店/优惠券)
                    title: '选择优惠券',
                    width: 1250,
                    show: true,
                    type: 'TreeAndTable',
                    isStep: true, //是否允许下一步(主要用于优惠券)
                    // formData: this.$dialog({ key: 'coupon' })
                    formData: this.$dialog({ key: 'coupon', option:{ queryParams: { isStop: 0, isInvalids: [0, 1]  } }})
                  }
                  this.dialogOptions.formData.table.radioSelect = true
                  this.dialogOptions.formData.table.mutiSelect = false
                break
                case 'openShopDialog':
                    this.dialogOptions = {
                        curType: 'shopId',
                        title: '选择门店',
                        width: 1250,
                        show: true,
                        type: 'TreeAndTable',
                        formData: this.$dialog({ key: 'shop' })
                    }
                break
                case 'openVipLevelDialog':
                    this.dialogOptions = {
                        curType: 'vipLevelId',
                        title: '选择会员级别',
                        width: 1250,
                        show: true,
                        type: 'TreeAndTable',
                        formData: this.$dialog({ key: 'vipLevel' })
                    }
                    break
                //适用时间回显
                    break
                case 'dialogChange':
                    let list = []
                    let dialogType = this.dialogOptions.curType
                    if (dialogType != 'timeDate') {
                        if (this.dialogOptions.formData.table.check.length <= 0) return
                        list = this.dialogOptions.formData.table.check
                        console.log(dialogType, 'dialogType')
                        console.log(list, 'list')
                        console.log(this.curTable, 'this.curTable')
                        if (['vipLevelId'].includes(dialogType)) {
                            list = [...list, ...this.joinGiftForm.vipLevelItem]
                            list = uniqWith(list, (x, y) => x.vipLevelId == y.vipLevelId)
                            this.joinGiftForm.vipLevelItem = list
                        }
                        if (['shopId'].includes(dialogType)) {
                            list = [...list, ...this.joinGiftForm.vipLevelItem]
                            list = uniqWith(list, (x, y) => x.shopId == y.shopId)
                            this.joinGiftForm.shopItem = list
                        }
                        if (dialogType =='couponCaseId') {
                          let curType = this.dialogOptions.curType
                          let arr = []
                          if (this.couponIndex === 'newVip') {
                            arr = this.joinGiftForm.newCouponItem
                          } else {
                            arr = this.joinGiftForm.recommendCouponItem
                          }
                          const couponDrawModes = {
                              0: '每天',
                              1: '每周',
                              2: '每月',
                              3: '总共'
                          }
                          list = list.map(item => ({
                              ...item,
                              // couponEmitMode: item.couponDrawMode,
                              // couponDrawModeName: `${couponDrawModes[item.couponEmitMode]}${item.couponEveryDrawQty}张`,
                              // couponEveryEmitQty: item.couponEveryDrawQty,
                              largessCouponCaseId: item.largessCouponCaseId? item.largessCouponCaseId : item.couponCaseId
                          }))
                          list = [...list, ...arr]
                          list = uniqWith(list, (x, y) => x.largessCouponCaseId == y.largessCouponCaseId)
                          
                          console.log(list, 'list')

                          if (this.couponIndex === 'newVip') {
                            this.joinGiftForm.newCouponItem = list
                          } else {
                            this.joinGiftForm.recommendCouponItem = list
                          }
                        }
                    }
                    // if (['vipId', 'vipLevelId'].includes(dialogType)) {

                    //   this.joinGiftForm[this.curTable] = list
                    // }
                    break
                default:
                break
            }
        },
    //判断打开那个优惠券的弹窗
    // giveCoupons (value) {
    //   //赋值下标
    //   this.couponIndex = value
    //   //弹窗
    //   this.openCoupon = true
    // },
    giveCoupons (value) {
      //赋值下标
      this.couponIndex = value
      //弹窗
      this.handleEvent('openCouponDialog')
    },
    //组装日期
    dateAssembly (begTime, endTime) {
      this.datetime = [begTime, endTime]
    },
    //保存并新增方案
    async addBill () {
      this.$confirm('是否保存并新增方案?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success'
      })
        .then(async () => {
          this.loading = true
          this.submitForm(true)
          
          this.loading = false
        })
        .catch()
    },
    //审核/删除/终止事件
    async auditBill (name) {
      if (this.joinGiftForm.billStatus != '0') {
        this.$message.error('此单据不是未审核的状态哦~')
        return
      }
      if (name === '审核') {
        if (this.joinGiftForm.billStatus != '0') {
          this.$message.error('此单据不是未审核的状态哦~')
          return
        }
        let valid = await this.submitForm()
        if (!valid) return
        const obj = {
          billIds: [this.joinGiftForm.billId],
          billStatus: '2'
        }
        //发送审核api
        await joinGiftUpdateStatusAPI(obj)
        // this.joinGiftForm = res1.data
        this.$confirm(`此单据已审核，是否要启用?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        }).then(() => {
          console.log(this.joinGiftForm, '1111')
          const obj = {
            billIds: [this.joinGiftForm.billId],
            isStop: false
          }
          const res2 = joinGiftIsStopAPI(obj)
          this.joinGiftForm = res2.data
          // 弹出提示
          this.$message.success('审核并启用成功')
        }).catch(() => {
          console.log(this.joinGiftForm, '5222')
            // this.$message.success('审核成功')
        })
      }
      if (this.joinGiftForm.couponItem > 0) {
        this.joinGiftForm.couponItem.forEach((item, index) => {
          //组装数据
          let assemblyCouponObj = this.assemblyCoupon(item)
          this.$set(this.joinGiftForm.couponItem, index, ...assemblyCouponObj)
        })
      }
    },
    /** 提交按钮 */
    async submitForm (isBool) {
      console.log(isBool, 'isBool');
      let valid = await this.$refs['form'].validate()
      // this.$refs['form'].validate(async valid => {
        if (valid) {
          //适用会员
          if (
            (this.joinGiftForm.vipLevelRangeType == 1 ||
              this.joinGiftForm.vipLevelRangeType == 2) &&
            this.joinGiftForm.vipLevelItem.length <= 0
          ) {
            this.$message.error('适用会员不能为空')
            return false
          }
          //适用门店
          if (
            (this.joinGiftForm.shopRangeType == 1 ||
              this.joinGiftForm.shopRangeType == 2) &&
            this.joinGiftForm.shopItem.length <= 0
          ) {
            this.$message.error('适用门店不能为空')
            return false
          }
          //适用渠道
          if (
            !this.joinGiftForm.isAllowOfflineShop &&
            !this.joinGiftForm.isAllowOnlineShop &&
            !this.joinGiftForm.isAllowOfflineHQ &&
            !this.joinGiftForm.isAllowOnlineWxCard 
          ) {
            this.$message.error('适用渠道不能为空')
            return false
          }
          if (this.joinGiftForm.billId) {
            const res = await joinGiftUpdateAPI(this.joinGiftForm)
            //重新赋值给表单，可以把id赋值
            if (isBool) {
              this.joinGiftForm = res.data
              //新人券拼装
              this.updateCouponItems(this.joinGiftForm.newCouponItem)
              //推荐券拼装
              this.updateCouponItems(this.joinGiftForm.recommendCouponItem)

              this.$modal.msgSuccess('修改方案成功')
            } else {
              this.joinGiftForm = res.data
              //新人券拼装
              this.updateCouponItems(this.joinGiftForm.newCouponItem)
              //推荐券拼装
              this.updateCouponItems(this.joinGiftForm.recommendCouponItem)
              this.$modal.msgSuccess('保存并新增方案成功')
            }
            return true
          } else {
            const { data } = await joinGiftAddAPI(this.joinGiftForm)
            //重新赋值给表单，可以把id赋值
            if (isBool) {
              this.joinGiftForm = data
              //新人券拼装
              this.updateCouponItems(this.joinGiftForm.newCouponItem)
              //推荐券拼装
              this.updateCouponItems(this.joinGiftForm.recommendCouponItem)
              this.reset()
              this.getOrderBillNo()
              this.$modal.msgSuccess('新增方案成功')
            } else {
              this.joinGiftForm = data
              //新人券拼装
              this.updateCouponItems(this.joinGiftForm.newCouponItem)
              //推荐券拼装
              this.updateCouponItems(this.joinGiftForm.recommendCouponItem)
              this.$modal.msgSuccess('保存并修改方案成功')
            }
            return true
          }
        }
      // })
    },
    //拼装优惠券数据
    updateCouponItems (items) {
      if (items.length === 0) return
      items.forEach((item, index) => {
        //组装数据
        let assemblyCouponObj = this.assemblyCoupon(item)
        this.$set(items, index, assemblyCouponObj)
      })
    },
    //门店确定
    shopInfoListCom (value) {
      if (value.length == 0) return
      //去重
      value = value.filter(item => {
        return this.joinGiftForm.shopItem.every(v => v.shopId != item.shopId)
      })
      if (value.length == 0) return
      this.joinGiftForm.shopItem.push(...value)
      this.joinGiftForm.shopItem = this.joinGiftForm.shopItem.filter(
        item =>
          (!(JSON.stringify(item) === '{}') && !(item.shopId === undefined)) ||
          !(item.shopId === undefined)
      )
    },
    //会员级别确定键
    vipLeveListCom (value) {
      if (value.length == 0) return
      //去重
      value = value.filter(item => {
        return this.joinGiftForm.vipLevelItem.every(
          v => v.vipLevelId != item.vipLevelId
        )
      })
      if (value.length == 0) return
      this.joinGiftForm.vipLevelItem.push(...value)
      this.joinGiftForm.vipLevelItem = this.joinGiftForm.vipLevelItem.filter(
        item =>
          (!(JSON.stringify(item) === '{}') &&
            !(item.vipLevelId === undefined)) ||
          !(item.vipLevelId === undefined)
      )
    },
    //打开优惠券
    getChooseCoupon () {
      this.openCoupon = true
    },
    //优惠券确定键
    getPitchData (value) {
      if (value.length === 0) return
      let couponIndex = this.couponIndex
      let couponList =
        couponIndex === 'newVip'
          ? this.joinGiftForm.newCouponItem
          : this.joinGiftForm.recommendCouponItem
      value = value.filter(item => {
        return couponList.every(
          v => v.largessCouponCaseId !== item.couponCaseId
        )
      })
      if (value.length === 0) return
      const assemblyCouponObj = this.assemblyCoupon(value[0])
      value.forEach(v => {
        const obj = {
          largessCouponCaseId: v.couponCaseId,
          couponCaseName: v.couponCaseName,
          couponEmitQty: v.couponEmitQty,
          ...assemblyCouponObj
        }
        couponList.push(obj)
      })
      couponList = couponList.filter(
        item =>
          (!(JSON.stringify(item) === '{}') &&
            !(item.largessCouponCaseId === undefined)) ||
          !(item.largessCouponCaseId === undefined)
      )
    },

    //组装优惠券数据
    assemblyCoupon (value) {
      console.log(value, 'values')
      //发放方式
      const couponEmitModes = {
        0: '即时发放',
        1: '按天分期发放',
        2: '按周分期发放',
        3: '按月分期发放'
      }
      const {
        couponEmitMode,
        couponEveryEmitQty,
        couponEmitTimes,
        couponEmitInterval
      } = value
      let couponEmitModeName = couponEmitModes[couponEmitMode]
      if ([1, 2, 3].includes(couponEmitMode)) {
        const timeUnit =
          couponEmitMode === 1 ? '天' : couponEmitMode === 2 ? '周' : '月'
        couponEmitModeName += `,每次发放数量：${couponEveryEmitQty},分期次数：${couponEmitTimes},每次间隔：${couponEmitInterval}${timeUnit}`
      }
      //生效方式
      const couponEffectModes = {
        0: '即时生效， ',
        1: '延迟生效， ',
        2: '固定日期生效， '
      }
      const {
        couponEffectMode,
        couponEffectDays,
        couponEffectDate,
        couponValidDays
      } = value
      let couponEffectModeName = couponEffectModes[couponEffectMode]
      if (couponEffectMode === 0) {
        couponEffectModeName += `生效后有效时长${couponValidDays}天`
      } else if (couponEffectMode === 1) {
        couponEffectModeName += `领取${couponEffectDays}天后生效,生效后有效时长${couponValidDays}天`
      } else if (couponEffectMode === 2) {
        couponEffectModeName += `${couponEffectDate},生效后有效时长${couponValidDays}天`
      }
      value.couponEmitModeName = couponEmitModeName
      value.couponEffectModeName = couponEffectModeName
      return value
    },
    //方案编号
    async getOrderBillNo () {
      this.joinGiftForm.billNo = await getBillNo(160303)
    },
    //删除会员、优惠券内容
    deleteRow (index, rows) {
      rows.splice(index, 1)
    },
    // 表单重置
    reset () {
      //新增按钮
      this.showAdd = false
      //关闭已删除图标
      this.deleteOrder = false
      //关闭审核图标
      this.auditOrder = false
      //关闭未审核图标
      this.noAuditOrder = false
      //关闭关闭图标
      this.stopOrder = false
      //关闭已禁用图标
      this.disableOrder = false
      //保存禁用
      this.disabled = false
      this.joinGiftForm = {
        billStatus: '',
        vipLevelRangeType: 0, //适用会员
        shopRangeType: 0, //适用门店
        isAllowOfflineShop: true, //线下门店
        isAllowOnlineShop: true, //线上商城
        isAllowOnlineWxCard: true, //线下总部
        isAllowOfflineHQ: true, //线上微信会员卡
        vipLevelItem: [], //适用会员
        shopItem: [], //适用门店
        newCouponItem: [], //新人送券
        recommendCouponItem: [] //推荐送券
      }
      this.datetime = []
      this.resetForm('form')
    },
    //新增按钮
    async handleAdd () {
      this.loading = true
      this.reset()
      await this.getOrderBillNo()
      this.loading = false
    },
    //退出
    getQuit () {
      this.$store.state.tagsView.visitedViews.splice(
        this.$store.state.tagsView.visitedViews.findIndex(
          item => item.path === this.$route.path
        ),
        1
      )
      this.$destroy()
      this.$router.push('/marketing/vipMarketing/joinGift')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-radio, .el-checkbox {
  color: #333;
}
.wrap {
  background-color: #eaeaea;
  padding: 40px 10px 6px 10px;
  min-height: 100vh;
  // 顶部按钮
  .btnTOP {
    text-align: right;
    padding: 0 10px 6px 0;
  }
  .tableContent {
    margin: 15px 10px 20px 10px;
    //提示
    .prompt {
      font-size: 14px;
      color: #a2a2a2;
    }
  }
  //赠送积分
  .rewardPoints {
    height: 40px;
    line-height: 40px;
  }
  //内部表单
  ::v-deep .el-form-item {
    padding: 0;
    margin: 0;
  }
  //阿里图标
  ::v-deep .iconfont {
    font-size: 12px;
  }
  //单据日期
  ::v-deep .el-date-editor--datetimerange.el-input,
  .el-date-editor--datetimerange.el-input__inner {
    width: 355px !important;
  }
  // /* 单选框选中后的字体颜色 */
  ::v-deep .el-radio__input.is-checked + .el-radio__label {
    color: #333333 !important;
  }
  /* 复选框选中后的字体颜色 */
  ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #333333 !important;
  }
  ::v-deep .el-input--medium .el-input__inner {
    height: 28px;
    line-height: 28px;
  }
}
</style>
