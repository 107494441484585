var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap",
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.upgradeForm,
            rules: _vm.rules,
            "label-width": "125px",
          },
        },
        [
          _c("topOperatingButton", {
            attrs: {
              disabled: _vm.disabled,
              showAudit: _vm.showAudit,
              isAddBtn: _vm.showAdd,
              isExamineBtn: true,
              id: "topOperatingButton",
            },
            on: {
              submitForm: function ($event) {
                return _vm.submitForm(false)
              },
              addBill: function ($event) {
                return _vm.addBill(true)
              },
              auditBill: _vm.auditBill,
              handleAdd: _vm.handleAdd,
              getQuit: _vm.getQuit,
            },
          }),
          _c(
            "cardTitleCom",
            {
              attrs: {
                cardTitle: "基本信息",
                auditOrder: _vm.auditOrder,
                noAuditOrder: _vm.noAuditOrder,
                stopOrder: _vm.stopOrder,
                deleteOrder: _vm.deleteOrder,
                disableOrder: _vm.disableOrder,
              },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticClass: "tableContent" }, [
                  _c(
                    "div",
                    { staticClass: "x-x marB10" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "方案编号", prop: "upgradeCaseNo" } },
                        [
                          _c("el-input", {
                            staticClass: "inputWidth",
                            attrs: {
                              disabled: true,
                              size: "mini",
                              placeholder: "方案编号",
                            },
                            model: {
                              value: _vm.upgradeForm.upgradeCaseNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.upgradeForm, "upgradeCaseNo", $$v)
                              },
                              expression: "upgradeForm.upgradeCaseNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "方案名称", prop: "upgradeCaseName" },
                        },
                        [
                          _c("el-input", {
                            staticClass: "inputWidth",
                            attrs: {
                              disabled: _vm.disabled,
                              placeholder: "方案名称",
                              maxlength: "40",
                              size: "mini",
                              clearable: "",
                            },
                            model: {
                              value: _vm.upgradeForm.upgradeCaseName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.upgradeForm,
                                  "upgradeCaseName",
                                  $$v
                                )
                              },
                              expression: "upgradeForm.upgradeCaseName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "方案类型", prop: "upgradeCaseType" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "inputWidth",
                              attrs: {
                                placeholder: "方案类型",
                                disabled: _vm.disabled,
                                size: "mini",
                                filterable: "",
                                clearable: "",
                              },
                              model: {
                                value: _vm.upgradeForm.upgradeCaseType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.upgradeForm,
                                    "upgradeCaseType",
                                    $$v
                                  )
                                },
                                expression: "upgradeForm.upgradeCaseType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "升级", value: 1 },
                              }),
                              _c("el-option", {
                                attrs: { label: "降级", value: 2 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "当前级别",
                            prop: "currentVipLevelId",
                          },
                        },
                        [
                          _c("SelectRemote", {
                            attrs: {
                              option: _vm.$select({
                                key: "viplevel",
                                option: {
                                  option: {
                                    disabled: _vm.disabled,
                                  },
                                },
                              }).option,
                            },
                            on: { selectChange: _vm.changeVipBrand },
                            model: {
                              value: _vm.upgradeForm.currentVipLevelId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.upgradeForm,
                                  "currentVipLevelId",
                                  $$v
                                )
                              },
                              expression: "upgradeForm.currentVipLevelId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "x-x" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "目标级别",
                            prop: "upgradeVipLevelId",
                          },
                        },
                        [
                          _c("SelectRemote", {
                            attrs: {
                              option: _vm.$select({
                                key: "viplevel",
                                option: {
                                  option: {
                                    disabled:
                                      _vm.disabled ||
                                      !_vm.upgradeForm.currentVipLevelId,
                                    remoteBody: { vipBrandId: _vm.vipBrandId },
                                  },
                                },
                              }).option,
                            },
                            model: {
                              value: _vm.upgradeForm.upgradeVipLevelId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.upgradeForm,
                                  "upgradeVipLevelId",
                                  $$v
                                )
                              },
                              expression: "upgradeForm.upgradeVipLevelId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "目标级别有效期",
                            prop: "validValue",
                            "label-width": "125px",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "inputWidth",
                            attrs: {
                              disabled: _vm.disabled,
                              size: "mini",
                              placeholder: "目标级别有效期",
                              clearable: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.valueBlur(11)
                              },
                            },
                            model: {
                              value: _vm.upgradeForm.validValue,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.upgradeForm,
                                  "validValue",
                                  _vm._n($$v)
                                )
                              },
                              expression: "upgradeForm.validValue",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "marT10 marL10 marR20",
                          attrs: { disabled: _vm.disabled },
                          model: {
                            value: _vm.upgradeForm.validType,
                            callback: function ($$v) {
                              _vm.$set(_vm.upgradeForm, "validType", $$v)
                            },
                            expression: "upgradeForm.validType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("日"),
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("周"),
                          ]),
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("月"),
                          ]),
                          _c("el-radio", { attrs: { label: 4 } }, [
                            _vm._v("年"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注" } },
                        [
                          _c("el-input", {
                            staticClass: "inputRemark marL10",
                            attrs: {
                              disabled: _vm.disabled,
                              size: "mini",
                              type: "textarea",
                              placeholder: "备注长度介于 1 和 80 字符之间",
                              maxlength: "80",
                              autosize: { minRows: 1, maxRows: 5 },
                            },
                            model: {
                              value: _vm.upgradeForm.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.upgradeForm, "remark", $$v)
                              },
                              expression: "upgradeForm.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            {
              attrs: {
                cardTitle: "升级规则设置",
                tooltipText:
                  "自成为当前级别会员之日起计算，满足规则所设条件时自动升级",
              },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticClass: "tableContent" }, [
                  _c(
                    "div",
                    { staticClass: "conditionSettings marB20" },
                    [
                      _c("span", { staticClass: "fontS14" }, [
                        _vm._v("条件设置"),
                      ]),
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "marL20",
                          attrs: { disabled: _vm.disabled },
                          model: {
                            value: _vm.upgradeForm.conditionType,
                            callback: function ($$v) {
                              _vm.$set(_vm.upgradeForm, "conditionType", $$v)
                            },
                            expression: "upgradeForm.conditionType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("满足所有条件"),
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("满足任意条件"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "condition y-c" }, [
                    _c("div", { staticClass: "x-fc marB20" }, [
                      _c(
                        "div",
                        { staticClass: "marR20" },
                        [
                          _c(
                            "el-checkbox",
                            {
                              staticClass: "inputW140",
                              attrs: { disabled: _vm.disabled },
                              model: {
                                value: _vm.upgradeForm.isUseAllScore,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.upgradeForm,
                                    "isUseAllScore",
                                    $$v
                                  )
                                },
                                expression: "upgradeForm.isUseAllScore",
                              },
                            },
                            [_vm._v("累计积分")]
                          ),
                          _c("el-input", {
                            staticClass: "inputWidth marL15 marR15",
                            attrs: { disabled: _vm.disabled, size: "mini" },
                            on: {
                              input: function ($event) {
                                return _vm.valueBlur(1)
                              },
                            },
                            model: {
                              value: _vm.upgradeForm.allScoreValue,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.upgradeForm,
                                  "allScoreValue",
                                  _vm._n($$v)
                                )
                              },
                              expression: "upgradeForm.allScoreValue",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "marL20" },
                        [
                          _c(
                            "el-checkbox",
                            {
                              staticClass: "inputW140",
                              attrs: { disabled: _vm.disabled },
                              model: {
                                value: _vm.upgradeForm.isUseThirtyDayConsume,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.upgradeForm,
                                    "isUseThirtyDayConsume",
                                    $$v
                                  )
                                },
                                expression: "upgradeForm.isUseThirtyDayConsume",
                              },
                            },
                            [_vm._v("30天内消费金额")]
                          ),
                          _c("el-input", {
                            staticClass: "inputWidth marL15 marR15",
                            attrs: { disabled: _vm.disabled, size: "mini" },
                            on: {
                              input: function ($event) {
                                return _vm.valueBlur(2)
                              },
                            },
                            model: {
                              value: _vm.upgradeForm.thirtyDayConsumeValue,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.upgradeForm,
                                  "thirtyDayConsumeValue",
                                  _vm._n($$v)
                                )
                              },
                              expression: "upgradeForm.thirtyDayConsumeValue",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "x-fc marB20" }, [
                      _c(
                        "div",
                        { staticClass: "marR20" },
                        [
                          _c(
                            "el-checkbox",
                            {
                              staticClass: "inputW140",
                              attrs: { disabled: _vm.disabled },
                              model: {
                                value: _vm.upgradeForm.isUseOneFill,
                                callback: function ($$v) {
                                  _vm.$set(_vm.upgradeForm, "isUseOneFill", $$v)
                                },
                                expression: "upgradeForm.isUseOneFill",
                              },
                            },
                            [_vm._v("单笔充值金额")]
                          ),
                          _c("el-input", {
                            staticClass: "inputWidth marL15 marR15",
                            attrs: { disabled: _vm.disabled, size: "mini" },
                            on: {
                              input: function ($event) {
                                return _vm.valueBlur(3)
                              },
                            },
                            model: {
                              value: _vm.upgradeForm.oneFillValue,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.upgradeForm,
                                  "oneFillValue",
                                  _vm._n($$v)
                                )
                              },
                              expression: "upgradeForm.oneFillValue",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "marL20" },
                        [
                          _c(
                            "el-checkbox",
                            {
                              staticClass: "inputW140",
                              attrs: { disabled: _vm.disabled },
                              model: {
                                value: _vm.upgradeForm.isUseOneYearConsume,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.upgradeForm,
                                    "isUseOneYearConsume",
                                    $$v
                                  )
                                },
                                expression: "upgradeForm.isUseOneYearConsume",
                              },
                            },
                            [_vm._v("一年内消费金额")]
                          ),
                          _c("el-input", {
                            staticClass: "inputWidth marL15 marR15",
                            attrs: { disabled: _vm.disabled, size: "mini" },
                            on: {
                              input: function ($event) {
                                return _vm.valueBlur(4)
                              },
                            },
                            model: {
                              value: _vm.upgradeForm.oneYearConsumeValue,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.upgradeForm,
                                  "oneYearConsumeValue",
                                  _vm._n($$v)
                                )
                              },
                              expression: "upgradeForm.oneYearConsumeValue",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "x-fc marB20" }, [
                      _c(
                        "div",
                        { staticClass: "marR20" },
                        [
                          _c(
                            "el-checkbox",
                            {
                              staticClass: "inputW140",
                              attrs: { disabled: _vm.disabled },
                              model: {
                                value: _vm.upgradeForm.isUseAllFill,
                                callback: function ($$v) {
                                  _vm.$set(_vm.upgradeForm, "isUseAllFill", $$v)
                                },
                                expression: "upgradeForm.isUseAllFill",
                              },
                            },
                            [_vm._v("累计充值金额")]
                          ),
                          _c("el-input", {
                            staticClass: "inputWidth marL15 marR15",
                            attrs: { disabled: _vm.disabled, size: "mini" },
                            on: {
                              input: function ($event) {
                                return _vm.valueBlur(5)
                              },
                            },
                            model: {
                              value: _vm.upgradeForm.allFillValue,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.upgradeForm,
                                  "allFillValue",
                                  _vm._n($$v)
                                )
                              },
                              expression: "upgradeForm.allFillValue",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "marL20" },
                        [
                          _c(
                            "el-checkbox",
                            {
                              staticClass: "inputW140",
                              attrs: { disabled: _vm.disabled },
                              model: {
                                value: _vm.upgradeForm.isUseAllConsume,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.upgradeForm,
                                    "isUseAllConsume",
                                    $$v
                                  )
                                },
                                expression: "upgradeForm.isUseAllConsume",
                              },
                            },
                            [_vm._v("累计消费金额")]
                          ),
                          _c("el-input", {
                            staticClass: "inputWidth marL15 marR15",
                            attrs: { disabled: _vm.disabled, size: "mini" },
                            on: {
                              input: function ($event) {
                                return _vm.valueBlur(6)
                              },
                            },
                            model: {
                              value: _vm.upgradeForm.allConsumeValue,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.upgradeForm,
                                  "allConsumeValue",
                                  _vm._n($$v)
                                )
                              },
                              expression: "upgradeForm.allConsumeValue",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "x-fc marB20" }, [
                      _c(
                        "div",
                        { staticClass: "marR20" },
                        [
                          _c(
                            "el-checkbox",
                            {
                              staticClass: "inputW140",
                              attrs: { disabled: _vm.disabled },
                              model: {
                                value: _vm.upgradeForm.isUseCurrentDayFill,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.upgradeForm,
                                    "isUseCurrentDayFill",
                                    $$v
                                  )
                                },
                                expression: "upgradeForm.isUseCurrentDayFill",
                              },
                            },
                            [_vm._v("当日充值金额")]
                          ),
                          _c("el-input", {
                            staticClass: "inputWidth marL15 marR15",
                            attrs: { disabled: _vm.disabled, size: "mini" },
                            on: {
                              input: function ($event) {
                                return _vm.valueBlur(7)
                              },
                            },
                            model: {
                              value: _vm.upgradeForm.currentDayFillValue,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.upgradeForm,
                                  "currentDayFillValue",
                                  _vm._n($$v)
                                )
                              },
                              expression: "upgradeForm.currentDayFillValue",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "marL20" },
                        [
                          _c(
                            "el-checkbox",
                            {
                              staticClass: "inputW140",
                              attrs: { disabled: _vm.disabled },
                              model: {
                                value: _vm.upgradeForm.isUseAllConsumeCount,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.upgradeForm,
                                    "isUseAllConsumeCount",
                                    $$v
                                  )
                                },
                                expression: "upgradeForm.isUseAllConsumeCount",
                              },
                            },
                            [_vm._v("累计消费次数")]
                          ),
                          _c("el-input", {
                            staticClass: "inputWidth marL15 marR15",
                            attrs: { disabled: _vm.disabled, size: "mini" },
                            on: {
                              input: function ($event) {
                                return _vm.valueBlur(8)
                              },
                            },
                            model: {
                              value: _vm.upgradeForm.allConsumeCountValue,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.upgradeForm,
                                  "allConsumeCountValue",
                                  _vm._n($$v)
                                )
                              },
                              expression: "upgradeForm.allConsumeCountValue",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "x-fc marB20" }, [
                      _c(
                        "div",
                        { staticClass: "marR20" },
                        [
                          _c(
                            "el-checkbox",
                            {
                              staticClass: "inputW140",
                              attrs: { disabled: _vm.disabled },
                              model: {
                                value: _vm.upgradeForm.isUseOneWeekConsume,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.upgradeForm,
                                    "isUseOneWeekConsume",
                                    $$v
                                  )
                                },
                                expression: "upgradeForm.isUseOneWeekConsume",
                              },
                            },
                            [_vm._v("一周内消费金额")]
                          ),
                          _c("el-input", {
                            staticClass: "inputWidth marL15 marR15",
                            attrs: { disabled: _vm.disabled, size: "mini" },
                            on: {
                              input: function ($event) {
                                return _vm.valueBlur(9)
                              },
                            },
                            model: {
                              value: _vm.upgradeForm.oneWeekConsumeValue,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.upgradeForm,
                                  "oneWeekConsumeValue",
                                  _vm._n($$v)
                                )
                              },
                              expression: "upgradeForm.oneWeekConsumeValue",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "marL20" },
                        [
                          _c(
                            "el-checkbox",
                            {
                              staticClass: "inputW140",
                              attrs: { disabled: _vm.disabled },
                              model: {
                                value: _vm.upgradeForm.isUseOneConsume,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.upgradeForm,
                                    "isUseOneConsume",
                                    $$v
                                  )
                                },
                                expression: "upgradeForm.isUseOneConsume",
                              },
                            },
                            [_vm._v("单笔消费金额")]
                          ),
                          _c("el-input", {
                            staticClass: "inputWidth marL15 marR15",
                            attrs: { disabled: _vm.disabled, size: "mini" },
                            on: {
                              input: function ($event) {
                                return _vm.valueBlur(10)
                              },
                            },
                            model: {
                              value: _vm.upgradeForm.oneConsumeValue,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.upgradeForm,
                                  "oneConsumeValue",
                                  _vm._n($$v)
                                )
                              },
                              expression: "upgradeForm.oneConsumeValue",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "x-x marB20" }, [
                      _c(
                        "div",
                        { staticClass: "marR20" },
                        [
                          _c(
                            "el-checkbox",
                            {
                              staticClass: "inputW140",
                              attrs: { disabled: _vm.disabled },
                              model: {
                                value:
                                  _vm.upgradeForm.isUseThirtyDayConsumeCount,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.upgradeForm,
                                    "isUseThirtyDayConsumeCount",
                                    $$v
                                  )
                                },
                                expression:
                                  "upgradeForm.isUseThirtyDayConsumeCount",
                              },
                            },
                            [_vm._v("30天内消费次数")]
                          ),
                          _c("el-input", {
                            staticClass: "inputWidth marL15 marR15",
                            attrs: { disabled: _vm.disabled, size: "mini" },
                            on: {
                              input: function ($event) {
                                return _vm.valueBlur(12)
                              },
                            },
                            model: {
                              value: _vm.upgradeForm.thirtyDayConsumeCountValue,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.upgradeForm,
                                  "thirtyDayConsumeCountValue",
                                  _vm._n($$v)
                                )
                              },
                              expression:
                                "upgradeForm.thirtyDayConsumeCountValue",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", {
                        staticClass: "marL20",
                        staticStyle: { width: "420px" },
                      }),
                    ]),
                  ]),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }