var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("TablePage", {
    ref: "tablePage",
    on: { handleEvent: _vm.handleEvent },
    model: {
      value: _vm.options,
      callback: function ($$v) {
        _vm.options = $$v
      },
      expression: "options",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }