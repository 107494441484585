<template>
  <div class="wrap">
    <!-- 表格商品信息卡片 -->
    <cardTitleCom cardTitle="模板设置">
      <template slot="cardContent">
        <!-- 整体盒子 -->
        <div>
          <templateCom class="padd10" />
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import templateCom from '../components/templateCom.vue' //短信模板
export default {
  name: 'send',
  components: { cardTitleCom, templateCom },
  data () {
    return {
      //选择对象遮罩
      loadingTable: false,
      objTableData: [{}],
      queryParams: {
        ra: 1,
        pageNum: 1,
        pageSize: 15
      },
      goodsItem: '',
      activeName: 'first',
      multiple: false
    }
  },
  methods: {
    handleClick () {},
    //表格增加/减少一行方法
    row (name, index) {
      if (name === 'push') {
        this.objTableData.splice(index + 1, 0, {})
      } else {
        if (this.objTableData.length <= 1) return
        this.objTableData.splice(index, 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 10px 10px 1px 10px;
  background-color: #dbdbdb;
}
</style>
