<template>
  <div class="wrap">
    <!-- 顶部剩余条数 -->
    <el-card class="remainingPieces">
      <span class="fontS14"
        >您剩余短信<span class="fontS18 annotateBlue">{{ bookTotal }}</span
        >条</span
      >
    </el-card>
    <!-- 短信套餐包 -->
    <cardTitleCom cardTitle="短信套餐包">
      <template slot="cardContent">
        <!-- 整体盒子 -->
        <div class="searchMessage x-fc">
          <i class="el-icon-arrow-left cursorP" @click="clickLeft" />
          <div
            class="y-c chargeInformation"
            v-for="(item, index) in showList"
            :key="index"
          >
            <span class="marB10">{{ item.packageCount }}条</span>
            <span class="marB10">单价{{ item.packagePrice }}元/条</span>
            <span class="marB20 asterisk fontS14"
              >¥{{ item.packageMoney }}元</span
            >
            <el-button type="primary" @click="getShoping(item)">购买</el-button>
          </div>
          <i class="el-icon-arrow-right cursorP" @click="clickRight" />
        </div>
      </template>
    </cardTitleCom>
    <!-- 表格充值记录卡片 -->
    <cardTitleCom cardTitle="充值记录">
      <template #rightCardTitle>
        <el-button
          icon="el-icon-refresh-right"
          size="mini"
          @click="getList"
          style="margin-right: 10px"
          >刷新</el-button
        >
      </template>
      <template slot="cardContent">
        <!-- 整体盒子 -->
        <div class="tableContent">
          <el-table
            v-loading="loadingTable"
            tooltip-effect="dark"
            :data="tableData"
            border
            max-height="600"
            height="600"
            ref="multipleTable"
          >
            <el-table-column
              label="序号"
              align="center"
              prop="userId"
              width="80"
              type="index"
            />
            <el-table-column label="订单号" align="center" prop="orderNo" />
            <el-table-column label="购买日期" align="center" prop="orderTime" />
            <el-table-column label="充值条数" align="center" prop="buyCount" />
            <el-table-column
              label="充值金额（元）"
              align="center"
              prop="buyMoney"
            />
            <el-table-column
              label="支付方式"
              align="center"
              prop="payModeName"
            />
            <el-table-column
              label="支付状态"
              align="center"
              prop="payStatusName"
            />
          </el-table>
          <pagination
            :total="total"
            :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize"
            @pagination="getList"
          />
        </div>
      </template>
    </cardTitleCom>

    <!-- 扫码支付弹窗 -->
    <el-dialog
      :visible.sync="dialogVisible"
      title="扫码支付"
      v-dialogDrag="true"
      :before-close="handleClose"
      width="600px"
    >
      <div class="pay-code" v-loading="loadingLog">
        <div style="line-height: 30px; padding: 15px 0; font-size: 16px">
          应付金额：
          <span style="font-size: 30px; color: #ff5b28"
            >{{ packageMoney || 0 }}元</span
          >
        </div>
        <img
          :src="smsUrl"
          class="QRImgUrl"
          style="width: 180px; height: 180px"
        />
        <div
          style="
            color: #666666;
            font-size: 14px;
            padding: 15px 0;
            display: flex;
            algin-item: center;
            justify-content: center;
          "
        >
          <el-image
            :src="require('@/assets/images/weixinpay.png')"
            class="icon"
          />
          <div>微信支付</div>
          <el-image
            :src="require('@/assets/images/zhifubaopay.png')"
            class="icon"
          />
          <div>支付宝支付</div>
          <el-image
            :src="require('@/assets/images/yunshanpay.png')"
            class="icon"
          />
          <div>云闪付</div>
        </div>
        <div style="color: #666666; font-size: 14px; padding: 15px 0">
          温馨提示：产品一经激活启用，非产品原因不允许退货退款
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import {
  buyListAPI,
  buyAddAPI,
  getBookUsableFillcount,
  getOrderState,
} from "@/api/marketing/sms/buy"; //短信购买
import { smsPackageListAPI, smsOrderPay } from "@/api/marketing/sms/smsPackage"; //短信套餐
export default {
  name: "buy",
  components: { cardTitleCom },
  data() {
    return {
      timeing: 0,
      timer: null,
      //弹框
      loadingLog: false,
      //短信价格
      packageMoney: 0,
      //短信购买二维码
      smsUrl: "",
      //存放显示的内容
      showList: [],
      //套餐包
      smsPackageList: [],
      //扫码支付弹窗
      dialogVisible: false,
      //收费信息
      chargeInformationList: [
        { number: 1800, unitPrice: 0.06, price: 100.0 },
        { number: 10000, unitPrice: 0.05, price: 500.0 },
        { number: 20000, unitPrice: 0.048, price: 960.0 },
      ],
      // 总条数
      total: 0,
      bookTotal: 0,
      //选择对象遮罩
      loadingTable: false,
      //表格
      tableData: [],
      //查询
      queryParams: {
        pageNum: 1,
        pageSize: 15,
      },
    };
  },
  async created() {
    //初始化全部的数据
    await this.getInitializeData();
  },
  methods: {
    // 点击左箭头方法
    clickLeft() {
      if (this.smsPackageList[0] == this.showList[0]) {
        return false;
      } else {
        let vid = this.showList[0];
        this.smsPackageList.forEach((v, i) => {
          if (vid == v) {
            this.showList.splice(5, 1);
            this.showList.unshift(this.smsPackageList[i - 1]);
          }
        });
      }
    },
    // 点击右键头方法
    clickRight() {
      if (
        this.smsPackageList[this.smsPackageList.length - 1] == this.showList[5]
      ) {
        return false;
      } else {
        let vid = this.showList[5];
        this.smsPackageList.forEach((v, i) => {
          if (vid == v) {
            this.showList.splice(0, 1);
            this.showList.push(this.smsPackageList[i + 1]);
          }
        });
      }
    },
    //初始化
    async getInitializeData() {
      //套餐包
      const res1 = await smsPackageListAPI(this.queryParams);
      this.smsPackageList = res1.rows;
      if (this.smsPackageList.length > 0) {
        this.showList = this.smsPackageList.slice(0, 6);
      }
      //表格数据
      await this.getList();
    },
    //点击买
    async getShoping(value) {
      clearInterval(this.timer);
      this.timeing = 0;
      this.dialogVisible = true;
      try {
        this.loadingLog = true;
        this.packageMoney = value.packageMoney;
        if (value.packageId) {
          const res = await buyAddAPI({
            buyCount: value.packageCount,
            buyMoney: value.packageMoney,
            packageId: value.packageId,
          });
          let orderNo = res.data;
          const res1 = await smsOrderPay({
            amt: value.packageMoney,
            ordNo: orderNo,
            subject: "短信套餐",
          });
          this.smsUrl = res1.data;
          // 每秒检测一次
          this.timer = setInterval(async () => {
            // 一分钟计时
            if (this.timeing <= 60) {
              try {
                const res = await getOrderState(orderNo);
                if (res?.data) {
                  // 支付成功
                  clearInterval(this.timer);
                  this.dialogVisible = false;
                  await this.getList();
                }
              } catch (error) {
                clearInterval(this.timer);
                this.$message.warning("支付失败！");
              }
            } else {
              clearInterval(this.timer);
              this.$message.warning("支付超时！");
            }
            this.timeing = this.timeing + 1;
          }, 1000);
          //渲染数据
        }
      } catch (error) {
      } finally {
        this.loadingLog = false;
      }
    },
    //关闭弹窗
    handleClose(done) {
      if (this.timer) {
        clearInterval(this.timer);
      }
      done();
    },
    /** 查询表格列表 */
    async getList() {
      try {
        this.loadingTable = true;
        const { rows, total } = await buyListAPI(this.queryParams);
        this.tableData = rows;
        this.total = total;
        const res = await getBookUsableFillcount();
        this.bookTotal = res.data || 0;
      } catch (error) {
      } finally {
        this.loadingTable = false;
      }
    },
  },
  beforeDestroy() {
    // 组件销毁前清除定时器
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  padding: 10px 10px 1px 10px;
  background-color: #dbdbdb;
  /* 顶部剩余条数 */
  .remainingPieces {
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    margin-bottom: 6px;
  }
  // 短信套餐包
  .searchMessage {
    padding: 20px 10px;
    width: 100%;
    //收费信息
    .chargeInformation {
      height: 179px;
      width: 180px;
      margin-right: 50px;
      background-color: #e5f1ff;
      .el-button {
        width: 160px;
      }
    }
    .chargeInformation:nth-child(7) {
      margin-right: 20px;
    }
    .chargeInformation:nth-child(2) {
      margin-left: 20px;
    }
  }
  //充值记录
  .tableContent {
    padding: 6px 10px;
  }
  //卡片默认边距0
  ::v-deep .el-card__body {
    padding: 0;
    margin: 0;
  }
  .pay-code {
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon {
      width: 20px;
      height: 20px;
      margin-left: 15px;
      margin-right: 3px;
    }

    .QRImgUrl {
      padding: 20px;
      border-radius: 30px;
      overflow: hidden;
      border: 2px solid #b3d9e9;
    }
  }
}
</style>
